declare global {  
  interface Date {  
   asDate(): Date;
  }
 }

 Date.prototype.asDate = function(): Date {
   var self = this as Date;
  return new Date(self.toDateString());
 }
 export {};