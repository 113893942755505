export const ptBR = {
    back: "Voltar",
    next: "Avançar",
    name: "Nome",
    email: "E-mail",
    phone: "Celular",
    doc: "CPF",
    isBlockToCall: "Bloquear Ligações",
    nameIsRequired: "Por favor, informe seu nome.",
    nameIsInvalid: "Favor digitar nome.",
    invalidEmail: "Por favor, informe um e-mail válido.",
    phoneIsRequired: "Por favor, informe um telefone válido.",
    docIsRequired: "Por favor, informe seu CPF.",
    docIsInvalid: "O CPF informado é inválido.",
    pleaseSelectEstablishment: "Por favor, selecione uma unidade para continuar.",
    anErrorWasOcurred: "Ocorreu um erro ao processar sua requisição.",
    selectPreferencEstablishment: "Selecione a Unidade de sua preferência",
    selectEstablishment: "Selecione a Unidade",
    waitMapPermission: "Aguardando permissão para acessar sua localização e assim mostrar as unidades mais próximas a você.",
    inputCityForMoreLocationResults: "* Você pode digitar a cidade, bairro, nome da unidade ou o CEP para refinar a busca.",
    pleaseSelectDate: "Por favor, informe uma data válida.",
    pleaseSelectATurn: "Por favor, selecione ao menos um período do dia.",
    invalidReCaptcha: "Por favor, valide o ReCaptcha.",
    schedule: "Agendar",
    selectTime: "Selecione o Horário",
    timeIsRequired: "Por favor, selecione um horário para continuar.",
    schedulerDone: "Tudo certo por aqui!",
    schedulerDetail: "Seu agendamento está marcado na unidade {{unidade}}, para o dia {{data}}.",
    attention: "Atenção!",
    alreadyRegisteredMessage: "Não é possível prosseguir pois localizamos um cadastro ativo informado na unidade {{unidade}}. Entre em contato com a unidade para maiores informações.",
    openWhatsapp: "Abrir WhatsApp",
    fromWhatsapp: "Pelo WhatsApp",
    referFriend: "Por Aqui",
    shareToWin: "Indique amigos para receber o voucher por e-mail.",
    shareToWinObs: "(Caso não receba na caixa de entrada, consulte a caixa de Spam)",
    finalize: "Concluir",
    addMore: "Adicionar Mais",
    noTimesForThisDay: "Nenhum horário disponível para este dia. 😥",
    referFriends: "Indicar Amigos",
    referFriendsRequired: "Por favor, preencha todos os campos da lista de indicados.",
    thanks: "Muito Obrigado!",
    finalMessage: "Estamos ansiosos para te receber em nossa unidade! 😉",
    referFriendsDuplicated: "Identificamos números de telefone repetidos na lista.",
    PrivacyPolicy: "Política de Privacidade",
    CookiePolicy: "Política de Cookie",
    knowTheApp: "Conheça o APP",
    notNow: "Agora não",
    weFindSomeSuggests: "Entretanto, nós selecionamos algumas opções de horário para você.",
    consentTerm: "Termo de consentimento",
    consentTermContent: " Concordo que li, compreendi e aceitei o",
    pleaseYouHaveToAcceptTheTerm: "Termo de consentimento deve ser aceito.",
    acceptConsentTerm: "Aceito",
    participation: "Como participar",
    regulations: "Regulamento",
    monday: "Segunda",
    tuesday: "Terça",
    wednesday: "Quarta",
    thursday: "Quinta",
    friday: "Sexta",
    saturday: "Sábado",
    sunday: "Domingo",
    morning: "Manhã",
    afternoon: "Tarde", 
    night: "Noite",
    openingHours: "HORÁRIOS DE FUNCIONAMENTO",
    noResultsFound: "Nenhum Resultado Encontrado",
    enterAddress: "Digitar CEP ou Bairro ou Cidade",
    close: "Fechar",
    sendInviteEmail: "Enviar convite",
    copy: "Copiar",
    shareInvite: "Compartilhe o link do seu convite",
    copiedLink: "Link copiado",
    emailSent: "E-mail enviado",
    emailFailure: "Não foi posssível enviar seu convite. Utilize uma das outas opções disponíveis.",
    inviteWhatsApp: "Indicar por WhatsApp",
    inviteFacebook: "Compartilhar no Facebook",
    inviteTwitter: "Compartilhar no Twitter",
    inviteTelegram: "Indicar por Telegram",
    inviteEmail: "Indicar por e-mail",
    inviteCopy: "Copiar link",
    backReschedulingMode: "Alterar Unidade",
    nextReschedulingMode: "Buscar Horários",
    invalidSegmentation: "Segmentação inválida",
    pleaseWait: "Por favor, aguarde...",
    seeMoreEstablishments: "Veja mais unidades",
    selectedEstablishment: "Unidade selecionada",
    fillFilter: "Informe um CEP, endereço, cidade, bairro ou nome da unidade",
    establishmentNotFound: "Nenhuma unidade para exibição.",
    establishments: "Unidades",
    cities: "Cidades",
    neighborhoods: "Bairros",
    addresses: "Endereços",
    establishmentFilterPlaceholder: "Digite para pesquisar",
    Avenue: "Avenida",
    Street: "Rua",
    pleaseSelectAllowedDate: "Por favor, informe uma data válida dentro do intervalo permitido.",

    scheduleManagementTitle: "Sua agenda",
    scheduleManagementLegend: "Por favor, confirme sua presença clicando no botão abaixo. Ou se preferir, utilize as outras opções para reagendar.",
    schedulerDetailEstablishment: "Seu agendamento está marcado na unidade <b>{{unidade}}</b>",
    schedulerDetailDate: "Para o dia <b>{{data}}</b>",
    confirmSchedule: "Confirmar Presença",
    reschedule: "Reagendar",
    dontGo: "Não Irei",
    hi: "Olá {{name}}",
    cancelScheduleQuestion: "Tem certeza que deseja cancelar seu agendamento conosco? Não se preocupe, futuramente você poderá voltar aqui e reagendar quando quiser.",
    yes:  "Sim",
    no: "Não",
    cancelScheduleSucess: "O cancelamento foi realizado com sucesso.",
    confirmScheduleSucess: "Agendamento confirmado com sucesso.",
    emailAuthenticated: "Seu e-mail foi autenticado com sucesso",
    emailAlreadyAuthenticated: "Esse e-mail já foi autenticado",
    appleOutlookCalendar:"iCal / Outlook",
    googleCalendar:"Google",
    outlookCalendar:"Outlook",
    addEvent:"Adicione na sua agenda",
    eventCalendarName: 'Espaçolaser | Avaliação Gratuita 3 Sessões Depilação a Laser',
    emailAlreadyExists: 'E-mail já cadastrado',    
    inviteThrough: 'Convidar via',
    facebook: 'Facebook',
    whatsapp: 'WhatsApp',
    link: 'Link',       
    fidelityTermContent: 'Concordo que li, compreendi e aceitei o',
    fidelityTerm: 'Termo do Programa Indique e Ganhe',
    alsoThe: 'e a',
    confirmed: 'Confirmado',
    whatsappContact: 'Fale com a loja',
    share: 'COMPARTILHAR',
    loadMoreDays: 'Carregar mais',
    filterByPeriod: 'Filtrar por período'
}
