import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { SchedulerSearchVM } from 'src/app/model/schedulerSearchVM';
import { Lead } from 'src/app/model/lead';
import { BehaviorSubject } from 'rxjs';
import { UiService } from 'src/app/services/ui.service';
import { ActiveFrameService } from 'src/app/services/activeFrame.service';
import { BusinessError } from 'src/app/helper/BusinessError';
import { SchedulerService } from 'src/app/services/scheduler.service';
import { ThemeService } from 'src/app/services/theme.service';
import { environment } from 'src/environments/environment';
import 'src/app/helper/date.extensions';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS, MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';

declare function handleCustomDataLayer(step) : any

@Component({
  selector: 'app-select-scheduler',
  templateUrl: './select-scheduler.component.html',
  styleUrls: ['./select-scheduler.component.scss'] ,
  providers: [
    {provide: MAT_DATE_LOCALE, useValue: 'pt-BR'},
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS]
    },
    {provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS},
  ],
})
export class SelectSchedulerComponent implements OnInit, AfterViewInit, OnDestroy {

  schedulerSearch: SchedulerSearchVM = { lead: null, date: null, morning: true, afternoon: true, night: true };

  stepName: string = 'SelectScheduler';

  minDate: Date;
  maxDate: Date;
  organizationalStructureDescription: string;
  nextBtnText: string;

  @Input() model: Lead;

  @Input() activeStep: BehaviorSubject<string>;

  selectedTime: Date;

  @Output() onConfirm = new EventEmitter<Date[]>();

  @Output() ConfirmSchedule = new EventEmitter<any>();

  @Output() onBack = new EventEmitter<string>();

  useNewScheduler: boolean = environment.useNewScheduler;

  constructor(
    public themeService: ThemeService,
    private uiService: UiService,
    private schedulerService: SchedulerService,
    private activeFrameService: ActiveFrameService,
    private adapter: DateAdapter<any>
  ) {
      this.adapter.setLocale('pt-BR');
   }

  async ngOnInit() {
    this.minDate = new Date();
    var base = new Date();
    base.setDate(base.getDate() + environment.schedulerInterval);
  }

  async ngAfterViewInit() {
    this.activeStep.subscribe(async (active) => {
      if (active == this.stepName) {

        this.schedulerSearch.lead = this.model;
        this.nextBtnText = this.themeService.text_Next_Btn_Time_Selection;

        if (this.schedulerSearch.date == null) {
          var base = new Date();
          base.setDate(base.getDate());
          this.schedulerSearch.date = this.model.nextAvaliableDate ?? base;
        }

        this.maxDate = this.getMaxDate();

        await this.handleOrganizationalStructureDescription();

        this.activeFrameService.moveTo("4");
      }
    });
  }
  
  ngOnDestroy(): void {
    this.activeStep.unsubscribe();
  }
  
  async validate() {
    if (this.schedulerSearch.date == null) {
      throw new BusinessError(await this.uiService.translate("pleaseSelectDate"));
    } else {      
      this.schedulerSearch.date = new Date(this.schedulerSearch.date);
      var date = this.schedulerSearch.date.asDate();     
      if (date < this.minDate.asDate() || date > this.getMaxDate().asDate()) {
        throw new BusinessError(await this.uiService.translate("pleaseSelectAllowedDate"));
      }
    }
    if (!this.schedulerSearch.morning && !this.schedulerSearch.afternoon && !this.schedulerSearch.night) {
      throw new BusinessError(await this.uiService.translate("pleaseSelectATurn"));
    }
  }

  back() {
    this.onBack.emit(this.stepName); 
  }

  async confirm() {
    if(this.useNewScheduler){
      await this.confirmSchedule();
    }else{
      await this.confirmScheduleOld();
    }
  }

  /**
   * Utilizado caso o parâmetro useNewScheduler seja falso
   */
  async confirmScheduleOld(){
    try {
      await this.validate();
      var data = await this.schedulerService.getAvaliableTimes(this.schedulerSearch);

      this.onConfirm.emit(data);
      handleCustomDataLayer(3);
    } catch(e) {
      this.uiService.showError(e);
    }
  }

  async confirmSchedule() {
    try {
      await this.validateConfirmSchedule();

      await this.schedulerService.doSchedule(this.model, this.selectedTime, this.themeService.use_free_schedule);

      this.ConfirmSchedule.emit({
        selectedTime: this.selectedTime,
        lead: this.model
      });

      handleCustomDataLayer(4);
    } catch(e) {
      this.uiService.showError(e);
    }
  }

  private async handleOrganizationalStructureDescription(){
    let splittedDescription = this.model.organizationalStructure.Description.split('-');
    if(splittedDescription.length == 3){
      this.organizationalStructureDescription = splittedDescription[2];
    }
  }

  getMaxDate(): Date {
    var base = new Date();
    let interval = environment.schedulerInterval;

    if (this.model && this.model.schedulerSuggestionInterval) {
      interval = this.model.schedulerSuggestionInterval;
    }
    base.setDate(base.getDate() + interval);
    
    return base;
    
  }

  async selectDate(e){
    this.selectedTime = e;
  }

  async validateConfirmSchedule() {
    if (this.selectedTime == null) {
      throw new BusinessError(await this.uiService.translate("timeIsRequired"));
    }
  }

}
