import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { FidelityService } from 'src/app/services/fidelity.service';
import { ThemeService } from 'src/app/services/theme.service';
import { UiService } from 'src/app/services/ui.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MarketingDigitalService } from 'src/app/services/marketing-digital.service';

@Component({
  selector: 'app-fidelity-dashboard',
  templateUrl: './fidelity-dashboard.component.html',
  styleUrls: ['./fidelity-dashboard.component.scss']
})
export class FidelityDashboardComponent implements OnInit {

  html_fidelity_dashboard: any;
  model: any = {};
  isClientInProgram: boolean = false;
  points: number;
  @Input() clientId: number;
  @Input() type: string;

  constructor(private fidelityService: FidelityService,
    private themeService: ThemeService,
    private uiService: UiService,
    private router: Router,
    private route: ActivatedRoute,
    private marketingDigitalService: MarketingDigitalService) {
  }

  async ngOnInit() {

    this.isClientInProgram = await this.fidelityService.isClientInFidelityProgram(this.clientId, this.type);

    if (this.isClientInProgram) {
      var model = await this.fidelityService.getPointsWithExtraData(this.clientId, this.type);
      this.model = model.extraData;
      this.points = model.points;
    }

    this.uiService.currentLanguage.subscribe(async lang => {
      //considerar lang como flag de inicialização (baseado em home.component)
      if (this.uiService.isBrowser && lang) {

        this.route.queryParams
          .subscribe(async params => {
            var htmlDashboard = this.themeService.html_fidelity_dashboard;
            htmlDashboard = htmlDashboard.replace('{{points}}', this.points);
            htmlDashboard = htmlDashboard.replace('{{model.name}}', this.model.name);
            this.html_fidelity_dashboard = htmlDashboard;
          });
        }
      });
  }

}
