import { Injectable } from '@angular/core';
import { UiService } from 'src/app/services/ui.service';

declare var $: any;

@Injectable({
    providedIn: 'root'
})
export class ActiveFrameService {
    constructor(private uiService: UiService) {

    }

    private show(el: Element): void {
        this.removeClass(el, "invisible");
        this.addClass(el, "visible");
    }

    private hide(el: Element): void {
        this.removeClass(el, "visible");
        this.addClass(el, "invisible");
    }

    private addClass(el: Element, cl: string): void {
        if(el.className.indexOf(cl) < 0){
            el.className += " "+ cl;
        }
    }

    private removeClass(el: Element, cl: string): void {
        if(el.className.indexOf(cl) >= 0){
            el.className = el.className.replace(cl, "");
        }
    }

    moveTo(fsSelection: string): void {
        if (!this.uiService.isBrowser) {
            return;
        }
        
        var current_fs = document.querySelector(".app-fs.active");
        var next_fs = document.querySelector(`.app-fs[fs-index='${fsSelection}']`);

        if (current_fs == next_fs) return;

        //show the next fieldset
        this.addClass(next_fs, "active");
        this.removeClass(current_fs, "active");

        var scale = 1;
        current_fs.setAttribute('style', 'transform: scale(' + scale + '); position: absolute');
        next_fs.setAttribute('style', 'left: 0%; opacity: 1; position: unset');
        this.hide(current_fs);
        current_fs.setAttribute('style', 'transform:scale(1)');
        this.show(next_fs);
    };
}