import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpClient, HttpEventType, HttpParams } from '@angular/common/http';
import { Observable, from, iif, of } from 'rxjs';
import { finalize, concatMap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { UiService } from '../services/ui.service';

@Injectable()
export class CmsRequestInterceptor implements HttpInterceptor {

    static inProgressRequests: number = 0;

    static cachedToken: string = null;

    static cachedTokenShared: string = null;

    constructor(
        public loaderService: UiService,
        private httpClient: HttpClient) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

        //verifica se a requisição é para o CMS
        if ((request.url.indexOf(environment.cms_url) >= 0 && request.url.indexOf(environment.cms_identity_endpoint) < 0)) {
            //RequestInterceptor.inProgressRequests = RequestInterceptor.inProgressRequests + 1;

            //this.loaderService.showLoading();
            
            return from(this.getCmsToken(request.url)).pipe(
                concatMap(token =>
                    iif(
                        () => !token,
                        of(request),
                        of(
                            request.clone({
                                setHeaders: {
                                    'Authorization': `Bearer ${token}`,
                                },
                                params: request.params.delete('operationName'),
                            }),
                        ),
                    ),
                ),
                concatMap(clonedRequest => next.handle(clonedRequest)),
                // --- login with refresh token on UNAUTHENTICATED graphql error, then try again.
                // Your Apollo server should throw AuthenticationError if an access token has expired.
                // For more info, see: https://www.apollographql.com/docs/apollo-server/features/errors.html
                concatMap(event => {
                    let needToAuthenticate = false
                    if (event.type === HttpEventType.Response &&
                        event.status === 200 &&
                        event.body &&
                        Array.isArray(event.body.errors)) {

                        const errors = event.body.errors as any[]
                        needToAuthenticate = !!errors.find(e => e.extensions && e.extensions.code === 'UNAUTHENTICATED')
                    }
                    
                    if (needToAuthenticate) {
                        // update access token by logging in to your auth server using a refresh token
                        return from(this.getCmsToken(request.url)).pipe(
                            concatMap(token => {
                                if (token) {
                                    return next.handle(request.clone({
                                        setHeaders: { Authorization: `Bearer ${token}` }
                                    }))
                                } else {
                                    // if logging in with refresh token failed to update access token, then can't help it...
                                    // --- apollo-link does not understand return throwError('message') so just throw new error
                                    throw new Error('Error getting access token after logging in with refresh token')
                                }
                            })
                        )
                    }
                    return of(event)
                }),
                finalize(() => {
                    //RequestInterceptor.inProgressRequests = RequestInterceptor.inProgressRequests - 1;

                    //if (RequestInterceptor.inProgressRequests <= 0) {
                    //this.loaderService.hideLoading();
                    //}
                })
            );
        }
        else {
            return next.handle(request);
        }
    }

    async getCmsToken(requestUrl: string): Promise<string> {        

        if (requestUrl.indexOf(environment.cms_shared_contract_content_endpoint) >= 0) return await this.getCmsTokenShared();

        if (CmsRequestInterceptor.cachedToken != null) return CmsRequestInterceptor.cachedToken;

        var body = new HttpParams()
            .set('grant_type', 'client_credentials')
            .set('client_id', environment.cms_identity_client_id)
            .set('client_secret', environment.cms_identity_client_secret)
            .set('scope', 'squidex-api');

        var data = <any>await this.httpClient.post(`${environment.cms_url}${environment.cms_identity_endpoint}`, body).toPromise();

        CmsRequestInterceptor.cachedToken = data.access_token;

        return data.access_token;
    }

    async getCmsTokenShared(): Promise<string> {
        if (CmsRequestInterceptor.cachedTokenShared != null) return CmsRequestInterceptor.cachedTokenShared;
        var body = new HttpParams().set('grant_type', 'client_credentials').set('client_id', environment.cms_identity_client_id_shared).set('client_secret', environment.cms_identity_client_secret_shared).set('scope', 'squidex-api');
        var data = <any>await this.httpClient.post(`${environment.cms_url}${environment.cms_identity_endpoint}`, body).toPromise();
        CmsRequestInterceptor.cachedTokenShared = data.access_token;
        return data.access_token;
    }
}
