import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { BusinessError } from 'src/app/helper/BusinessError';
import { Lead } from 'src/app/model/lead';
import { ActiveFrameService } from 'src/app/services/activeFrame.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-consent-term',
  templateUrl: './consent-term.component.html',
  styleUrls: ['./consent-term.component.scss']
})
export class ConsentTermComponent implements OnInit, OnDestroy {

  stepName: string = "ConsentTerm";

  protected _onDestroy = new Subject<void>();

  @Input() model: Lead;

  @Input() activeStep: BehaviorSubject<string>;

  @Output() onConfirm = new EventEmitter<Lead>();

  @Output() onBack = new EventEmitter<string>();

  consentTermChecked: boolean;

  constructor(
    private uiService: UiService,
    private activeFrameService: ActiveFrameService,
    public modal: MatDialog) { }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.activeStep.unsubscribe();
  }

  async ngOnInit() {
  }

  async ngAfterViewInit() {
    this.activeStep.subscribe(async (active) => {
      if (active == this.stepName) {
        this.activeFrameService.moveTo("3")
      }
    })
  }

  async validate() {
    if (this.consentTermChecked == false) {
      throw new BusinessError(await this.uiService.translate("pleaseYouHaveToAcceptTheTerm"));
    }
  }

  async confirm() {
    try {

      await this.validate();

      this.model.consentTerm = this.consentTermChecked;

      this.onConfirm.emit(this.model);

    } catch (e) {

      this.uiService.showError(e);

    }
  }
}