import { Injectable } from "@angular/core";
import { FingerPrint } from "../model/fingerprint";
import { IpService } from "./ip.service";
import { LocationService } from "./location.service";

import * as platform from 'platform';

@Injectable({
    providedIn: 'root'
})
export class FingerPrintService {
    private platformInfo;
    
    constructor(
        private locationService: LocationService,
        private ipService: IpService) {
    }

    async get() : Promise<FingerPrint> {
        try
        {
            this.platformInfo = platform.parse(navigator.userAgent);
            
            return {
                cookieEnabled: await this.isCookieEnabled(),
                os: await this.getOS(),
                browserName: await this.getBrowserName(),
                browserInfo: await this.getBrowserInfo(),
                ipAddress: await this.ipService.getIpAddress(),
                geolocation: await this.getGeolocation()
            };
        }
        catch(e){
            console.error('Erro ao obter fingerprint: '+ e);
            return null;
        }
    }

    private async isCookieEnabled() : Promise<boolean>{
        var cookieConsent = localStorage.getItem("cookieConsent");
        return cookieConsent == "true";
    }

    private async getOS() : Promise<string>{
        return this.platformInfo.os.family;
    }

    private async getBrowserName() : Promise<string>{
        return this.platformInfo.name;
    }
    
    private async getBrowserInfo() : Promise<string>{
        return this.platformInfo.version +' - '+ this.platformInfo.os.architecture + ' bits';
    }

    private async getGeolocation(){
        if(await this.hasGeolocationAccess()){
            return await this.locationService.getPosition();
        }
        else{
            return null;
        }
    }

    private async hasGeolocationAccess() : Promise<boolean> {
        if(navigator.permissions){
            return await navigator.permissions.query({ name: 'geolocation' }).then(x => x.state == "granted");
        }
        else{
            return false;
        }
    }
}