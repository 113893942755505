import * as CryptoJS from 'crypto-js';  

export class EncrypterService  {

    static keyHex = CryptoJS.enc.Utf8.parse("vndWasdk");

    static encrypt(value: string) : string {
        var res = CryptoJS.DES.encrypt(value, EncrypterService.keyHex, {
            mode: CryptoJS.mode.ECB
        });
        var strRes = res.toString().replace('+', '-').replace('/', '_');
        return strRes;
    }   
    
    static decrypt(value: string) : string {

        value = value.replace('_', '/').replace('-', '+');
        switch (value.length % 4)
        {
            case 2: value += "=="; break;
            case 3: value += "="; break;
        }

        var res = CryptoJS.DES.decrypt({
            ciphertext: CryptoJS.enc.Base64.parse(value)
        }, EncrypterService.keyHex, {
            mode: CryptoJS.mode.ECB
        });
       
        var strRes = res.toString(CryptoJS.enc.Utf8);
        return strRes;
    }  
}