<div class="unit-filter">
    <p class="back" *ngIf="userPosition" (click)="closeFilter()">{{'back' | translate}}</p>
    <div class="clear"></div>
    <p class="filter-title">{{'fillFilter' | translate}}</p>
    <input matInput type="text" placeholder="{{'establishmentFilterPlaceholder' | translate}}" [(ngModel)]="searchTerms" (keyup)="filter()" />


    <div *ngIf="results && results.length == 0 && searchTerms && searchTimeout == undefined">
        <h3>{{'establishmentNotFound' | translate}}</h3>
    </div>

    <div class="unit-results" *ngIf="searching">
        <div *ngFor="let item of results">   
            <div class="establishments" *ngIf="item.type == 'establishment'">
                <p class="unit-filter-title"><b>{{'establishments' | translate}}</b></p>
                <div class="result" *ngFor="let unit of item.results" (click)="selectAddress(item.type, unit)">
                    <div [innerHTML]="unit.text"></div>
                    <p>{{unit.Document.Address.StateAbrev + ' - ' + unit.Document.Address.City}}</p>
                    <p>{{unit.Document.Address.Type | uppercase }} {{unit.Document.Address.Description + ', ' + unit.Document.Address.Number +
                        ' - ' + unit.Document.Address.Neighborhood | uppercase }}</p>
                </div>
                
            </div>
            <div class="cities" *ngIf="item.type == 'city'">
                <p class="unit-filter-title"><b>{{'cities' | translate}}</b></p>
                <div class="result" *ngFor="let unit of item.results" (click)="selectAddress(item.type, unit)" [innerHTML]="unit.text"></div>
            </div>
            <div class="neighborhoods" *ngIf="item.type == 'neighborhood'">
                <p class="unit-filter-title"><b>{{'neighborhoods' | translate}}</b></p>
                <div class="result" *ngFor="let unit of item.results" (click)="selectAddress(item.type, unit)" [innerHTML]="unit.text"></div>
            </div>
            <div class="adressess" *ngIf="item.type == 'address'">
                <p class="unit-filter-title"><b>{{'addresses' | translate}}</b></p>
                <div *ngFor="let unit of item.results" (click)="selectAddress(item.type, unit)">
                    <div class="result">
                        <span *ngIf="unit.text.length <= 15">
                            <div [innerHTML]="unit.text"></div>
                            <p>{{ unit.Document.Address }}</p>
                        </span>
                        <span *ngIf="unit.text.length > 15">
                            <div [innerHTML]="unit.text"></div>
                        </span>
                </div>                
            </div>
        </div>
    </div>

</div>
