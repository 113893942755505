import { Injectable } from "@angular/core";
import axios from "axios";
import qs from "qs";
import { environment } from "src/environments/environment";

@Injectable({
    providedIn: 'root'
})
export class MarketingDigitalService {

    constructor() {
    }

    async getAttendantRecord(id: number) {
        return await this.getById(id);
    }

    async getBondRecord(id: number) {
        return await this.getById(-id);
    }

    private async getById(id: number) {
        const form = {
            grant_type: 'client_credentials',
            scope: 'squidex-api',
            client_id: environment.cms_identity_client_id_shared,
            client_secret: environment.cms_identity_client_secret_shared
        };
        
        var auth = <any>await axios.post(`${environment.cms_url}${environment.cms_identity_endpoint}`, qs.stringify(form), { headers: { 'content-type': 'application/x-www-form-urlencoded' } });
    
        var query = {
            "query": `{
                queryMarketingDigitalVinculoContents (filter: "data/Id/iv eq ${id}") {
                   flatData {
                    capa {
                        url
                    },
                    path,
                    redirectTo
                  }
                }
              }`
        };

        var returnData = <any>await axios.post(`${environment.cms_url}${environment.cms_shared_contract_content_endpoint}`, query, { headers: { Authorization: `Bearer ${auth.data.access_token}` } });
        
        var flatDataArray = <any[]>returnData.data.data.queryMarketingDigitalVinculoContents;
        return flatDataArray[0].flatData;
    }
}