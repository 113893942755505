<div *ngIf="times?.length > 0">
    <h2 class="fs-title">{{ 'schedule' | translate }}</h2>

    <h3 class="fs-info" style="margin-bottom: 20px;">{{ this.themeService.rotulo_sel_hora.replace('[[data]]', this.datepipe.transform(this.times[0], 'dd/MM/yyyy')) }}</h3>

    <mat-select #selectHorario
    [(value)]="selectedTime" 
    (valueChange)="onSelectTime($event)" 
    placeholder="{{ 'selectTime' | translate }}">
    <mat-select-trigger>{{selectedTime | date:'HH:mm'}}
    </mat-select-trigger>

    <mat-option *ngFor="let time of times" [value]="time">
        {{time | date:'HH:mm' }}
    </mat-option>
    </mat-select>
    <div>
        <button mat-button name="previous" class="previous action-button w50" (click)="back()" >{{ 'back' | translate }}</button>
        <button mat-button name="next" class="next action-button w50" (click)="confirm()" >{{ this.themeService.text_Next_Btn_Time_Selection }}</button>
    </div>
</div>
<div *ngIf="times?.length == 0">
    <h2 class="fs-title">{{ 'noTimesForThisDay' | translate }}</h2>

    <div *ngIf="suggestions?.length > 0">
        <h3 class="fs-info">{{ 'weFindSomeSuggests' | translate }}</h3>
        <mat-select 
        [(value)]="selectedTime" 
        (valueChange)="onSelectTime($event)" 
        placeholder="{{ 'selectTime' | translate }}">
        <mat-select-trigger>{{selectedTime | date:'dd/MM/yyyy HH:mm'}}
        </mat-select-trigger>
    
        <mat-option *ngFor="let suggestion of suggestions" [value]="suggestion">
            {{suggestion | date:'dd/MM/yyyy HH:mm' }}
        </mat-option>
        </mat-select>

    </div>
    <div>
        <button mat-button name="previous" class="previous action-button w50" (click)="back()" >{{ 'back' | translate }}</button>
        <button mat-button id="btnNextDoScheduler" *ngIf="suggestions?.length > 0" name="next" class="next action-button w50" (click)="confirm()" >{{ this.themeService.text_Next_Btn_Time_Selection }}</button>
    </div>
</div>