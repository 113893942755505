import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ShortlinkService } from './shortlink.service';
import { ThemeService } from './theme.service';
import { UiService } from './ui.service';
import { InitialThemeService } from './initial-theme.service';
import { EncrypterService } from './encrypter.service';

@Injectable({
  providedIn: 'root'
})
export class InviteService {

  baseUrl: string;

  shortLink: string;

  copyMsg: string;

  constructor(
    private shortlinkService: ShortlinkService,
    private themeService: ThemeService,
    private uiService: UiService,
    private initialThemeService: InitialThemeService) { }



  async sendToWhatsapp(inviteLink, iFrame) {
    var msg = encodeURIComponent(await this.getMsg(false, inviteLink));
    this.sendOpenCommand(`https://wa.me/?text=${msg}`, iFrame);
  }

  async sendToFacebook(inviteLink, iFrame) {
    await this.generateShortenerURL(inviteLink);
    var url = encodeURIComponent(this.shortLink);
    var msg = encodeURIComponent(this.themeService.share_facebook);
    this.sendOpenCommand(`https://www.facebook.com/sharer.php?u=${url}&quote=${msg}`, iFrame);
  }

  // copyInviteLink(inviteLink, iFrame, isFromFidelity) {
  //   this.baseUrl = inviteLink.split('?')[0];
  //   var inviteLinkParams = inviteLink.split('?')[1];
  //   this.shortlinkService.generateShortenerURLForCopy(inviteLinkParams, this.baseUrl, true).subscribe(response => {
  //     if (response && response.token) {
  //       setTimeout(() => {
  //         this.sendCopyCommand(`${this.baseUrl}?t=${response.token}`, iFrame, isFromFidelity);
  //       }, 500);
  //     } else {
  //       setTimeout(() => { this.sendCopyCommand(`${this.baseUrl}`, iFrame, isFromFidelity) }, 500);
  //     }
  //   });
  // }

  copyInviteLink(inviteLink, iFrame, isFromFidelity) {
    const inviteLinkComponents = inviteLink.split('?');
    this.baseUrl = inviteLinkComponents[0];
    const inviteLinkParams = inviteLinkComponents[1];

    this.shortlinkService.generateShortenerURLForCopy(inviteLinkParams, this.baseUrl, true).subscribe(response => {
      if (response && response.token) {
        setTimeout(() => {
          this.sendCopyCommand(`${this.baseUrl}?t=${response.token}`, iFrame, isFromFidelity);
        }, 500);
      } else {
        setTimeout(() => { this.sendCopyCommand(`${this.baseUrl}`, iFrame, isFromFidelity) }, 500);
      }
    });
  }

  sendCopyCommand(msg, iFrame, isFromFidelity) {
    if (iFrame && !isFromFidelity) {
      parent.postMessage({
        type: "copiaLink",
        data: msg
      }, "*");
    } else {
      navigator.clipboard.writeText(msg).then(async () => {
        this.uiService.showMessage({
          title: "",
          text: await this.uiService.translate("copiedLink"),
        });
      }).catch((e) => {
        this.uiService.showError(e);
      });
    }
  }



  async generateShortenerURL(inviteLink) {
    this.baseUrl = inviteLink.split('?')[0];
    var inviteLinkParams = inviteLink.split('?')[1];
    var data = await this.shortlinkService.generateShortenerURL(inviteLinkParams, this.baseUrl, true);
    if (data && data.token) {
      this.shortLink = `${this.baseUrl}?t=${data.token}`;
    } else {
      this.shortLink = `${this.baseUrl}`;
    }
  }


  async getMsg(clear: boolean = true, inviteLink): Promise<string> {
    await this.generateShortenerURL(inviteLink);
    var msg = this.themeService.share_whatsapp.replace("{0}", this.shortLink);

    if (clear) {
      // msg = msg.replaceAll("*", "");
      while (msg.indexOf("*") >= 0) {
        msg = msg.replace("*", "");
      }
    }

    return msg;
  }

  sendOpenCommand(url: string, iFrame) {
    if (iFrame) {
      parent.postMessage({
        type: "aberturaLink",
        data: url
      }, "*");
    }
    else {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        window.location.assign(url);
      } else {
        window.open(url);
      }
    }
  }

  public buildInviteLink(
    id: number = null,
    tipo: string = null,
    hasMarketingVinculo: boolean = false,
    hasVariant: boolean = false,
    isRefferShareLink: boolean = false,
    UTMList: any[] = [],
    mktDigitalPath: string = null) {

    let inviteLink;

    if (isRefferShareLink) {
      inviteLink = environment.refferShareLink;
    } else {
      inviteLink = environment.shareLink;
    }

    return this.generateInviteLink(id, tipo, hasMarketingVinculo, hasVariant, inviteLink, UTMList, mktDigitalPath);
  }

  public generateInviteLink(
    id: number = null,
    tipo: string = null,
    hasMarketingVinculo: boolean = false,
    hasVariant: boolean = false,
    inviteLink: any,
    UTMList: any[] = [],
    mktDigitalPath: string = null) {

    let ignoreEncode = false;

    //Caso o Id não seja um número significa que já está criptografado
    if (!isNaN(id)) {
      ignoreEncode = false;
    } else {
      ignoreEncode = true;
    }

    let baseUrl;

    if (hasMarketingVinculo && mktDigitalPath) {
      baseUrl = `${location.origin}${mktDigitalPath}`;
    } else {
      baseUrl = location.origin;
    }

    //se possui marketing digital ou variante, utilizar link_site
    if (hasVariant && this.initialThemeService.link_site) {
      baseUrl = this.initialThemeService.link_site;
    }

    //remove ultimo caracter se for '/'
    if (baseUrl.endsWith('/')) {
      baseUrl = baseUrl.substring(0, baseUrl.length - 1);
    }

    const inviteLinkComponents = inviteLink.split('?');

    if (inviteLinkComponents.length > 1 && id != null && tipo != null) {

      const encodedId = ignoreEncode ? id : encodeURIComponent(EncrypterService.encrypt(`${id}`));

      const inviteLinkParams = inviteLinkComponents[1].replace('{0}', encodedId).replace('{1}', tipo);

      if (hasMarketingVinculo || baseUrl.includes('?')) {
        inviteLink = `${baseUrl}&${inviteLinkParams}`;
      } else {
        inviteLink = `${baseUrl}?${inviteLinkParams}`;
      }
    } else {
      inviteLink = baseUrl;
    }

    if (!hasMarketingVinculo) {
      if (UTMList.length > 0) {
        UTMList.forEach(utm => {
          if (utm.value) {
            inviteLink += `&${utm.utm}=${utm.value}`
          }
        });
      }
    }


    return inviteLink;
  }

  buildUTMList(utm_term, utm_source, utm_medium, utm_campaign, utm_content) {
    let UTMList = [];

    UTMList.push({ utm: 'utm_term', value: this.getParamValue(utm_term) });
    UTMList.push({ utm: 'utm_source', value: this.getParamValue(utm_source) });
    UTMList.push({ utm: 'utm_medium', value: this.getParamValue(utm_medium) });
    UTMList.push({ utm: 'utm_campaign', value: this.getParamValue(utm_campaign) });
    UTMList.push({ utm: 'utm_content', value: this.getParamValue(utm_content) });

    return UTMList;
  }

  getParamValue(value: any): string {
    if (value && value.length > 0) {
      if (Array.isArray(value)) {
        return value[0];
      } else {
        return value;
      }
    }
    return null;
  }



}



