import { Component, OnInit, Input, AfterViewInit } from '@angular/core';
import { SelectSchedulerService } from 'src/app/services/pages/select-scheduler.service';
import { BehaviorSubject, iif } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { ThemeService } from 'src/app/services/theme.service';

export interface PageContents
{
  titulo: string;
  descricao: string;
  imagemFundo: any;
  icone: any;
  utilizarHtmlCustomizado: boolean;
  html: string;
}
@Component({
  selector: 'app-select-scheduler-top-content',
  templateUrl: './select-scheduler-top-content.component.html',
  styleUrls: ['./select-scheduler-top-content.component.scss']
})

export class SelectSchedulerTopContentComponent implements  AfterViewInit {

  @Input() activeStep: BehaviorSubject<string>;

  stepName: string = 'SelectScheduler';

  pageContents: PageContents = {titulo: null, descricao: null, imagemFundo: null, icone: null, utilizarHtmlCustomizado: null, html: null};

  htmlContent: any;

  useHtml: boolean;

  background: any;

  icon: any;

  segmentation: any;

  constructor(
    private selectSchedulerService: SelectSchedulerService,
    private sanitizer: DomSanitizer,
    private themeService: ThemeService) { }

  
  async ngAfterViewInit() {
    this.activeStep.subscribe(async (active) => {
      if (active == this.stepName) {

        if (!this.themeService.has_select_scheduler_data_from_variants) {
            this.pageContents = await this.selectSchedulerService.initialize();
        }else{
            this.pageContents.titulo = this.themeService.select_scheduler_titulo;
            this.pageContents.descricao = this.themeService.select_scheduler_descricao;
            this.pageContents.imagemFundo = this.themeService.select_scheduler_imagem_fundo;
            this.pageContents.icone = this.themeService.select_scheduler_icone;
            this.pageContents.utilizarHtmlCustomizado = this.themeService.select_scheduler_utiliza_html_customizado;
            this.pageContents.html = this.themeService.select_scheduler_html;
        }

        if (this.pageContents) {
            this.buildContents();
        }

      }
    });
  }

  buildContents(){
    this.background = this.pageContents.imagemFundo ? this.pageContents.imagemFundo[0].url : null;
    this.icon = this.pageContents.icone ? this.pageContents.icone[0].url : null;
    if (this.pageContents.utilizarHtmlCustomizado) {
      this.useHtml = this.pageContents.utilizarHtmlCustomizado && this.pageContents.html != null;
      this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.pageContents.html);
    }
  }

}
