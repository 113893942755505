import { Component, Input, OnInit } from '@angular/core';
import { Output, EventEmitter } from '@angular/core';
import Enumerable from 'linq';
import { SearchAddressService } from 'src/app/services/search-address.service';

@Component({
  selector: 'app-unit-filter',
  templateUrl: './unit-filter.component.html',
  styleUrls: ['./unit-filter.component.scss']
})
export class UnitFilterComponent implements OnInit {
  @Output() closeFilterEvent = new EventEmitter<boolean>();
  @Output() selectAddressEvent = new EventEmitter<any>();
  searchTerms: string;
  searching: boolean = false;
  results: any[];
  next = 0;
  searchTimeout: any;

  @Input() userPosition: boolean;

  constructor(private searchAddressService: SearchAddressService) { }

  ngOnInit(): void {
  }

  closeFilter(){
    this.closeFilterEvent.emit()
  }

  filter(){    

    if (this.searchTimeout) {
        clearTimeout(this.searchTimeout);
        this.searchTimeout = undefined;
		}

    this.searchTimeout = setTimeout(async () => {
      this.searching = this.searchTerms ? true : false;    

      this.results = [];
      this.next = 0;
      if (this.searchTerms) {
      const results = await this.searchAddressService.search(this.searchTerms);
      this.results = Enumerable.from(results).groupBy(r => r.type).select(g => ({
        type: g.key(),
        results: Enumerable.from(g.getSource()).select(t => (
          {
            text: t.text,
            Document: t.Document
        })).toArray()
      })).toArray();
    } 
    this.searchTimeout = undefined;
      
    }, 500);
  }


  selectAddress(type, item){
    this.selectAddressEvent.emit({type: type, item: item});
    this.closeFilter();
  }


}
