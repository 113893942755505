import { Component, OnInit, ViewChild, Input, OnDestroy, Output, EventEmitter, HostListener } from '@angular/core';
import { OrganizationalStructure } from 'src/app/model/organizationalStructure';
import { UntypedFormControl } from '@angular/forms';
import { ReplaySubject, Subject } from 'rxjs';
import { MatSelect } from '@angular/material/select';
import { Lead } from 'src/app/model/lead';
import { BusinessError } from 'src/app/helper/BusinessError';
import { takeUntil } from 'rxjs/operators';
import { UiService } from 'src/app/services/ui.service';
import { LocationService } from 'src/app/services/location.service';
import { LeadService } from 'src/app/services/lead.service';
import { ActiveFrameService } from 'src/app/services/activeFrame.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { ThemeService } from 'src/app/services/theme.service';
import { Meta } from '@angular/platform-browser';
import { ExceptionHandlerService } from 'src/app/services/exceptionHandler.service';
import { ActivatedRoute, Router } from '@angular/router';

var weekdays: Array<string>;

declare function handleCustomDataLayer(step, label, address, encryptedEmail, clientAlreadyExist): any

@Component({
  selector: 'app-select-establishment',
  templateUrl: './select-establishment.component.html',
  styleUrls: ['./select-establishment.component.scss']
})
export class SelectEstablishmentComponent implements OnInit, OnDestroy {

  stepName: string = 'SelectEstablishment';

  @Input() model: Lead;

  @Input() activeStep: Subject<string>;

  @Input() mainEvents: Subject<string>;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.refreshViewport();
  }

  @Output() onConfirm = new EventEmitter<Lead>();

  @Output() onBack = new EventEmitter<string>();

  establishments: OrganizationalStructure[] = [];

  /** control for the selected bank */
  public selectUnidadeCtrl: UntypedFormControl = new UntypedFormControl();

  /** control for the MatSelect filter keyword */
  public selectUnidadeFilterCtrl: UntypedFormControl = new UntypedFormControl();

  /** list of banks filtered by search keyword */
  public filteredEstablishments: ReplaySubject<OrganizationalStructure[]> = new ReplaySubject<OrganizationalStructure[]>(1);

  @ViewChild('selectUnidade', { static: true }) selectUnidade: MatSelect;

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  lat: number = null;
  lng: number = null;
  userLat: number;
  userLng: number;
  zoom: number = 12;
  userPosition: boolean = false;
  mapReady: boolean = false;
  useMap: boolean;
  usesGeolocation: boolean;
  clientAlreadyExist: boolean;

  fnFilterEstablishment: any;

  constructor(
    public themeService: ThemeService,
    private meta: Meta,
    private uiService: UiService,
    private locationService: LocationService,
    private leadService: LeadService,
    private activeFrameService: ActiveFrameService,
    private translateService: TranslateService,
    private exceptionHandlerService: ExceptionHandlerService,
    private route: ActivatedRoute
  ) {
    this.useMap = environment.useMap;
    this.usesGeolocation = environment.usesGeolocation;
  }

  async ngOnInit() {
    // listen for search field value changes
    this.selectUnidadeFilterCtrl.valueChanges
      .pipe(takeUntil(this._onDestroy))
      .subscribe(() => {
        this.filterUnidades();
      });

    this.uiService.currentLanguage.subscribe(async lang => {
      //considerar lang como flag de inicialização (baseado em home.component)
      if (this.uiService.isBrowser && lang) {
        this.route.queryParams
          .subscribe(async params => {


            if (this.themeService.media_name && this.model.id == null) {
              this.model.mediaName = this.themeService.media_name;
            }

            weekdays = new Array(
              await this.translateService.get("sunday").toPromise(),
              await this.translateService.get("monday").toPromise(),
              await this.translateService.get("tuesday").toPromise(),
              await this.translateService.get("wednesday").toPromise(),
              await this.translateService.get("thursday").toPromise(),
              await this.translateService.get("friday").toPromise(),
              await this.translateService.get("saturday").toPromise()
            );

          });
      }
    });

  }

  async ngAfterViewInit() {
    this.activeStep.subscribe(async (active) => {
      if (active == this.stepName) {
        this.activeFrameService.moveTo("2");
      }
    });

    this.mainEvents.subscribe(async (event) => {
      if (event == "SelectEstablishment" ||
        event == "backFromSelectScheduler") {
        if (event == "backFromSelectScheduler") {
          this.model.organizationalStructure = null;
          this.clientAlreadyExist = true;
        }
        await this.loadEstablishments();
        await this.loadUserPosition();
      }
    });
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.activeStep.unsubscribe();
    this.mainEvents.unsubscribe();
  }

  refreshViewport() {
    this.meta.updateTag({ name: 'viewport', content: `height=${window.innerHeight},width=device-width,initial-scale=1` });
  }

  onChangeUnidade($event) {
    this.selecionarUnidade($event);
  }

  getWeekDay(daysOfWeek: number[]) {
    if (!daysOfWeek || !daysOfWeek.length || daysOfWeek.length <= 0) return '';
    if (!weekdays) return '';

    let fDay = weekdays[daysOfWeek[0]];
    let lDay = daysOfWeek.length > 1 ? ' à ' + weekdays[daysOfWeek[daysOfWeek.length - 1]] : '';
    return `${fDay}${lDay}`;
  }

  getEstablishmentDescription(description: string) {

    if (!description) return description;

    var i = description.lastIndexOf('-');

    if (i > 0) {
      return description.substr(i + 1).trim();
    }

    return description;
  }

  selecionarUnidade(selected: OrganizationalStructure) {

    this.model.organizationalStructure = selected;

    this.lat = selected.AddressLat;
    this.lng = selected.AddressLng;
  }

  protected async filterUnidades() {

    if (this.fnFilterEstablishment) {
      clearTimeout(this.fnFilterEstablishment);
    }

    // get the search keyword
    let search = this.selectUnidadeFilterCtrl.value;

    if (!search) {
      this.filteredEstablishments.next(this.establishments.slice());
      return;
    } else {
      search = search.toLowerCase();
    }

    this.fnFilterEstablishment = setTimeout(async () => {
      this.model.organizationalStructure = null;
      handleCustomDataLayer(2, search, undefined, undefined, undefined);
      this.establishments = await this.leadService.getEstablishmentsByTerms(search, this.model.attendantId, this.model.marketingDigitalBondId);
      this.filteredEstablishments.next(this.establishments.slice());
    }, 700);
  }

  async loadUserPosition() {
    try {

      if (environment.usesGeolocation) {

        var pos = await this.locationService.getPosition();

        this.userPosition = true;
        this.userLat = pos.lat;
        this.userLng = pos.lng;
        this.lat = pos.lat;
        this.lng = pos.lng;
        this.loadEstablishments();
      } else {
        this.userPosition = false;
      }
    }
    catch (e) {
      this.userPosition = false;
    }
    finally {
      this.mapReady = true;
      setTimeout(() => {
        let hmtmlE: HTMLElement = document.getElementById("mat-select-0").children.item(0) as HTMLElement;
        hmtmlE.click();
      }, 500);
    }
  }

  async loadEstablishments() {
    this.establishments = await this.leadService.getEstablishments(this.lat, this.lng, this.model.attendantId, this.model.marketingDigitalBondId);

    this.filteredEstablishments.next(this.establishments.slice());
  }

  async validate() {
    if (this.model.organizationalStructure == null) {
      throw new BusinessError(await this.uiService.translate("pleaseSelectEstablishment"));
    }
  }

  back() {
    this.model.organizationalStructure = null;
    this.clientAlreadyExist = true;
    this.onBack.emit(this.stepName);
  }

  async confirm() {
    try {
      await this.validate();
      this.onConfirm.emit(this.model);
      handleCustomDataLayer(2, undefined, this.model.organizationalStructure.Address, undefined, this.clientAlreadyExist || false);

    } catch (e) {
      await this.exceptionHandlerService.handle(e);
    }
  }
}
