import { Pipe, PipeTransform } from '@angular/core';
import { EncrypterService } from 'src/app/services/encrypter.service'

@Pipe({name: 'encrypt'})
export class EncryptPipe implements PipeTransform {
  transform(value) {
    return EncrypterService.encrypt(value);
  }
}

@Pipe({name: 'decrypt'})
export class DecryptPipe implements PipeTransform {
  transform(value) {
    return EncrypterService.decrypt(value);
  }
}