import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, } from '@angular/core';
import { Lead } from 'src/app/model/lead';
import { BusinessError } from 'src/app/helper/BusinessError';
import { UiService } from 'src/app/services/ui.service';
import { ActiveFrameService } from 'src/app/services/activeFrame.service';
import { Subject } from 'rxjs';
import { ExceptionHandlerService } from 'src/app/services/exceptionHandler.service';
import { OrganizationalStructure } from 'src/app/model/organizationalStructure';
import { ThemeService } from 'src/app/services/theme.service';
import { MarketingDigitalService } from 'src/app/services/marketing-digital.service';
import { ActivatedRoute, Router } from '@angular/router';
import { EncrypterService } from 'src/app/services/encrypter.service';

declare function handleCustomDataLayer(step, label, address, encryptedEmail, clientAlreadyExist): any

@Component({
  selector: 'app-unit-view',
  templateUrl: './unit-view.component.html',
  styleUrls: ['./unit-view.component.scss']
})
export class UnitViewComponent implements OnInit, AfterViewInit {

  establishments: OrganizationalStructure[] = [];

  filterView: boolean;

  stepName: string = 'SelectEstablishment';

  clientAlreadyExist: boolean;

  selectedUnit: any;

  filteredLat: any = null;

  filteredLng: any = null;

  userPosition: boolean;

  removedUnit: boolean = false;

  @Input() model: Lead;

  @Input() activeStep: Subject<string>;

  @Input() mainEvents: Subject<string>;

  @Output() onConfirm = new EventEmitter<Lead>();

  @Output() onBack = new EventEmitter<string>();

  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  constructor(
    private uiService: UiService,
    private activeFrameService: ActiveFrameService,
    private exceptionHandlerService: ExceptionHandlerService,
    public themeService: ThemeService,
    private route: ActivatedRoute,
    private marketingDigitalService: MarketingDigitalService) { }

  async ngOnInit() {
    this.uiService.currentLanguage.subscribe(async lang => {
      //considerar lang como flag de inicialização (baseado em home.component)
      if (this.uiService.isBrowser && lang) {
        this.route.queryParams
          .subscribe(async params => {
            if (this.themeService.media_name && this.model.id == null) {
              this.model.mediaName = this.themeService.media_name;
            }
          });
      }
    });
  }

  async ngAfterViewInit() {
    this.activeStep.subscribe(async (active) => {
      if (active == this.stepName) {
        this.activeFrameService.moveTo("2");
      }
    });
    this.mainEvents.subscribe(async (event) => {
      if (event == "SelectEstablishment" ||
        event == "backFromSelectScheduler") {
        if (event == "backFromSelectScheduler") {
          this.model.organizationalStructure = null;
          this.clientAlreadyExist = true;
          this.removeUnit();
        }
      }
    });
  }

  openFilter() {
    this.filterView = true;
  }

  closeFilter() {
    this.filterView = false;
  }

  back() {
    this.model.organizationalStructure = null;
    this.clientAlreadyExist = true;
    this.onBack.emit(this.stepName);
  }

  removeUnit() {
    this.filteredLat = null;
    this.filteredLng = null;
    this.selectedUnit = null;
    this.removedUnit = true;
  }

  async validate() {
    if (this.model.organizationalStructure == null) {
      throw new BusinessError(await this.uiService.translate("pleaseSelectEstablishment"));
    }
  }

  async selectUnit(unit: any) {
    this.selectedUnit = unit;
    if (this.model && this.model.organizationalStructure)
      this.model.organizationalStructure = unit;
  }

  async confirm() {
    try {
      this.model.organizationalStructure = this.selectedUnit;
      await this.validate();
      this.onConfirm.emit(this.model);
      handleCustomDataLayer(2, undefined, this.model.organizationalStructure.Address, undefined, this.clientAlreadyExist || false);

    } catch (e) {
      await this.exceptionHandlerService.handle(e);
    }
  }

  selectAddress(e) {
    this.filteredLat = e.item.Document?.AddressLat;
    this.filteredLng = e.item.Document?.AddressLng;
  }

  ngOnDestroy() {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.activeStep.unsubscribe();
    this.mainEvents.unsubscribe();
  }

  checkUserPosition(e) {
    this.userPosition = e;
    if (!e && (!this.filteredLat && !this.filteredLng)) {
      this.openFilter();
    }
  }
}
