<div class="scheduler-done-wrapper">

  <ngx-qrcode *ngIf="allowIndicateFriendsViaWhatsApp === false" [elementType]="elementType"
    [errorCorrectionLevel]="correctionLevel" [value]="value" cssClass="qrcode-size"></ngx-qrcode>


  <div *ngIf="topContent" [innerHTML]="topContent"></div>

  <div class="confirmation-card">
    <div class="scheduler-info">
      <p>
        <span class="scheduler-date">
          {{schedulerDate}}
        </span>
        <span class="scheduler-time">
          {{schedulerTime}}
        </span>
        <span style="display: block" class="clear"></span>
        <span class="scheduler-day-of-the-week">
          {{schedulerDay}}
        </span>
      </p>
    </div>
    <div class="organizational-structure-info">
      <div class="brand-icon">
        <img
          src="https://cms-dev.evup.com.br/api/assets/landing-page/36c97625-39e1-4e2d-a528-94a36dd9c317/logo-unity.png?version=0" />
      </div>
      <div class="organizational-structure-text">
        <p class="org-title">
          {{getEstablishmentDescription(lead?.organizationalStructure?.Description)?.toLowerCase()}}
        </p>
        <p class="org-description">
          {{establishment?.Address.StateAbrev}} - {{establishment?.Address.City?.toLowerCase()}}
        </p>
        <p class="org-address">
          {{establishment?.Address.Type?.toLowerCase()}} {{establishment?.Address.Description?.toLowerCase()}},
          {{establishment?.Address.Number?.toLowerCase()}} - {{establishment?.Address.Neighborhood?.toLowerCase()}}
        </p>
      </div>
    </div>
    <div class="scheduler-status">
      {{'confirmed' | translate}}
    </div>
  </div>

  <div *ngIf="showCalendar" class="calendar-events">
    <h2 class="add-event"> {{ 'addEvent' | translate }}: </h2>
    <div class="btn-wrapper">

      <a type="button" id="btnOpenGoogleCalendar" (click)="addGoogleCalendar()">
        <div>
          <img src="../../../assets/imagens/calendar1.png" />
        </div>
      </a>

      <a type="button" id="btnOpenAppleOutlookCalendar" (click)="addAppleOutlookCalendar()">
        <div>
          <img src="../../../assets/imagens/calendar2.png" />
        </div>
      </a>


    </div>
  </div>
  <div class="clear"></div>

  <div class="whatsapp-contact" *ngIf="showWhatsapp">
    <button type="button" class="btn-whatsapp" (click)="openWhatsappContact()">
      <img src="../../../assets/imagens/whatsapp-icon-white.png"/>
      {{'whatsappContact' | translate}}
    </button>
  </div>

  <!-- <div class="page-description" [innerHtml]="pageDescription">
  </div>

  <div class="invitation-links" *ngIf="showInvitationLinks">

    <h2>{{'inviteThrough' | translate}}</h2>

    <div class="btn-row">
      <span *ngFor="let invitationLink of invitationLinks; let i = index;">
        <div class="btn-share" [ngClass]="[invitationLink.class, 'pos' + i]"
          (click)="sendInvitation(invitationLink.action)">
          <div>
            <img src="{{invitationLink.imgUrl}}" />
            <span>{{invitationLink.text}}</span>
          </div>
        </div>
        <div *ngIf="invitationLinks.length > 2 && i === 1" style="clear: both;"></div>
      </span>
    </div>
    <div style="clear: both;"></div>

  </div> -->

  <!-- <div class="app-links" *ngIf="showAppLinks">
    <h2>{{pageContent?.appDescription}}</h2>
    <div class="app-links-wrapper">

      <div *ngIf="pageContent?.showAndroid" class="app-link app-link-android"
        [ngClass]="{'app-link-android-position' : showBothAppLinks}" (click)="redirectApp('android')">
        <img src="../../../assets/imagens/icon-google-play.png" />
      </div>

      <div *ngIf="pageContent?.showIos" class="app-link app-link-ios"
        [ngClass]="{'app-link-ios-position' : showBothAppLinks}" (click)="redirectApp('ios')">
        <img src="../../../assets/imagens/icon-app-store.png" />
      </div>
      <div style="clear:both"></div>
    </div>
  </div> -->

</div>