import { Component, Input, Output, EventEmitter, AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { Lead } from 'src/app/model/lead';
import { BehaviorSubject } from 'rxjs';
import { ActiveFrameService } from 'src/app/services/activeFrame.service';
import { UiService } from 'src/app/services/ui.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';
import { ThemeService } from 'src/app/services/theme.service';
import { NgxQrcodeElementTypes, NgxQrcodeErrorCorrectionLevels } from '@techiediaries/ngx-qrcode';
import { LinkService } from 'src/app/services/link.service';
import { calendarEventsService } from 'src/app/services/calendar-events.service';
import { SchedulerDoneService } from 'src/app/services/pages/scheduler-done.service';
import { DomSanitizer } from '@angular/platform-browser';
import { InviteService } from '../../services/invite.service';
import { ActivatedRoute } from '@angular/router';
import { EncrypterService } from '../../services/encrypter.service';
import { LeadService } from '../../services/lead.service';
import { EstablishmentService } from 'src/app/services/establishment.service';

declare function handleCustomDataLayer(step, label): any

export interface SchedulerDonePageContents {
  title: string;
  description: string;
  showWhatsapp: boolean;
  showFacebook: boolean;
  showLink: boolean;
  appDescription: string;
  showAndroid: boolean;
  showIos: boolean;
  ignoreEventCalendar: boolean;
  topHTML: string;
  htmlRefferFriends: string;
}
@Component({
  selector: 'app-scheduler-done',
  templateUrl: './scheduler-done.component.html',
  styleUrls: ['./scheduler-done.component.scss']
})
export class SchedulerDoneComponent implements OnInit, AfterViewInit, OnDestroy {

  public iFrame: boolean = false;

  public isFromFidelity: boolean = false;

  elementType = NgxQrcodeElementTypes.URL;
  correctionLevel = NgxQrcodeErrorCorrectionLevels.HIGH;
  value = "";

  stepName: string = 'SchedulerDone';

  allowManualReferFriends: boolean = environment.allowManualReferFriends;

  allowIndicateFriendsViaWhatsApp: boolean;

  success_message: any;

  pageContent: SchedulerDonePageContents = {
    title: null,
    description: null,
    showWhatsapp: null,
    showFacebook: null,
    showLink: null,
    appDescription: null,
    showAndroid: null,
    showIos: null,
    ignoreEventCalendar: null,
    topHTML: null,
    htmlRefferFriends: null
  };

  showInvitationLinks: boolean = false;

  showAppLinks: boolean = false;

  pageDescription: any;

  inviteLink: any;

  showBothAppLinks: boolean = false;

  appAndroidLink: string;

  appIosLink: string;

  invitationLinks: any[] = [];

  showCalendar: boolean = false;

  @Input() lead: Lead;

  @Input() selectedTime: Date;

  @Input() activeStep: BehaviorSubject<string>;

  @Output() onConfirm = new EventEmitter<Date>();

  @Output() onBack = new EventEmitter<string>();

  @Output() onSchedulerDone = new EventEmitter<string>();

  googleAppleDateFormat: string = 'yyyyMMddTHHmm00';
  outlookDateFormat: string = 'yyyy-MM-ddTHH:mm';
  endDateMinutes: number = 15;
  schedulerTime: any;
  schedulerDate: any;
  schedulerDay: any;
  establishment: any;
  contactPhone: any;
  showWhatsapp: boolean = false;
  topContent: any;
  UTMList: any[] = [];

  constructor(private activeFrameService: ActiveFrameService,
    private uiService: UiService,
    public datepipe: DatePipe,
    public themeService: ThemeService,
    private linkService: LinkService,
    private calendarEventServices: calendarEventsService,
    private schedulerDoneService: SchedulerDoneService,
    private sanitizer: DomSanitizer,
    private inviteService: InviteService,
    private route: ActivatedRoute,
    private leadService: LeadService,
    private establishmentService: EstablishmentService) { }

  async ngOnInit() {

    this.uiService.currentLanguage.subscribe(async lang => {
      //considerar lang como flag de inicialização (baseado em home.component)
      if (this.uiService.isBrowser && lang) {
        this.route.queryParams.subscribe(async params => {

          if (!this.themeService.has_scheduler_done_data_from_variants) {
            this.pageContent = await this.schedulerDoneService.initialize();
          } else {
            this.pageContent.title = this.themeService.scheduler_done_title;
            this.pageContent.description = this.themeService.scheduler_done_description;
            this.pageContent.showWhatsapp = this.themeService.scheduler_done_show_whatsapp;
            this.pageContent.showFacebook = this.themeService.scheduler_done_show_facebook
            this.pageContent.showLink = this.themeService.scheduler_done_show_link;
            this.pageContent.appDescription = this.themeService.scheduler_done_app_description;
            this.pageContent.showAndroid = this.themeService.scheduler_done_show_android;
            this.pageContent.showIos = this.themeService.scheduler_done_show_ios;
            this.pageContent.ignoreEventCalendar = this.themeService.scheduler_done_ignore_events_calendar;
            this.pageContent.topHTML = this.themeService.scheduler_done_top_html;
            this.pageContent.htmlRefferFriends = this.themeService.scheduler_done_reffer_friends_html;
          }

          if (this.pageContent && this.pageContent.topHTML)
            this.topContent = this.sanitizer.bypassSecurityTrustHtml(this.pageContent.topHTML);

          this.showCalendar = !this.pageContent?.ignoreEventCalendar;

          this.appAndroidLink = this.themeService.app_android_link;
          this.appIosLink = this.themeService.app_ios_link;
          this.showWhatsapp = this.themeService.show_whatsapp_contact_button;

          if (this.pageContent) {
            this.pageDescription = this.sanitizer.bypassSecurityTrustHtml(this.pageContent.description);
            this.showInvitationLinks = await this.checkForInvitationLinks();
            this.showAppLinks = await this.checkForAppLinks();
          }

          this.UTMList = this.inviteService.buildUTMList(params['utm_term'], params['utm_source'], params['utm_medium'], params['utm_campaign'], params['utm_content']);

          this.inviteLink = this.inviteService.buildInviteLink(
            this.lead.id,
            'lead',
            this.themeService.hasMarketingVinculo,
            this.themeService.hasVariant,
            false,
            this.UTMList,
            this.themeService.mktDigitalPath);

          var refferFriendsRedirect = this.inviteService.buildInviteLink(
            this.lead.id,
            'lead',
            this.themeService.hasMarketingVinculo,
            this.themeService.hasVariant,
            true, this.UTMList,
            this.themeService.mktDigitalPath);

          this.emitInviteLink(refferFriendsRedirect);
        });
      }
    });
  }

  async ngAfterViewInit() {
    this.activeStep.subscribe(async (active) => {
      if (active == this.stepName) {
        this.activeFrameService.moveTo("6");
        this.allowIndicateFriendsViaWhatsApp = (this.lead.indicateFriend == "true");
        if (this.allowIndicateFriendsViaWhatsApp === false) {
          this.shareWhatsapp();
        }
        if (this.themeService.success_message) {
          var message = this.themeService.success_message;

          //Tratamento para quebrar linha na mensagem vinda do CMS
          var index = message.indexOf(" ");
          var firstWord = message.slice(0, index);
          var text = message.slice(index + 1);

          this.success_message = firstWord + '<br/>' + text;
          this.success_message = this.sanitizer.bypassSecurityTrustHtml(this.success_message);
        }
        //Tratamento para separar data e hora do agendamento para exibição em tela
        if (this.uiService.isBrowser && this.selectedTime) {
          var d = new Date(this.selectedTime);
          this.schedulerTime = (d.getHours() < 10 ? '0' : '') + d.getHours() + ':' + (d.getMinutes() < 10 ? '0' : '') + d.getMinutes();
          this.schedulerDate = ((d.getDate() + 1) < 10 ? '0' : '') + d.getDate() + '/' + ((d.getMonth() + 1) < 10 ? '0' : '') + (d.getMonth() + 1);
          this.schedulerDay = this.getDayName(d, 'pt-BR').split('-')[0];
        }
        this.establishment = await this.leadService.getEstablishment(this.lead?.organizationalStructure?.Id);
        if (this.establishment) {
          this.handleWhatsappContactNumber();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.activeStep.unsubscribe();
  }

  shareWhatsapp() {

    var isWhatsApp = environment.shareLink.includes("{0}");

    var link = this.linkService.getLinkForIndication(this.lead.id, "lead");

    var msg = this.themeService.share_whatsapp.replace("{0}", link);

    msg = encodeURIComponent(msg);

    handleCustomDataLayer(4, 'Whatsapp');

    if (this.allowIndicateFriendsViaWhatsApp) {

      if (this.uiService.isBrowser) {
        if (isWhatsApp) {
          document.location.href = `https://wa.me/?text=${msg}`;
        } else {
          document.location.href = link;
        }
      }
    } else {
      this.value = `https://wa.me/?text=${msg}`;
    }
  }

  referFriend() {
    try {

      this.onConfirm.emit(this.selectedTime);
      handleCustomDataLayer(5, 'referFriend');

    } catch (e) {
      this.uiService.showError(e);
    }
  }

  openInviteForm() {
    document.location.href = this.linkService.getLinkForInvitationPage(this.lead.id, "lead");
  }

  async setEndDateMinutes(selectedTime) {
    var newDate = new Date(selectedTime);
    return new Date(newDate.setMinutes(newDate.getMinutes() + this.endDateMinutes)).toJSON();
  }

  async addGoogleCalendar() {
    window.open(this.calendarEventServices.makeGoogleCalendarUrl({
      name: await this.uiService.translate('eventCalendarName'),
      location: this.lead?.organizationalStructure?.Description,
      details: '',
      startsAt: this.datepipe.transform(this.selectedTime, this.googleAppleDateFormat),
      endsAt: this.datepipe.transform(await this.setEndDateMinutes(this.selectedTime), this.googleAppleDateFormat)
    }));
  }


  async addAppleOutlookCalendar() {
    document.location.href = this.calendarEventServices.makeICSCalendarUrl({
      name: await this.uiService.translate('eventCalendarName'),
      location: this.lead?.organizationalStructure?.Description,
      details: '',
      startsAt: this.datepipe.transform(this.selectedTime, this.googleAppleDateFormat),
      endsAt: this.datepipe.transform(await this.setEndDateMinutes(this.selectedTime), this.googleAppleDateFormat)
    });
  }

  async checkForInvitationLinks() {
    var any = this.pageContent.showWhatsapp || this.pageContent.showFacebook || this.pageContent.showLink;
    if (any) {
      this.buildInvitationLinks();
    }
    return any;
  }

  async checkForAppLinks() {
    this.showBothAppLinks = this.pageContent.showAndroid && this.pageContent.showIos;
    return this.pageContent.showAndroid || this.pageContent.showIos;
  }


  async sendToWhatsapp() {
    await this.inviteService.sendToWhatsapp(this.inviteLink, this.iFrame);
  }

  async sendToFacebook() {
    await this.inviteService.sendToFacebook(this.inviteLink, this.iFrame)
  }

  copyInviteLink() {
    this.inviteService.copyInviteLink(this.inviteLink, this.iFrame, this.isFromFidelity);
  }

  redirectApp(type: string) {
    var url = type === 'android' ? this.appAndroidLink : this.appIosLink;
    if (this.uiService.isBrowser) {
      window.open(url, '_blank');
    }
  }

  async buildInvitationLinks() {
    if (this.pageContent.showWhatsapp) {
      let text = await this.uiService.translate('whatsapp');
      this.invitationLinks.push({ class: 'share-whatsapp', action: 'whatsapp', text: text, imgUrl: '../../../assets/imagens/icon-whatsapp.png' });
    }
    if (this.pageContent.showFacebook) {
      let text = await this.uiService.translate('facebook');
      this.invitationLinks.push({ class: 'share-facebook', action: 'facebook', text: text, imgUrl: '../../../assets/imagens/icon-facebook.png' });
    }
    if (this.pageContent.showLink) {
      let text = await this.uiService.translate('link');
      this.invitationLinks.push({ class: 'share-link', action: 'link', text: text, imgUrl: '../../../assets/imagens/icon-link.png' });
    }
  }

  async sendInvitation(action: string) {
    switch (action) {
      case 'whatsapp':
        await this.sendToWhatsapp();
        break;
      case 'facebook':
        await this.sendToFacebook();
        break;
      case 'link':
        this.copyInviteLink();
        break;
    }
  }

  getDayName(dateStr, locale) {
    var date = new Date(dateStr);
    return date.toLocaleDateString(locale, { weekday: 'long' });
  }

  getEstablishmentDescription(description: string) {
    return this.establishmentService.getEstablishmentDescription(description);
  }

  emitInviteLink(refferFriendsRedirect) {
    this.onSchedulerDone.emit(refferFriendsRedirect);
  }

  handleWhatsappContactNumber() {
    if (this.establishment.Phones && this.establishment.Phones.length > 0) {
      let estab = this.establishment.Phones.filter(w => w.Type == 'WhatsappPhone' || w.Type == '6')[0];
      if (estab) {
        this.contactPhone = estab.Number;
      }
      if (!this.contactPhone) {
        this.contactPhone = this.themeService.whatsapp_relationship_center;
      }
    } else {
      this.contactPhone = this.themeService.whatsapp_relationship_center;
    }
    this.contactPhone = this.contactPhone.replace("(", "").replace(")", "")
      .replace(" ", "")
      .replace(" ", "")
      .replace(" ", "");
  }

  openWhatsappContact() {
    var msg = this.themeService.whatsapp_message;
    if (msg) {
      msg = encodeURIComponent(msg);
      var url = `https://wa.me/${this.contactPhone}?text=${msg}`;
      window.open(url, '_blank').focus();

    }
  }
}
