import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

const schedulerDoneQuery = gql`{
  querySchedulerDoneContents {
    flatData{
      title,
      description,
      showWhatsapp,
      showFacebook,
      showLink,
      appDescription,
      showAndroid,
      showIos,
      ignoreEventCalendar,
      topHTML
    }
  }
}`;

const schedulerDoneRefferQuery = gql`{
  querySchedulerDoneContents {
    flatData{
      htmlRefferFriends
    }
  }
}`;

@Injectable({
  providedIn: 'root'
})
export class SchedulerDoneService {

  result: any;
  resultReffer: any;
  constructor(private apollo: Apollo) { }


  async initialize(){

    if (!this.result) {
      var result = await this.apollo.watchQuery<any>({
        query: schedulerDoneQuery
      }).result();

      this.result = result.data.querySchedulerDoneContents[0].flatData;   
    }

    return this.result;

  }

  async initializeReffer(){

    if (!this.resultReffer) {
      var result = await this.apollo.watchQuery<any>({
        query: schedulerDoneRefferQuery
      }).result();

      this.resultReffer = result.data.querySchedulerDoneContents[0].flatData;   
    }

    return this.resultReffer;

  }

}
