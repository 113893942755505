import { Component, OnInit, Input, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-not-found',
  templateUrl: './not-found.component.html',
  styleUrls: ['./not-found.component.scss']
})
export class NotFoundComponent implements OnInit, AfterViewInit {

  constructor() { }
  
  ngAfterViewInit(): void {

  }

  ngOnInit(): void {
  }
}
