<h2 class="fs-title">{{ 'referFriends' | translate }}</h2>

<div style="max-height: 300px; overflow: auto; display: block;">
    <div *ngFor="let refer of referList">
        <p><mat-icon style="display: inline; font-size: 10px; color: red; cursor: pointer;" (click)="remove(refer)" *ngIf="referList.indexOf(refer) > 2" >close</mat-icon> Pessoa {{ referList.indexOf(refer)+1 }}:</p>
        <label class="w50">
            <input matInput type="text" placeholder="{{ 'name' | translate }}" [(ngModel)]="refer.name" name="name" />
        </label>
        <label class="w50">
            <input matInput type="tel" placeholder="{{ 'phone' | translate }}" [(ngModel)]="refer.phoneNumber" name="phoneNumber" [mask]="phoneMask[referList.indexOf(refer)]" (ngModelChange)="phoneChanged(refer);" />
        </label>
    </div>
</div>
<button mat-button color="primary" (click)="addRow()">{{ 'addMore' | translate }}</button>
<br/>
<button mat-button name="previous" class="previous action-button w50" (click)="back()" >{{ 'back' | translate }}</button>
<button mat-button name="next" class="next action-button w50" (click)="confirm()" >{{ 'finalize' | translate }}</button>