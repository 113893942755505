import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EncrypterService } from './encrypter.service';
import { ActivatedRoute, Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class LinkService {

  constructor(
    private route: ActivatedRoute,
    private router: Router) { }

  getLinkForIndication(id: number, type: string) : string {
    return this.getBasicLink(id, type);
  }

  getLinkForInvitationPage(id: number, type: string) : string {
    return this.getBasicLink(id, type) + "&invite=true";
  }

  private getBasicLink(id: number, type: string) : string {
    var paramPersonId = EncrypterService.encrypt(id.toString());
    paramPersonId = encodeURIComponent(paramPersonId);
    return environment.shareLink.replace("{0}", paramPersonId).replace("{1}", type);
  }

  getLinkVariantForInvitationPage(id: number, type: string, segmentation: string) : string {
    var paramPersonId = EncrypterService.encrypt(id.toString());
    paramPersonId = encodeURIComponent(paramPersonId);
    return environment.shareLinkVariant.replace("{0}", segmentation).replace("{1}", paramPersonId).replace("{2}", type) + "&invite=true";
  }

  getLinkVariantMarketingForInvitationPage(id: number, type: string, url: string) : string {
    var paramPersonId = EncrypterService.encrypt(id.toString());
    paramPersonId = encodeURIComponent(paramPersonId);
    return url + environment.shareComplementLink.replace("{0}", paramPersonId).replace("{1}", type) + "&invite=true";
  }

  getSegmentationInUrl(): string {
    var segmentation: string = null;
    if (this.route.snapshot.url.length > 0) {
      segmentation = this.route.snapshot.url[0].path;
    }
    else if (this.router.routerState.snapshot.url.length > 1) {
      segmentation = this.router.routerState.snapshot.url.substring(1);
    }
    if (segmentation && segmentation.indexOf('?') > - 1) {
      segmentation = segmentation.substring(0, segmentation.indexOf('?'));
    }
    if (segmentation && segmentation.indexOf('#') > -1) {
      segmentation = segmentation.substring(0, segmentation.indexOf('#'));
    }
    return segmentation;
  }

  getQueryStringInUrl(): string {
    var url = this.router.url;
    var queryString: string = null;
    queryString = url.substring(url.indexOf('?'));
    return queryString;
  }

}
