import { Component, OnInit, Input, AfterViewInit, OnDestroy, Inject, LOCALE_ID, Output, EventEmitter } from '@angular/core';
import { Lead } from 'src/app/model/lead';
import { BehaviorSubject, iif } from 'rxjs';
import { environment } from 'src/environments/environment';
import { SchedulerSearchVM } from 'src/app/model/schedulerSearchVM';
import { SchedulerService } from 'src/app/services/scheduler.service';
import { LabelType, Options } from '@angular-slider/ngx-slider';
import { DecimalPipe, formatNumber } from '@angular/common';
import Enumerable from 'linq';
import { UiService } from 'src/app/services/ui.service';
import { InitialThemeService } from 'src/app/services/initial-theme.service';

export interface SchedulerDays
{
  date: string,
  dayName: string,
  avaliable: boolean,
  rawDate: string,
  exibitionDate: string,
  isToday?: boolean,
  times: SchedulerTime[]
}

export interface SchedulerTime
{
  time: string;
  active: boolean;
}





@Component({
  selector: 'app-select-scheduler-select-day',
  templateUrl: './select-scheduler-select-day.component.html',
  styleUrls: ['./select-scheduler-select-day.component.scss']
})

export class SelectSchedulerSelectDayComponent implements OnInit, AfterViewInit, OnDestroy {

  schedulerSearch: SchedulerSearchVM = { lead: null, date: null, morning: true, afternoon: true, night: true, initDate: null, endDate: null, freeSchedule: false };

  @Input() model: Lead;

  @Input() activeStep: BehaviorSubject<string>;

  
  @Output() selectDate = new EventEmitter<any>();

  stepName: string = 'SelectScheduler';

  panelOpenState = false;

  daysArray: any[] = [];

  schedulerDaysToShow: SchedulerDays[] = [];

  isToday: boolean;

  quantityToShow: number = environment.schedulerDaysQuantityToShow;

  freeSchedule: boolean = false;

  groupedDatesResult: any[] = [];

  lang: string;

  isBrowser: boolean;

  showLoadMore: boolean = true;

  //configurações range slider
  minValue: number = 7;
  maxValue: number = 22;
  options: Options = {
    floor: 0,
    ceil: 24,
    step: 0.25,
    noSwitching: true,
    ticksArray: [0, 6, 12, 18, 24],
    translate: (value: number, label: LabelType): string => {
      const h = Math.floor(value);
      const hh = h;
      const mm = formatNumber((value - h) * 60, this.locale, '2.0-0');
      const aa = value < 12 ? 'AM' : 'PM';
      return `${hh}:${mm}`;
    },
    getLegend: (value: number): string => {
      if(value == 0 || value == 6){
        return "0" + value + 'h';
      }
      else if(value == 12 || value == 18 || value == 24) {
        return value + 'h';
      }
    },
    animate: false,
  };

  constructor(
    private schedulerService: SchedulerService,
    @Inject(LOCALE_ID) private locale: string,
    private uiService: UiService,
    private themeService: InitialThemeService
    ) { }

   ngOnInit() {
    this.uiService.currentLanguage.subscribe(async lang => {
      this.lang = lang;
    });
    this.isBrowser = this.uiService.isBrowser;
  }

  async ngAfterViewInit() {
    this.activeStep.subscribe(async (active) => {
      if (active == this.stepName) {
        if (this.model) {
          this.clear();
          this.schedulerSearch.lead = this.model;
          this.freeSchedule = this.themeService.use_free_schedule;
          await this.loadTimes();
        }
      }
    });
  }

  ngOnDestroy(): void {
    this.activeStep.unsubscribe();
    this.uiService.currentLanguage.unsubscribe();
  }

  /**
   * retorna todos os dias entre o range informado 
   * @param start 
   * @param end 
   * @returns 
   */
  getDaysArray(start, end) {
    for (var arr = [], dt = new Date(start); dt <= new Date(end); dt.setDate(dt.getDate() + 1)) {
      arr.push(new Date(dt));
    }
    return arr;
  }


  /**
   * retorna o nome dos dias da semana
   * @param dateStr 
   * @param locale 
   * @returns 
   */
  getDayName(dateStr, locale) {
    var date = new Date(dateStr);
    return date.toLocaleDateString(locale, { weekday: 'long' });
  }

  /**
   * Retorna a data máxima disponível para agendamento
   * @returns 
   */
  getMaxDate(): Date {
    var base = new Date();
    let interval = environment.schedulerInterval;

    if (this.model && this.model.schedulerSuggestionInterval) {
      interval = this.model.schedulerSuggestionInterval;
    }
    base.setDate(base.getDate() + interval);

    return base;
  }

  


  /**
   * Verifica se a primeira data da lista é a data de hoje
   * @returns 
   */
  isFirstDateToday(): boolean{
    var date = this.daysArray[0].date.getFullYear() + '/' + (this.daysArray[0].date.getMonth() + 1) + '/' + this.daysArray[0].date.getDate();
    var compareDate = new Date();
    var date2 = compareDate.getFullYear() + '/' + (compareDate.getMonth() + 1) + '/' + compareDate.getDate();
    return date == date2;
  }

  /**
   * Carrega os horários e dias disponíveis para agendamento
   * 
   */
  async loadTimes(addDays: boolean = false) {
    
    let datesResult = [];

    this.schedulerSearch.freeSchedule = this.freeSchedule;
    
    var filters = this.buildFilters(addDays);

    this.schedulerSearch.initDate = filters.initialDate;
    this.schedulerSearch.endDate = filters.endDate;

    var data = await this.schedulerService.getAvaliableTimes(this.schedulerSearch);
    
    if(data){

      var groupedResult;

      if(data.times){

        data.times.forEach(item => {
            datesResult.push({date: new Date(item), rawDate: item, formatedDate: null, times: null, sugestions: null});
        });

        datesResult.forEach(item => {          
          item.formatedDate = item.date.getFullYear() + '/' + (item.date.getMonth() + 1) + '/' + item.date.getDate();      
        });

        groupedResult = Enumerable.from(datesResult)
        .groupBy(r => r.formatedDate)
        .select(g => {
          return {
            date: g.key(),
            times: Enumerable.from(g.getSource()).select(s => {
              return {
                time: s.date.toLocaleString(this.lang, {hour: '2-digit', minute: '2-digit'}),
                rawDate: s.rawDate,
                active: false,
                hidden: false
              };
            }).toArray()
          };
        }).toArray();
        
        
      }else if(data.sugestions){

        data.sugestions.forEach(item => {
            datesResult.push({date: new Date(item), rawDate: item, formatedDate: null, times: null, sugestions: null});
        });

        datesResult.forEach(item => {          
          item.formatedDate = item.date.getFullYear() + '/' + (item.date.getMonth() + 1) + '/' + item.date.getDate();      
        });

        groupedResult = Enumerable.from(datesResult)
        .groupBy(r => r.formatedDate)
        .select(g => {
          return {
            date: g.key(),
            sugestions: Enumerable.from(g.getSource()).select(s => {
              return {
                time: s.date.toLocaleString(this.lang, {hour: '2-digit', minute: '2-digit'}),
                rawDate: s.rawDate,
                active: false,
                hidden: false
              };
            }).toArray()
          };
        }).toArray();
      }

      
      this.groupedDatesResult = groupedResult;

      this.applyFilter();

      this.buildDaysList();
    }
  }

  /**
   * Monta a lista dos dias para exibir em tela
   */
   buildDaysList() {
    this.daysArray = [];
    //lista com os dias disponíveis dentro do range  
    let avaliableDaysList = [];
    avaliableDaysList = this.getDaysArray(new Date(), this.getMaxDate());
    //monta um array de objetos com as datas disponíveis e os nomes dos dias da semana
    avaliableDaysList.forEach(item => {
      this.daysArray.push({
        dayName: this.getDayName(item, this.lang).split('-')[0],
        date: item
      });
    });

    this.isToday = this.isFirstDateToday();

    if (this.isToday) {
      this.daysArray[0].isToday = true;
    }

    //monta um novo array cruzando as listas de datas afim de descobrir se a data possui horários disponíveis
    let daysList = [];
    this.daysArray.forEach(item => {
      var formatedDate = item.date.getFullYear() + '/' + (item.date.getMonth() + 1) + '/' + item.date.getDate();
      var resultDate = this.groupedDatesResult.filter(e => e.date === formatedDate);      
      daysList.push({
        date: formatedDate,
        dayName: item.dayName,
        avaliable: resultDate.length > 0,
        rawDate: item.date,
        exibitionDate: item.date.toLocaleString(this.lang, {month: "2-digit", day: "2-digit"}),
        isToday: item.isToday,
        times: Enumerable.from(resultDate).select(s => s.times).firstOrDefault(),
        sugestions: Enumerable.from(resultDate).select(s => s.sugestions).firstOrDefault()
      });
    }); 
    
    
    this.schedulerDaysToShow = daysList.slice(0, this.quantityToShow);    
  }

  /**
   * Exibe novos dias para agendamento de acordo com o parâmetro schedulerDaysQuantityToShow
   */
  async loadMoreDays(){
    this.quantityToShow += environment.schedulerDaysQuantityToShow;
    await this.loadTimes(true);
  }

  /**
   * Filtra os horários a serem exibidos de acordo com o slider
   */
  applyFilter(){
    this.groupedDatesResult.forEach(item =>{
      
      if(item.times){
        item.times.forEach(time => {
          if(time.time < this.formatLabel(this.minValue) || time.time > this.formatLabel(this.maxValue)){
            time.hidden = true;
          }else{
            time.hidden = false;
          }
        });
      }
      if(item.sugestions){
        item.sugestions.forEach(time => {
          if(time.time < this.formatLabel(this.minValue) || time.time > this.formatLabel(this.maxValue)){
            time.hidden = true;
          }else{
            time.hidden = false;
          }
        });
      }
    });
  }

  /**
   * Aplica o controle dos horários selecionados
   * @param time 
   * @param date 
   */
  selectTime(time, date){

    //Limpa a flag "active" de todos os horários
    this.schedulerDaysToShow.forEach(item => {
      if(item.times && item.times.length > 0){
        item.times.forEach(time =>{
          time.active = false;
        });
      }      
    });

    //Aplica a flag "active" ao horário selecionado
    var selectedDate = this.schedulerDaysToShow.filter(w => w.date == date)[0];
    var selectedTime;
    if(selectedDate && selectedDate.times){
      selectedTime = selectedDate.times.filter(w => w.time == time.time)[0];
      if(selectedTime){
        selectedTime.active = true;
        this.selectDate.emit(selectedTime.rawDate);     
      }
    }
  }

  /**
   * Formata a exibição dos valores do filtro para o formato de horas
   * @param value 
   * @returns 
   */
  formatLabel(value: number | null) {
    if (!value) {
      return '00:00';
    }

    let decimalPart = +value.toString().replace(/^[^\.]+/,'0');
    let mm = decimalPart * 60;
    var mmPart = mm.toString().length == 1 ? mm.toString() + "0" : mm.toString();

    if (value >= 0) {
      let valueStr = value.toFixed(2);
      let strArr = valueStr.split(".");
      if(strArr[0].length == 1) {
        strArr[0] = "0" + strArr[0];
      }
      var hhPart = strArr[0];
    }

    return hhPart + ":" + mmPart;
 }


 /**
  * Constrói os filtros a serem utilizados para consultar as datas e horários
  * @param addDays Indica se devem ser adicionadas novas datas e horários a lista já existente 
  * @returns 
  */
 buildFilters(addDays: boolean = false){
    var result = {
      initialDate: new Date(),
      endDate: new Date()
    }

    if(!addDays){    
      result.endDate.setDate(result.initialDate.getDate() + (environment.schedulerDaysQuantityToShow - 1));
    }else{
      result.endDate.setDate(result.endDate.getDate() + (this.quantityToShow - 1));
    }

    let maxDate = this.getMaxDate();

    //Se a data final for maior que a data máxima permitida, setamos a data final como a data máxima e desabilitamos o botão "carregar mais"
    if(result.endDate > maxDate){
      result.endDate.setDate(maxDate.getDate());
      this.showLoadMore = false;
    }

    return result;

 }

 clear(){
  this.quantityToShow = environment.schedulerDaysQuantityToShow;
  this.schedulerDaysToShow = [];
  this.schedulerSearch.initDate = null;
  this.schedulerSearch.endDate = null;
  this.groupedDatesResult = [];
  this.daysArray = [];
 }

}
