import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

const dataQuery = gql`{
    queryVariantesContents {
       flatData {
        id
      }
    }
  }`;

const dataQueryMarketingDigital = gql`{
    queryVariantesMarketingDigitalVinculoContents {
       flatData {
        id
      }
    }
  }`;

@Injectable({
    providedIn: 'root'
})
export class SegmentationService {

    public variants: string[] = [];

    constructor(private apollo: Apollo) {
    }

    async initialize(isMarketingDigital : boolean = false) {

        if (isMarketingDigital)
        {
            var result = await this.apollo.watchQuery<any>({
                query: dataQueryMarketingDigital
            }).result();

            var records = <any[]>result.data.queryVariantesMarketingDigitalVinculoContents;
            for (let index = 0; index < records.length; index++) {
                const element = records[index].flatData;
    
                this.variants[index] = element.id;            
            }
        }
        else
        {
            var result = await this.apollo.watchQuery<any>({
                query: dataQuery
            }).result();
    
            var records = <any[]>result.data.queryVariantesContents;
            for (let index = 0; index < records.length; index++) {
                const element = records[index].flatData;
    
                this.variants[index] = element.id;            
            }
   
        }
    }

    isSegmentationValid(segmentation: string) : boolean {
        return this.variants && this.variants.includes(segmentation);
    }
}