import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { BehaviorSubject, Subject } from 'rxjs';
import { BusinessError } from 'src/app/helper/BusinessError';
import { Lead } from 'src/app/model/lead';
import { ActiveFrameService } from 'src/app/services/activeFrame.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-fidelity-term',
  templateUrl: './fidelity-term.component.html',
  styleUrls: ['./fidelity-term.component.scss']
})
export class FidelityTermComponent implements OnInit {


  protected _onDestroy = new Subject<void>();

  @Input() model: Lead;

  @Input() activeStep: BehaviorSubject<string>;

  @Output() onConfirm = new EventEmitter<Lead>();

  fidelityTermChecked: boolean;

  constructor(
    private uiService: UiService,
    private activeFrameService: ActiveFrameService,
    public modal: MatDialog) { }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
    this.activeStep.unsubscribe();
  }

  async ngOnInit() {
  }

  async validate() {
    if (this.fidelityTermChecked == false) {
      throw new BusinessError(await this.uiService.translate("pleaseYouHaveToAcceptTheTerm"));
    }
  }

  async confirm() {
    try {

      await this.validate();

      this.model.fidelityTerm = this.fidelityTermChecked;

      this.onConfirm.emit(this.model);

    } catch (e) {

      this.uiService.showError(e);

    }
  }

}
