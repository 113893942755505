export class organizationalStructureConfig {
    constructor(
        public establishmentId: number,
        public configName: string,
        public establishmentLevel: string
    ) { }
}

export class organizationalStructureConfigRoot {
    constructor(
        public configName: string,
    ) { }
}

export class OrganizationalStructureConfigResult {
    public error: boolean = false;
    public model: organizationalStructureConfigModel;

}

interface organizationalStructureConfigModel {
    organizationalStructureId: number,
    configId: number,
    configName: string,
    value: any
}