import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { UiService } from 'src/app/services/ui.service';

@Injectable({
  providedIn: 'root'
})

export class SeoService { 

  constructor(private uiService: UiService, @Inject(DOCUMENT) private doc) {}

  createLinkForCanonicalURL() : void {
     let link: HTMLLinkElement = this.doc.createElement('link');
     link.setAttribute('rel', 'canonical');
     this.doc.head.appendChild(link);
     link.setAttribute('href', this.doc.URL);
   }

   createGTM(gtm: string) : void {
    if(this.uiService.isBrowser && gtm){
      let script: HTMLScriptElement = this.doc.createElement('script');
      script.innerText = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':"+
      "new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],"+
      "j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src="+
      "'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);"+
      "})(window,document,'script','dataLayer','"+ gtm +"');";
      this.doc.head.prepend(script);

      let noscript: HTMLElement = this.doc.createElement('noscript');
      // let iframe: HTMLElement = this.doc.createElement('iframe');
      // iframe.setAttribute('height', '0');
      // iframe.setAttribute('width', '0');
      // iframe.setAttribute('style', 'display:none;visibility:hidden');
      // iframe.setAttribute('href', 'https://www.googletagmanager.com/ns.html?id='+ gtm);
      noscript.innerText = "<iframe src='https://www.googletagmanager.com/ns.html?id="+ gtm +"' height='0' width='0' style='display:none;visibility:hidden'></iframe>";
      // noscript.appendChild(iframe);
      this.doc.body.prepend(noscript);    
    }
  }
}