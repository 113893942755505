import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  alreadyAskedPermission = false;
  location: any;
  error: any;

  getPosition(): Promise<any> {
    return new Promise((resolve, reject) => {

      //para evitar ficar questionando o usuário
      //como no caso do browser interno do instagram
      if (this.alreadyAskedPermission) {
        if (this.location) {
          resolve(this.location);
        } else {
          reject(this.error);
        }
      } else {
        this.alreadyAskedPermission = true;

        navigator.geolocation.getCurrentPosition(
          resp => {
            this.location = { lng: resp.coords.longitude, lat: resp.coords.latitude };
            resolve(this.location);
          },
          err => {
            this.error = err;
            reject(err);
          });
      }

    });

  }

  getDistance(lat1, lon1, lat2, lon2): number {
    const radlat1 = Math.PI * lat1 / 180;
    const radlat2 = Math.PI * lat2 / 180;
    const theta = lon1 - lon2;
    const radtheta = Math.PI * theta / 180;
    let dist = Math.sin(radlat1) * Math.sin(radlat2) + Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = dist * 180 / Math.PI;
    dist = dist * 60 * 1.1515;
    dist = dist * 1.609344; //kilometers

    return dist;
  }
}
