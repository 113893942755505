import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { AgmCoreModule } from '@agm/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { MatIconModule } from '@angular/material/icon';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NgxMaskModule } from 'ngx-mask';
import { InitialFormComponent } from './components/initial-form/initial-form.component';
import { InviteFormComponent } from './components/invite-form/invite-form.component';
import { SelectEstablishmentComponent } from './components/select-establishment/select-establishment.component';
import { SelectSchedulerComponent } from './components/select-scheduler/select-scheduler.component';
import { DoSchedulerComponent } from './components/do-scheduler/do-scheduler.component'
import { MatDialogModule } from '@angular/material/dialog';

import { RECAPTCHA_V3_SITE_KEY, RecaptchaV3Module } from 'ng-recaptcha';

import { SchedulerDoneComponent } from './components/scheduler-done/scheduler-done.component';
import { DatePipe } from '@angular/common';
import { ReferFriendComponent } from './components/refer-friend/refer-friend.component'
import { RequestInterceptor } from './helper/request.interceptor';
import { MatButtonModule } from '@angular/material/button';
import { FinalMessageComponent } from './components/final-message/final-message.component';
import { environment } from 'src/environments/environment';
import { GraphQLModule } from './graphql.module';
import { CmsRequestInterceptor } from './helper/cms.interceptor';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { TranslateUniversalLoader } from './helper/TranslateUniversalLoader';
import { MatDividerModule } from '@angular/material/divider';
import { ConsentTermComponent } from './components/consent-term/consent-term.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { EncryptPipe, DecryptPipe } from 'src/app/pipes/encrypter.pipe';
import { ConsentTermCheckboxComponent } from './components/consent-term/consent-term-checkbox/consent-term-checkbox.component';
import { ShareButtonsConfig } from 'ngx-sharebuttons';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';
import { NotFoundComponent } from './components/not-found/not-found.component';
import { cookieConfig } from './helper/cookieConsentConfig';
import { NgcCookieConsentModule } from 'ngx-cookieconsent';
import { DialogContentPrivacyPolicy } from './DialogContentPrivacyPolicy';
import { DialogContentCookiePolicy } from './DialogContentCookiePolicy';
import { FidelityDashboardComponent } from './components/fidelity-dashboard/fidelity-dashboard.component';
import { HomeComponent } from './pages/home/home.component';
import { HubComponent } from './components/hub/hub.component';
import { UnitViewComponent } from './components/unit/unit-view/unit-view.component';
import { UnitSelectionListComponent } from './components/unit/unit-selection-list/unit-selection-list.component';
import { UnitFilterComponent } from './components/unit/unit-filter/unit-filter.component';
import { UnitSelectedComponent } from './components/unit/unit-selected/unit-selected.component';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { ScheduleManagementComponent } from './components/schedule-management/schedule-management.component';
import { FidelityTermCheckboxComponent } from './components/fidelity-term/fidelity-term-checkbox/fidelity-term-checkbox.component';
import { FidelityTermComponent } from './components/fidelity-term/fidelity-term.component';
import { RefferAreaComponent } from './components/reffer-area/reffer-area.component';
import { TextMaskModule } from 'angular2-text-mask';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { SelectSchedulerTopContentComponent } from './components/select-scheduler/select-scheduler-top-content/select-scheduler-top-content.component';
import { SelectSchedulerSelectDayComponent } from './components/select-scheduler/select-scheduler-select-day/select-scheduler-select-day.component';
import {MatExpansionModule} from '@angular/material/expansion';
import { NgxSliderModule } from '@angular-slider/ngx-slider';

const customData = {
  text: 'Copiar link',
  icon: ['fas', 'link'],
  successText: 'Copiado',
  successIcon: ['fas', 'check'],
  delay: 2000
};

const customConfig: ShareButtonsConfig = {
  prop: {
    whatsapp: {
      text: 'Indicar por WhatsApp',
      share: {
        android: 'https://wa.me/?',
      }
    },
    facebook: {
      text: 'Compartilhar no Facebook'
    },
    twitter: {
      text: 'Compartilhar no Twitter'
    },
    telegram: {
      text: 'Indicar por Telegram'
    },
    email: {
      text: 'Indicar por e-mail'
    },
    copy: {
      text: 'Copiar link',
      data: customData
    },
  }
}

registerLocaleData(localePt)

export function createTranslateLoader() {
  return new TranslateUniversalLoader();
}

@NgModule({
  declarations: [
    AppComponent,
    DialogContentPrivacyPolicy,
    DialogContentCookiePolicy,
    InitialFormComponent,
    SelectEstablishmentComponent,
    SelectSchedulerComponent,
    DoSchedulerComponent,
    SchedulerDoneComponent,
    ReferFriendComponent,
    FinalMessageComponent,
    NotFoundComponent,
    ConsentTermComponent,
    InviteFormComponent,
    EncryptPipe,
    DecryptPipe,
    ConsentTermCheckboxComponent,
    FidelityDashboardComponent,
    HomeComponent,
    HubComponent,
    UnitViewComponent,
    UnitSelectionListComponent,
    UnitFilterComponent,
    UnitSelectedComponent,
    ScheduleManagementComponent,
    FidelityTermCheckboxComponent,
    FidelityTermComponent,
    RefferAreaComponent,
    SelectSchedulerTopContentComponent,
    SelectSchedulerSelectDayComponent
  ],
  imports: [
    FormsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatSelectModule,
    MatProgressSpinnerModule,
    HttpClientModule,
    NgxMatSelectSearchModule,
    MatIconModule,
    MatDatepickerModule,
    MatCheckboxModule,
    RecaptchaV3Module,
    MatButtonModule,
    MatDialogModule,
    MatDividerModule,
    NgxMaskModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    AgmCoreModule.forRoot({
      apiKey: environment.googleApiKey,
      libraries: ["places"]
    }),
    GraphQLModule,
    NgxQRCodeModule,
    ShareButtonsModule.withConfig(customConfig),
    ShareIconsModule, // Optional if you want the default share icons
    NgcCookieConsentModule.forRoot(cookieConfig),
    InfiniteScrollModule,
    TextMaskModule,
    Ng2TelInputModule,
    MatExpansionModule,
    NgxSliderModule

  ],
  providers: [DatePipe,
    { provide: HTTP_INTERCEPTORS, useClass: RequestInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: CmsRequestInterceptor, multi: true },
    { provide: RECAPTCHA_V3_SITE_KEY, useValue: environment.recaptcha_siteKey }],
  bootstrap: [AppComponent]
})
export class AppModule { }
