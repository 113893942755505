import { Component, OnInit, OnDestroy, Inject, HostListener, ElementRef } from '@angular/core';
import { UiService } from 'src/app/services/ui.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { AppDateAdapter, APP_DATE_FORMATS } from 'src/app/adapters/appDateAdapter';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { InitialThemeService } from 'src/app/services/initial-theme.service';
import { MatDialog } from '@angular/material/dialog';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { LeadService } from 'src/app/services/lead.service';
import { EncrypterService } from 'src/app/services/encrypter.service';
import { Renderer2 } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { ValidationService } from 'src/app/services/validation.service';
import { Subscription } from 'rxjs';
import { ShortlinkService } from 'src/app/services/shortlink.service';
import { FingerPrintService } from 'src/app/services/fingerPrint.service';
import { Lead } from 'src/app/model/lead';
import { DialogContentPrivacyPolicy } from 'src/app/DialogContentPrivacyPolicy';
import { DialogContentCookiePolicy } from 'src/app/DialogContentCookiePolicy';
import { ExceptionHandlerService } from 'src/app/services/exceptionHandler.service';
import { MarketingDigitalService } from 'src/app/services/marketing-digital.service';
import { ELeadState } from 'src/app/model/enum.leadState';
import { OrganizationalStructure } from 'src/app/model/organizationalStructure';
import { DomSanitizer } from '@angular/platform-browser';
import { InviteService } from 'src/app/services/invite.service';
import { ThemeService } from 'src/app/services/theme.service';

declare function topFunction(): any

export interface UTMs {
  utm: string;
  value: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class HomeComponent implements OnInit, OnDestroy {

  activeStep: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  mainEvents: Subject<string> = new Subject();

  queryString: any;

  model: Lead;

  timesAndSuggestions: any;

  selectedTime: Date;

  isLoading: boolean = false;

  isEstablishmentPreSelected: boolean = false;

  isFromApp: boolean = false;

  statusChangeSubscription: Subscription;

  ignoreSaving: boolean = false;

  isFromFidelity: boolean = false;

  backgroundImg: string;

  useNewEstablishmentSearch: boolean;

  leadState: ELeadState = ELeadState.NewRegister;

  currentLeadEstablishment: OrganizationalStructure = null;

  banners: any[];

  hasMarketingVinculo: boolean = false;

  stepName: string;

  refferShareLink: string;

  showHtmlTopo: boolean = false;

  inviteLink: string;

  UTMList: UTMs[] = [];

  @HostListener('document:click', ['$event'])
  clickout(event) {
    if (this.eRef.nativeElement.contains(event.target) && event.target.tagName.toLowerCase() === 'a') {
      if (event.srcElement.attributes.href.nodeValue.startsWith('#')) {
        if (this.route.snapshot.url.length > 0)
          this.router.navigate(['/' + this.route.snapshot.url[0].path], { queryParamsHandling: 'preserve', queryParams: this.queryString, fragment: event.srcElement.attributes.href.nodeValue.substring(1) });
        else
          this.router.navigate([''], { queryParams: this.queryString, fragment: event.srcElement.attributes.href.nodeValue.substring(1) });

        return false;
      }
    }
  }

  constructor(
    private uiService: UiService,
    private exceptionHandlerService: ExceptionHandlerService,
    private route: ActivatedRoute,
    public dialog: MatDialog,
    public initialThemeService: InitialThemeService,
    private leadService: LeadService,
    private recaptchaV3Service: ReCaptchaV3Service,
    @Inject(DOCUMENT) private document: any,
    private renderer: Renderer2,
    private shortlinkService: ShortlinkService,
    private fingerPrintService: FingerPrintService,
    private router: Router,
    private marketingDigitalService: MarketingDigitalService,
    private eRef: ElementRef,
    private sanitizer: DomSanitizer,
    private inviteService: InviteService,
    private themeService: ThemeService
  ) {
    this.initModel();
  }

  initModel() {
    if (!environment.mock) {
      this.model = {
        id: null,
        name: null,
        email: null,
        phoneNumber: "",
        //phoneCountryCode: "",
        doc: "",
        organizationalStructure: null,
        status: 1,
        isBlockToCall: true,
        marketingDigitalBondId: null,
        attendantId: null,
        schedulingAttendantId: null,
        indicatedByLead: null,
        indicatedBy: null,
        externalTrackingCode: null,
        internalTrackingCode: null,
        nextAvaliableDate: null,
        UTM_Source: null,
        UTM_Medium: null,
        UTM_Campaign: null,
        UTM_Content: null,
        UTM_Term: null,
        consentTerm: false,
        indicateFriend: "true",
        media: null,
        mediaName: null,
        gclid: null,
        fingerPrint: null,
        segmentation: null,
        Schedule: null,
        IsTermPending: true,
        LeadMembershipType: null,
        fidelityTerm: false
      };

    } else {

      this.model = {
        id: null,
        name: 'Guilherme Furlan',
        email: 'guilherme.furlan@evup.com.br',
        phoneNumber: Math.round((Math.random() * 10000000000)).toString(),
        //phoneCountryCode: "55",
        doc: null,
        organizationalStructure: null,
        status: 1,
        isBlockToCall: true,
        marketingDigitalBondId: null,
        attendantId: null,
        schedulingAttendantId: null,
        indicatedByLead: null,
        indicatedBy: null,
        externalTrackingCode: null,
        internalTrackingCode: null,
        nextAvaliableDate: null,
        UTM_Source: null,
        UTM_Medium: null,
        UTM_Campaign: null,
        UTM_Content: null,
        UTM_Term: null,
        consentTerm: false,
        indicateFriend: "true",
        media: null,
        mediaName: null,
        gclid: null,
        fingerPrint: null,
        segmentation: null,
        Schedule: null,
        IsTermPending: true,
        LeadMembershipType: null,
        fidelityTerm: false
      };
    }
  }

  ngOnDestroy(): void {
    this.model = null;
    this.uiService.onLoading.unsubscribe();
    this.uiService.currentLanguage.unsubscribe();
  }

  async ngOnInit() {

    this.useNewEstablishmentSearch = environment.useNewEstablishmentSearch;
    this.uiService.onLoading.subscribe((isLoading) => {
      setTimeout(() => {
        this.isLoading = isLoading;
      });
    });
    this.activeStep.subscribe(async (active) => {
      if (active) {
        this.stepName = active;
        var banner = this.initialThemeService.applyBanner(active); // this.bannersService.applyBanner(active);
        this.applyShowHtmlTopo(active);
        if (banner) {
          this.applyBanner(banner);
        } else {
          this.backgroundImg = this.initialThemeService.backgroundImage;

          if (this.uiService.isBrowser)
            (document.querySelector('.sec-ch-banner') as HTMLElement).style.backgroundColor = this.initialThemeService.corDeFundo;
        }
      }
    });

    var firstAction = true;
  
    this.uiService.currentLanguage.subscribe(async lang => {

      if (!lang || !this.uiService.isBrowser) return;

      this.route.queryParams.subscribe(async params => {
        this.queryString = params;
        if (params['auth'] && this.uiService.isBrowser) {
          var auth = await this.leadService.authenticateEmailLead(params['auth']);
          if (auth) {
            this.uiService.showMessage({
              title: "",
              text: await this.uiService.translate("emailAuthenticated"),
            });
          } else {
            this.uiService.showMessage({
              title: "",
              text: await this.uiService.translate("emailAlreadyAuthenticated"),
            });
          }
        }

        if (params['t']) {
          var result = await this.shortlinkService.getShortenerURLByToken(params['t']);
          if (result && !result.error && result.url) {

            //concatenar de volta os parâmetros anteriores
            const paramNames = Object.getOwnPropertyNames(params).filter(p => p !== 't');
            for (let i = 0; i < paramNames.length; i++) {
              result.url += `&${paramNames[i]}=${encodeURIComponent(params[paramNames[i]])}`
            }

            if (this.uiService.isBrowser) {
              var url = new URL(`${location.origin}?${result.url}`);
              if (url.pathname) {

                setTimeout(() => {
                  if (url.search) {

                    var first = Array.from(result.url)[0];

                    if (first == '/') {
                      this.document.location.href = `${location.origin}${result.url}`;
                    } else {
                      this.document.location.href = `${location.origin}?${result.url}`;
                    }

                  } else {
                    this.router.navigate([url.pathname]);
                  }
                }, 100);
              }
            }
          }
        }

        if (params['iframe'] == 'indique') {
          this.isFromFidelity = true;
        }

        if (params['codigoAcompanhamento']) {
          this.model.externalTrackingCode = params['codigoAcompanhamento'];
        }

        if (params['m_id']) {
          this.model.internalTrackingCode = params['m_id'];
        }

        if (params['nome']) {
          this.model.name = params['nome'];
        }

        if (params['telefone']) {
          this.model.phoneNumber = params['telefone'];
        }

        if (params['email']) {
          this.model.email = params['email'];
        }

        if (params['unidade']) {
          let pUnidade = params['unidade'];

          let id: number;

          if (ValidationService.isOnlyNumbers(pUnidade)) {
            id = pUnidade;
          } else {
            let valUnidade = EncrypterService.decrypt(pUnidade);
            id = Number.parseInt(valUnidade);
          }

          let organizationalStructure = await this.leadService.getEstablishment(id);

          this.model.organizationalStructure = organizationalStructure;
          this.isEstablishmentPreSelected = true;
        }
        else {
          this.isEstablishmentPreSelected = false;
        }

        if (params['vinculo']) {
          let pMarketingDigitalBond = params['vinculo'];
          let valMarketingDigitalBond = EncrypterService.decrypt(pMarketingDigitalBond);
          let id = Number.parseInt(valMarketingDigitalBond);
          this.model.marketingDigitalBondId = id;

          let mdData = await this.marketingDigitalService.getBondRecord(id);

          if (mdData != null) {
            this.hasMarketingVinculo = true;
          }
        }

        if (params['consultor']) {
          let pAttendant = params['consultor'];
          let valAttendant = EncrypterService.decrypt(pAttendant);
          let id = Number.parseInt(valAttendant);
          this.model.attendantId = id;

          let mdData = await this.marketingDigitalService.getAttendantRecord(id);
          if (mdData.capa && mdData.capa.length > 0) {
            this.backgroundImg = `url(${mdData.capa[0].url})`;
          }
        }

        if (!this.backgroundImg) {
          this.backgroundImg = this.initialThemeService.backgroundImage;
        }

        if (params['indicadoPor']) {
          let pIndicadoPor = params['indicadoPor'];
          let valIndicadorPor = EncrypterService.decrypt(pIndicadoPor);
          let id = Number.parseInt(valIndicadorPor);
          if (params['tipo'] == 'lead') {
            this.model.indicatedByLead = id;
          }
          else if (params['tipo'] == 'client') {
            this.model.indicatedBy = id;
          }
        }

        this.model.UTM_Source = this.getParamValue(params['utm_source']);
        this.model.UTM_Medium = this.getParamValue(params['utm_medium']);
        this.model.UTM_Campaign = this.getParamValue(params['utm_campaign']);
        this.model.UTM_Content = this.getParamValue(params['utm_content']);
        this.model.UTM_Term = this.getParamValue(params['utm_term']);

        this.UTMList = this.inviteService.buildUTMList(params['utm_term'], params['utm_source'], params['utm_medium'], params['utm_campaign'], params['utm_content']);

        if (params['gclid']) {
          this.model.gclid = params['gclid'];
        }

        if (params['leadId']) {
          if (params['consultorAgendamento']) {
            let pSchedulingAttendant = params['consultorAgendamento'];
            let valSchedulingAttendant = EncrypterService.decrypt(pSchedulingAttendant);
            let id = Number.parseInt(valSchedulingAttendant);
            this.model.schedulingAttendantId = id;
          }

          let pLeadId = params['leadId'];
          let valLeadId = EncrypterService.decrypt(pLeadId);
          let id = Number.parseInt(valLeadId);
          this.model.id = id;
          try {

            this.leadState = await this.getLead();

            this.currentLeadEstablishment = this.model.organizationalStructure;

            switch (this.leadState) {
              case ELeadState.NewRegister:
                this.activeStep.next('InitialForm');
                break;
              case ELeadState.ConsentTerm:
                this.activeStep.next('ConsentTerm');
                break;
              case ELeadState.SelectEstablishment:
                this.activeStep.next('SelectEstablishment');
                break;
              case ELeadState.NewSchedule:
                this.model.consentTerm = true;
                this.ignoreSaving = true;
                this.activeStep.next('SelectScheduler');
                break;
              case ELeadState.EditSchedule:
                this.model.consentTerm = true;
                this.ignoreSaving = true;
                this.activeStep.next('ScheduleManagement');
                break;
            }
          }
          catch (e) {
            this.uiService.showError(e);
          }
        }
        else {
          this.activeStep.next('InitialForm');
        }

        if (params['compartilha']) {
          this.model.indicateFriend = params['compartilha'];
        }

        if (params['tel']) {
          this.model.phoneNumber = params['tel'];
        }

        if (params['media']) {
          this.model.media = params['media'];
        }

        if (params['invite']) {
          if (params['iframe']) {
            this.isFromApp = true;
            this.renderer.setStyle(this.document.documentElement, '--is-app', 'true');
          }
          else {
            this.renderer.setStyle(this.document.documentElement, '--is-app', 'false');
          }
          this.activeStep.next('InviteForm');
        }
        else {
          this.renderer.setStyle(this.document.documentElement, '--is-app', 'false');
        }

        if (firstAction)
        {
          firstAction = false;
          this.loadFooterScripts();
        }

      });
    });
  }


  async back($event) {
    this.onActivate();

    switch ($event) {
      case "SelectEstablishment":

        this.model.organizationalStructure = this.currentLeadEstablishment;

        if (this.leadState == ELeadState.EditSchedule)
          this.activeStep.next('ScheduleManagement');
        else
          this.activeStep.next('InitialForm');

        topFunction();
        break;
      case "ConsentTerm":

        if (this.isEstablishmentPreSelected) {
          this.activeStep.next('InitialForm');
        } else {
          this.activeStep.next("SelectEstablishment");
        }

        break;
      case "SelectScheduler":

        if (this.model.id != null && this.model.organizationalStructure == null) {
          this.model = await this.leadService.validate(this.model, await this.recaptchaV3Service.execute('backFromSelectScheduler').toPromise());
        }

        if (this.isEstablishmentPreSelected) {
          this.activeStep.next('InitialForm');
        }
        else {
          this.ignoreSaving = false;
          this.mainEvents.next('backFromSelectScheduler');
          this.activeStep.next('SelectEstablishment');
        }

        break;
      case "DoScheduler":
        this.activeStep.next('SelectScheduler');
        break;
      case "ReferFriends":
        this.activeStep.next('SchedulerDone');
        break;
    }
  }

  openPrivacyPolicyDialog() {
    this.dialog.open(DialogContentPrivacyPolicy, {
      data: {
        html: this.initialThemeService.html_politica_privacidade
      },
      height: "96vh",
      width: "95%",
      maxWidth: "95%",
      panelClass: "privacy-modal"
    });
  }

  openCookiePolicyDialog() {
    this.dialog.open(DialogContentCookiePolicy, {
      data: {
        html: this.initialThemeService.html_politica_cookie
      }
    });
  }

  async initialFormConfirmed($event) {

    this.onActivate();

    this.model = $event;

    //gerar link se possuir Id
    if (this.model.id) {
      this.inviteLink = this.inviteService.buildInviteLink(
        this.model.id,
        'lead',
        this.hasMarketingVinculo,
        this.themeService.hasVariant,
        false,
        this.UTMList,
        this.themeService.mktDigitalPath);
    }

    if (this.model.organizationalStructure != null) {
      await this.callSave();

      this.handleSelectSchedulerStep(true);
    }
    else {
      this.mainEvents.next('SelectEstablishment');
      this.activeStep.next('SelectEstablishment');
      topFunction();
    }
  }

  onInitialFormValidating() {
    this.mainEvents.next('leadValidating');
  }


  async selectEstablishmentConfirmed($event) {
    this.onActivate();
    this.model = $event;

    if (!this.model.consentTerm) {
      this.activeStep.next('ConsentTerm');
    } else {
      var saved = this.ignoreSaving == true ? true : await this.saveLead();

      if (saved) {
        this.handleSelectSchedulerStep();
      }
    }
    this.ignoreSaving = false;
  }

  async handleSelectSchedulerStep(showMessage: boolean = false) {
    try {
      if (this.initialThemeService.ignore_scheduler) {
        if (showMessage) {
          this.uiService.showMessage({
            title: await this.uiService.translate("pleaseWait"),
            text: this.initialThemeService.success_message.replace('[[unidade]]', this.model?.organizationalStructure?.Description)
          });
        }
        else {
          this.activeStep.next('SchedulerDone');
        }
      }
      else {
        this.activeStep.next('SelectScheduler');
      }
    } catch (e) {
      await this.exceptionHandlerService.handle(e);
    }
  }

  async acceptConsentTerm($event) {
    this.onActivate();
    this.model = $event;

    var saved = await this.saveLead();

    if (saved) {
      this.handleSelectSchedulerStep();
    }
  }

  async selectSchedulerConfirmed($event) {
    this.onActivate();
    this.timesAndSuggestions = $event;
    this.activeStep.next('DoScheduler');
  }

  async doSchedulerConfirmed($event) {
    this.onActivate();
    this.selectedTime = $event.selectedTime;
    this.model = $event.lead;

    this.activeStep.next('SchedulerDone');
  }

  schedulerDoneConfirmed($event) {
    this.onActivate();
    this.activeStep.next('ReferFriends');
  }

  referFriendsConfirmed($event) {
    this.onActivate();
    this.activeStep.next('FinalMessage');
  }

  scrollToTop() {
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;

      var vel = pos * 0.1;

      if (vel < 10) vel = 10;

      if (pos > 0) {
        window.scrollTo(0, pos - vel); // how far to scroll on each step
      } else {
        window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  async getLead(): Promise<ELeadState> {
    let m = await this.leadService.get(this.model);

    if (m) {
      this.model.email = m.email;
      this.model.phoneNumber = m.phoneNumber;
      this.model.status = m.status;
      this.model.attendantId = m.attendantId;
      this.model.organizationalStructure = m.organizationalStructure;
      this.model.schedulerSuggestionInterval = m.schedulerSuggestionInterval;
      this.model.Schedule = m.Schedule;
      this.model.IsTermPending = m.IsTermPending;
      this.model.LeadMembershipType = m.LeadMembershipType;

      if (this.model.schedulingAttendantId == null && m.schedulingAttendantId != null) {
        this.model.schedulingAttendantId = m.schedulingAttendantId;
      }

      if (this.model.email > '' || this.model.Schedule) {
        this.model.name = m.name;
        this.model.doc = m.doc;
      }
      else {
        this.model.name = null;
        this.model.doc = null;
      }

      if (this.model.IsTermPending) {
        return ELeadState.ConsentTerm;
      }

      if (this.model.organizationalStructure == null) {
        return ELeadState.SelectEstablishment;
      }

      if (this.model.Schedule) {
        return ELeadState.EditSchedule;
      } else {
        return ELeadState.NewSchedule;
      }
    }
    else {
      this.model.id = 0;
      this.model.schedulerSuggestionInterval = 90;
    }

    return ELeadState.NewRegister;
  }

  async saveLead(): Promise<boolean> {

    try {

      await this.callSave();

      return true;

    } catch (e) {
      await this.exceptionHandlerService.handle(e);
    }

    return false;
  }

  async callSave() {
    if (this.model.id == null) {
      this.model.segmentation = this.uiService.getActiveSegmentation();
      if (!this.model.fingerPrint) {
        this.model.fingerPrint = await this.fingerPrintService.get();
      }
    }
    var segmentacao = this.uiService.getActiveSegmentation();
    if (segmentacao) {
      this.model.segmentation = segmentacao;
    }
    await this.leadService.save(this.model);

    // Após gravação do Lead, criar o link
    this.inviteLink = this.inviteService.buildInviteLink(
      this.model.id,
      'lead',
      this.hasMarketingVinculo,
      this.themeService.hasVariant,
      false,
      this.UTMList,
      this.themeService.mktDigitalPath);
  }

  onActivate() {
    window.scroll(0, 0);
  }

  applyBanner(banner: any) {

    if (banner.image)
      this.backgroundImg = `url(${banner.image})`;
    else
      this.backgroundImg = this.initialThemeService.backgroundImage;

    if (banner.backgroundColor && this.uiService.isBrowser) {
      (document.querySelector('.sec-ch-banner') as HTMLElement).style.backgroundColor = banner.backgroundColor;
    } else {
      if (this.uiService.isBrowser)
        (document.querySelector('.sec-ch-banner') as HTMLElement).style.backgroundColor = this.initialThemeService.corDeFundo;
    }
  }

  applyShowHtmlTopo(activeStep: any) {

    switch (activeStep) {
      case 'InitialForm':
        this.showHtmlTopo = !(this.initialThemeService.hide_menu_registration_stage ? this.initialThemeService.hide_menu_registration_stage : false);
        break;
      case 'SelectEstablishment':
        this.showHtmlTopo = !(this.initialThemeService.hide_menu_unit_selection ? this.initialThemeService.hide_menu_unit_selection : false);
        break;
      case 'SelectScheduler':
        this.showHtmlTopo = !(this.initialThemeService.hide_menu_time_selection ? this.initialThemeService.hide_menu_time_selection : false);
        break;
      case 'DoScheduler':
        this.showHtmlTopo = !(this.initialThemeService.hide_menu_time_selection ? this.initialThemeService.hide_menu_time_selection : false);
        break;
      case 'SchedulerDone':
        this.showHtmlTopo = !(this.initialThemeService.hide_menu_registration_confirmation ? this.initialThemeService.hide_menu_registration_confirmation : false);
        break;
      case 'InviteForm':
        this.showHtmlTopo = !(this.initialThemeService.hide_menu_indicate ? this.initialThemeService.hide_menu_indicate : false);
        break;
    }
  }

  getParamValue(value: any): string {
    if (value && value.length > 0) {
      if (Array.isArray(value)) {
        return value[0];
      } else {
        return value;
      }
    }
    return null;
  }

  loadFooterScripts() {
    if (this.initialThemeService.scripts_footer) {
      setTimeout(() => {
        const fileref = this.renderer.createElement('script');
        fileref.type = 'text/javascript';
        fileref.innerHTML = this.initialThemeService.scripts_footer;
        this.renderer.appendChild(this.document.body, fileref);
      }, 50);

    }
  }

  setInviteLink(e) {
    this.refferShareLink = e;
  }

}
