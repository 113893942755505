<div>
    <!-- <h2 class="fs-title">
        {{ this.themeService.rotulo_sel_data }}<br>
        {{ this.organizationalStructureDescription }}
    </h2> -->

    <!-- <app-select-scheduler-top-content [activeStep]="activeStep"></app-select-scheduler-top-content> -->

    <div *ngIf="!useNewScheduler">
        <input matInput [matDatepicker]="picker" style="width: 150px;" name="date" [(ngModel)]="schedulerSearch.date"
            [min]="minDate" [max]="maxDate">
        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
        <mat-datepicker #picker></mat-datepicker>

        <div style="width: 100%;">
            <div style="width: 33%;display: inline-block;">
                <mat-checkbox name="morning" [(ngModel)]="schedulerSearch.morning" class="fs-info">{{ 'morning' |
                    translate }}</mat-checkbox>
            </div>
            <div style="width: 33%;display: inline-block;">
                <mat-checkbox name="afternoon" [(ngModel)]="schedulerSearch.afternoon" class="fs-info">{{ 'afternoon' |
                    translate }}</mat-checkbox>
            </div>
            <div style="width: 33%;display: inline-block;">
                <mat-checkbox name="night" [(ngModel)]="schedulerSearch.night" class="fs-info">{{ 'night' | translate }}
                </mat-checkbox>
            </div>
        </div>
    </div>


    <app-select-scheduler-select-day *ngIf="useNewScheduler" (selectDate)="selectDate($event)" [model]="model"
        [activeStep]="activeStep"></app-select-scheduler-select-day>
    <div class="select-scheduler-btn-wrapper">
        <button mat-button name="previous" class="previous action-button" (click)="back()">{{ 'back' | translate}}</button>
        <button 
            [disabled]="!selectedTime && useNewScheduler" 
            mat-button id="btnNextSelectScheduler"
            name="next"
            class="next action-button"
            (click)="confirm()">
            {{ nextBtnText }}
        </button>
    </div>

</div>