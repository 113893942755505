
import { TranslateLoader } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { ptBR } from '../../assets/i18n/ptBR'
import { enUS } from '../../assets/i18n/enUS'
import { esCO } from '../../assets/i18n/esCO'

export class TranslateUniversalLoader implements TranslateLoader {
    constructor() { }

    public getTranslation(lang: string): Observable<any> {
        return Observable.create(observer => {
            if (lang === 'pt-BR') {
                observer.next(ptBR);
            }
            else if (lang === 'en-US') {
                observer.next(enUS);
            }
            else if (lang === 'es-CO') {
                observer.next(esCO);
            }

            observer.complete();
        });
    }
}