export class DDDBrasil {
    static newDDDList() {
      let DDDBrArray = [11,12,13,14,15,16,17,18,19,21,22,24,27,28,31,32,33,34,35,37,38,41,42,43,44,45,46,47,48,49,51,53,54,55,61,62,63,64,65,66,67,68,69,71,73,74,75,77,79,81,82,83,84,85,86,87,88,89,91,92,93,94,95,96,97,98,99];
        return DDDBrArray;
    }
  }

/*
11;São Paulo;Região Metropolitana de São Paulo/Região Metropolitana de Jundiaí/Região Geográfica Imediata de Bragança Paulista
12;São Paulo;Região Metropolitana do Vale do Paraíba e Litoral Norte
13;São Paulo;Região Metropolitana da Baixada Santista/Vale do Ribeira
14;São Paulo;Avaré/Bauru/Botucatu/Jaú/Lins/Marília/Ourinhos
15;São Paulo;Itapetininga/Itapeva/Sorocaba/Tatuí
16;São Paulo;Araraquara/Franca/Jaboticabal/Ribeirão Preto/São Carlos/Sertãozinho
17;São Paulo;Barretos/Catanduva/Fernandópolis/Jales/São José do Rio Preto/Votuporanga
18;São Paulo;Andradina/Araçatuba/Assis/Birigui/Dracena/Presidente Prudente
19;São Paulo;Americana/Campinas/Limeira/Piracicaba/Rio Claro/São João da Boa Vista
21;Rio de Janeiro;Rio de Janeiro e Região Metropolitana/Teresópolis
22;Rio de Janeiro;Cabo Frio/Campos dos Goytacazes/Macaé/Nova Friburgo
24;Rio de Janeiro;Angra dos Reis/Petrópolis/Volta Redonda
27;Espírito Santo;Vitória e Região Metropolitana/Colatina/Linhares/Santa Maria de Jetibá
28;Espírito Santo;Cachoeiro de Itapemirim/Castelo/Itapemirim/Marataízes
31;Minas Gerais;Belo Horizonte e Região Metropolitana/Conselheiro Lafaiete/Ipatinga
32;Minas Gerais;Barbacena/Juiz de Fora/Muriaé/São João del-Rei/Ubá
33;Minas Gerais;Almenara/Caratinga/Governador Valadares/Manhuaçu/Teófilo Otoni
34;Minas Gerais;Araguari/Araxá/Patos de Minas/Uberlândia/Uberaba
35;Minas Gerais;Alfenas/Guaxupé/Lavras/Poços de Caldas/Pouso Alegre/Varginha
37;Minas Gerais;Bom Despacho/Divinópolis/Formiga/Itaúna/Pará de Minas
38;Minas Gerais;Curvelo/Diamantina/Montes Claros/Pirapora/Unaí
41;Paraná;Curitiba e Região Metropolitana
42;Paraná;Ponta Grossa/Guarapuava
43;Paraná;Apucarana/Londrina
44;Paraná;Maringá/Campo Mourão/Umuarama
45;Paraná;Cascavel/Foz do Iguaçu
46;Paraná;Francisco Beltrão/Pato Branco
47;Santa Catarina;Balneário Camboriú/Blumenau/Itajaí/Joinville
48;Santa Catarina;Florianópolis e Região Metropolitana/Criciúma
49;Santa Catarina;Caçador/Chapecó/Lages
51;Rio Grande do Sul;Porto Alegre e Região Metropolitana/Santa Cruz do Sul/Litoral Norte
53;Rio Grande do Sul;Pelotas/Rio Grande
54;Rio Grande do Sul;Caxias do Sul/Passo Fundo
55;Rio Grande do Sul;Santa Maria/Santana do Livramento/Santo Ângelo/Uruguaiana
61;Distrito Federal/Goiás;Abrangência em todo o Distrito Federal e alguns municípios da Região Integrada de Desenvolvimento do Distrito Federal e Entorno
62;Goiás;Goiânia e Região Metropolitana/Anápolis/Niquelândia/Porangatu
63;Tocantins;Abrangência em todo o estado
64;Goiás;Caldas Novas/Catalão/Itumbiara/Rio Verde
65;Mato Grosso;Cuiabá e Região Metropolitana
66;Mato Grosso;Rondonópolis/Sinop
67;Mato Grosso do Sul;Abrangência em todo o estado
68;Acre;Abrangência em todo o estado
69;Rondônia;Abrangência em todo o estado
71;Bahia;Salvador e Região Metropolitana
73;Bahia;Eunápolis/Ilhéus/Porto Seguro/Teixeira de Freitas
74;Bahia;Irecê/Jacobina/Juazeiro/Xique-Xique
75;Bahia;Alagoinhas/Feira de Santana/Paulo Afonso/Valença
77;Bahia;Barreiras/Bom Jesus da Lapa/Guanambi/Vitória da Conquista
79;Sergipe;Abrangência em todo o estado
81;Pernambuco;Recife e Região Metropolitana/Caruaru
82;Alagoas;Abrangência em todo o estado
83;Paraíba;Abrangência em todo o estado
84;Rio Grande do Norte;Abrangência em todo o estado
85;Ceará;Fortaleza e Região Metropolitana
86;Piauí;Teresina e alguns municípios da Região Integrada de Desenvolvimento da Grande Teresina/Parnaíba
87;Pernambuco;Garanhuns/Petrolina/Salgueiro/Serra Talhada
88;Ceará;Juazeiro do Norte/Sobral
89;Piauí;Picos/Floriano
91;Pará;Belém e Região Metropolitana
92;Amazonas;Manaus e Região Metropolitana/Parintins
93;Pará;Santarém/Altamira
94;Pará;Marabá
95;Roraima;Abrangência em todo o estado
96;Amapá;Abrangência em todo o estado
97;Amazonas;Abrangência no interior do estado
98;Maranhão;São Luís e Região Metropolitana
99;Maranhão;Caxias/Codó/Imperatriz
*/