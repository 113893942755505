<div class="selected-unit-wrapper">
    <h2 class="selected-unit-title">{{ 'selectedEstablishment' | translate}}</h2>
    <div class="selected-unit">
        <span *ngIf="userPosition" class="selected-unit-distance">{{selectedUnit.Distance?.toLocaleString(undefined, { maximumFractionDigits: 1, minimumFractionDigits: 1 })}} km</span>
        <p class="selected-unit-name"><b>{{getEstablishmentDescription(selectedUnit.Description)}}</b></p>
        <p class="selected-unit-uf-city"><b>{{selectedUnit.Address.StateAbrev + ' - ' + selectedUnit.Address.City}}</b></p>
        <p class="selected-unit-address">{{selectedUnit.Address.Type | translate}} {{selectedUnit.Address.Description + ', ' + selectedUnit.Address.Number +
            ' - ' + selectedUnit.Address.Neighborhood | uppercase }}</p>
        <p class="unit-scheduler" *ngFor="let calendar of selectedUnit.SchedulerCalendarTime">{{ getWeekDay(calendar.DaysOfWeek) }} das {{calendar.StartTime}} às {{calendar.EndTime}}
            <br>
        </p>
    </div>
</div>
