import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HubComponent } from './components/hub/hub.component';
import { staticRoutes } from './services/redirect.service';

let routes: Routes = Object.assign([], staticRoutes);

routes.push({ path: '**', component: HubComponent });

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    //initialNavigation: 'enabled',
    anchorScrolling: 'enabled',
    relativeLinkResolution: 'legacy'
})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
