import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UiService } from './ui.service';
import { BusinessError } from '../helper/BusinessError';
import { OrganizationalStructure } from '../model/organizationalStructure';
import { Lead } from '../model/lead';
import { ClientAlreadyExistsError } from '../helper/ClientAlreadyExistsError';
import { EncrypterService } from './encrypter.service';
import { EmailAlreadyExistsError } from '../helper/EmailAlreadyExistsError';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { ELeadMembershipType } from '../model/enum.leadMembershipType';
import { EstablishmentService } from './establishment.service';
import { organizationalStructureConfigRoot } from '../model/organizationalStructureConfig';

@Injectable({
  providedIn: 'root'
})
export class LeadService {

  constructor(private http: HttpClient, private uiService: UiService, private establishmentService: EstablishmentService) {

  }

  async getEstablishment(id: number): Promise<OrganizationalStructure> {

    var data = <any>await this.http.get(environment.serviceUrl + `/${this.uiService.getDefaultLang()}/GetEstablishment/${id}`).toPromise();

    if (data.error) {
      throw new BusinessError(data.message);
    }

    return data.model;
  }

  async getEstablishments(lat: number, lng: number, attendant: number, marketingDigitalBond: number, skip: number = 0): Promise<OrganizationalStructure[]> {
    let pAttendant: string = '';
    if (attendant) {
      pAttendant = EncrypterService.encrypt(attendant.toString());
      pAttendant = encodeURIComponent(pAttendant);
    }
    let pMarketingDigitalBond: string = '';
    if (marketingDigitalBond) {
      pMarketingDigitalBond = EncrypterService.encrypt(marketingDigitalBond.toString());
      pMarketingDigitalBond = encodeURIComponent(pMarketingDigitalBond);
    }

    var url = environment.serviceUrl + `/${this.uiService.getDefaultLang()}/GetEstablishments?attendant=${pAttendant}&marketingDigitalBond=${pMarketingDigitalBond}&skip=${skip}`;

    if (lat && lng) {
      url = url + `&lat=${lat}&lng=${lng}`;
    }

    var data = <any>await this.http.get(url).toPromise();

    if (data.error) {
      throw new BusinessError(data.message);
    }

    return data.list;
  }

  async getEstablishmentsByTerms(filterText: string, attendant: number, marketingDigitalBond: number): Promise<OrganizationalStructure[]> {
    let pAttendant: string = '';
    if (attendant) {
      pAttendant = EncrypterService.encrypt(attendant.toString());
      pAttendant = encodeURIComponent(pAttendant);
    }
    let pMarketingDigitalBond: string = '';
    if (marketingDigitalBond) {
      pMarketingDigitalBond = EncrypterService.encrypt(marketingDigitalBond.toString());
      pMarketingDigitalBond = encodeURIComponent(pMarketingDigitalBond);
    }
    var data = <any>await this.http.get(environment.serviceUrl + `/${this.uiService.getDefaultLang()}/GetEstablishments?filterText=${filterText}&attendant=${pAttendant}&marketingDigitalBond=${pMarketingDigitalBond}`).toPromise();

    if (data.error) {
      throw new BusinessError(data.message);
    }

    return data.list;
  }

  async get(lead: Lead): Promise<Lead> {
    var data = <any>await this.http.post(environment.serviceUrl + `/${this.uiService.getDefaultLang()}/GetLead`, lead).toPromise();

    if (data.error) {
      throw new BusinessError(data.message);
    }

    return data.lead;
  }

  async validate(lead: Lead, recaptchaToken: string): Promise<Lead> {
    var data = <any>await this.http.post(environment.serviceUrl + `/${this.uiService.getDefaultLang()}/ValidateLead`, { lead, recaptchaToken }).toPromise();

    if (data.error) {
      this.handleErrors(data);
    }

    return data.lead;
  }

  private handleErrors(data: any) {
    if (data.clientAlreadyExists == true) {
      throw new ClientAlreadyExistsError(data.person, data.message);
    } else if (data.emailAlreadyExists == true) {
      throw new EmailAlreadyExistsError(data.person, data.message);
    }
    else {
      throw new BusinessError(data.message);
    }
  }

  async save(lead: Lead) {
    var restoreAllData = lead.id > 0;
    var url = environment.serviceUrl + `/${this.uiService.getDefaultLang()}/SaveLead`;
    var data = <any>await this.http.post(url, lead).toPromise();

    if (data.error) {
      if (!restoreAllData) {
        lead.id = null;
      }

      this.handleErrors(data);
    }
    else if (restoreAllData) {
      lead.name = data.lead.Name;
      lead.email = data.lead.Email;
      lead.doc = data.lead.Doc;
      lead.phoneNumber = data.lead.PhoneNumber;
      lead.status = data.lead.Status;
      lead.attendantId = data.lead.AttendantId;
      lead.organizationalStructure = data.lead.OrganizationalStructure;
      lead.marketingDigitalBondId = data.lead.MarketingDigitalBondId;
      lead.segmentation = data.lead.Segmentation;
      lead.Schedule = data.lead.Schedule;
      lead.IsTermPending = data.lead.IsTermPending;
    }
    else {
      lead.id = data.lead.Id | data.lead.id;
    }

    lead.schedulerSuggestionInterval = data.schedulerSuggestionInterval;

    if (data.times && data.times.length > 0) {
      var selectedDate = new Date(data.times[0]);
      selectedDate.setHours(0, 0, 0, 0);
      lead.nextAvaliableDate = selectedDate;
    } else {
      var today = new Date();
      var nextWeek = new Date(today);
      nextWeek.setHours(0, 0, 0, 0);
      nextWeek.setDate(nextWeek.getDate() + 8);

      lead.nextAvaliableDate = nextWeek;
    }
  }

  async referFriends(lead: Lead, referList) {

    var data = <any>await this.http.post(environment.serviceUrl + `/${this.uiService.getDefaultLang()}/ReferFriends`, { lead, referList }).toPromise();

    if (data.error) {
      throw new BusinessError(data.message);
    }

  }

  async inviteLead(knownPersonId: any, type: string, consultor: any, emailTo: string, link: string, segmentacao: string) {
    var model = {
      KnownPersonId: knownPersonId,
      Type: type,
      Consultor: consultor,
      EmailTo: emailTo,
      Link: link,
      Segmentation: segmentacao
    }
    var data = <any>await this.http.post(environment.serviceUrl + `/${this.uiService.getDefaultLang()}/InviteLead`, model).toPromise();

    if (data.error) {
      throw new BusinessError(data.message);
    }

  }

  async hasPendantTerm(lead: Lead): Promise<boolean> {
    var data = <any>await this.http.post(environment.serviceUrl + `/${this.uiService.getDefaultLang()}/HasPendantTermLead`, lead).toPromise();

    if (data.error) {
      throw new BusinessError(data.message);
    }

    return data.hasPendantTerm;
  }

  async authenticateEmailLead(validationToken: string) {
    var data = <any>await this.http.post(environment.serviceUrl + `/${this.uiService.getDefaultLang()}/AuthenticateEmailLead`, { validationToken: validationToken }).toPromise();
    if (data.error) {
      throw new BusinessError(data.message);
    }

    return data.authentication
  }

  async getLeadFidelityMembershipType(): Promise<ELeadMembershipType> {
    var data = await this.establishmentService.getEstablishmentConfigRoot(new organizationalStructureConfigRoot("LeadFidelityProgramMembershipType"))

    if (data.error)
      this.handleErrors(data);

    return <ELeadMembershipType>data.model.value;
  }

  async getLeadFidelityProgramIsActive(): Promise<boolean> {
    var data = await this.establishmentService.getEstablishmentConfigRoot(new organizationalStructureConfigRoot("EnableLeadFidelity"))

    if (data.error)
      this.handleErrors(data);

    return data.model.value === "False" ? false : true;
  }

  async getFidelityProgramIsActive(): Promise<boolean> {
    var data = await this.establishmentService.getEstablishmentConfigRoot(new organizationalStructureConfigRoot("UsesFidelityProgram"))

    if (data.error)
      this.handleErrors(data);

    return data.model.value === "False" ? false : true;
  }

}