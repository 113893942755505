import { Injectable } from "@angular/core";

export type TCalendarEvent = {
    name: string;
    details: string | null;
    location: string | null;
    startsAt: string;
    endsAt: string;
}

type Query = { [key: string]: null | boolean | number | string };

const makeUrl = (base: string, query: Query) => Object.keys(query).reduce(
    (accum, key, index) => {
        const value = query[key];

        if (value !== null) {            
            return `${accum}${index === 0 ? '?' : '&'}${key}=${encodeURIComponent(value)}`;
        }
        return accum;
    },
    base
);

@Injectable({
    providedIn: 'root'
})

export class calendarEventsService {

    constructor() { }

    makeGoogleCalendarUrl(event: TCalendarEvent) {
        return makeUrl('https://calendar.google.com/calendar/render', {
            action: 'TEMPLATE',
            dates: `${event.startsAt}/${event.endsAt}`,
            location: event.location,
            text: event.name,
            details: event.details,
        });
    }

    // makeOutlookCalendarUrl(event: TCalendarEvent) {
    //     return makeUrl('https://outlook.live.com/owa', {
    //         rru: 'addevent',
    //         startdt: event.startsAt,
    //         enddt: event.endsAt,
    //         subject: event.name,
    //         location: event.location,
    //         body: event.details,
    //         allday: false,
    //         uid: new Date().getTime().toString(),
    //         path: '/calendar/view/Month',
    //     });
    // }

    makeICSCalendarUrl(event: TCalendarEvent) {

        const components = [
            'BEGIN:VCALENDAR',
            'VERSION:2.0',
            `PRODID:Calendar:${event.name}`,
            'BEGIN:VEVENT',
            `SUMMARY:${event.name}`,
            `UID:${event.startsAt}${event.name}`,
            `DTSTAMP;VALUE=DATE-TIME:${event.startsAt}`,
            `DTSTART;VALUE=DATE-TIME:${event.startsAt}`,
            `DTEND;VALUE=DATE-TIME:${event.endsAt}`,
            `LOCATION:${event.location}`,
            `DESCRIPTION:${event.details}`,
            'TRANSP:TRANSPARENT',
            'END:VEVENT',
            'END:VCALENDAR',
        ];

        var SEPARATOR = (navigator.appVersion.indexOf('Win') !== -1) ? '\r\n' : '\n';

        return encodeURI(`data:text/calendar;charset=utf8,${components.join(SEPARATOR)}`);
    };
}