export const esCO = {
    back: "Volver",
    next: "Avanzar",
    name: "Nombre y apellido",
    email: "Correo electrónico",
    phone: "Teléfono celular",
    doc: "CPF",
    isBlockToCall: "Bloquear conexiones",
    nameIsRequired: "Por favor, escriba su nombre.",
    nameIsInvalid: "Por favor, introduzca su nombre y apellido.",
    invalidEmail: "Por favor, introduce una dirección de correo electrónico válida.",
    phoneIsRequired: "Por favor ingrese un número de teléfono válido.",
    docIsRequired: "Por favor, ingrese su CPF.",
    docIsInvalid: "El CPF ingresado no es válido.",
    pleaseSelectEstablishment: "Seleccione una unidad para continuar.",
    selectEstablishment: "Seleccione una unidad",
    anErrorWasOcurred: "Hubo un error al procesar su solicitud.",
    selectPreferencEstablishment: "Seleccione la unidad de su elección",
    waitMapPermission: "Esperando permiso para acceder a su ubicación y así mostrar las unidades más cercanas a usted.",
    inputCityForMoreLocationResults: "* Puede ingresar la ciudad, el vecindario, el nombre de la unidad o el código postal para refinar su búsqueda.",
    pleaseSelectDate: "Por favor introduzca una fecha valida.",
    pleaseSelectATurn: "Seleccione al menos un período del día.",
    invalidReCaptcha: "Por favor, valide o ReCaptcha.",
    schedule: "Agendar",
    selectTime: "Seleccionar hora",
    timeIsRequired: "Seleccione una hora para continuar.",
    schedulerDone: "Todo bien aqui!",
    schedulerDetail: "¡Su cita está confirmada en {{unidade}}, para {{data}}.",
    attention: "Atención!",
    alreadyRegisteredMessage: "No es posible continuar ya que hemos localizado un registro activo informado en la unidad {{unidade}}. Póngase en contacto con la unidad para obtener más información.",
    openWhatsapp: "Abrir WhatsApp",
    fromWhatsapp: "Pelo WhatsApp",
    referFriend: "Por Aquí",
    shareToWin: "Invita a tus amigos a recibir el cupón por correo electrónico.",
    shareToWinObs: "(Si no lo recibe en su bandeja de entrada, marque su casilla de Spam)",
    finalize: "Concluir",
    addMore: "Añadir más",
    noTimesForThisDay: "No hay horario disponible para este día. 😥",
    referFriends: "Recomendar amigos",
    referFriendsRequired: "Complete todos los campos de la lista de nominados.",
    thanks:"Muchas gracias!",
    finalMessage: "¡Esperamos darle la bienvenida a nuestra unidad! 😉",
    referFriendsDuplicated: "Identificamos números de teléfono repetidos en la lista.",
    PrivacyPolicy: "Política de privacidad",
    CookiePolicy: "Política de cookie",
    knowTheApp: "Conozca la APP",
    notNow: "Ahora no",
    weFindSomeSuggests: "Sin embargo, hemos seleccionado algunas opciones de tiempo para usted.",
    consentTerm: "Formulario de consentimiento",
    consentTermContent: "He leído y acepto el",
    pleaseYouHaveToAcceptTheTerm: "Se debe aceptar el formulario de consentimiento.",
    acceptConsentTerm: "Aceito",
    participation: "Como participar",
    regulations: "Regulaciones",
    monday: "Lunes",
    tuesday: "Martes",
    wednesday: "Miércoles",
    thursday: "Jueves",
    friday: "Viernes",
    saturday: "Sábado",
    sunday: "Domingo",
    morning: "Mañana",
    afternoon: "Tarde", 
    night: "Noche",
    openingHours: "HORARIO DE APERTURA",
    noResultsFound: "Ningún resultado encontrado",
    enterAddress: "Ingrese la dirección deseada para buscar",
    close: "Cerrar",
    sendInviteEmail: "Enviar invitacion",
    copy: "Copy",
    shareInvite: "Comparte tu enlace de invitación",
    copiedLink: "Enlace copiado",
    emailSent: "Email enviado",
    emailFailure: "No pudimos enviar su invitación. Utilice una de las otras opciones disponibles.",
    inviteWhatsApp: "Indicar por WhatsApp",
    inviteFacebook: "Compartir en Facebook",
    inviteTwitter: "Compartir en Twitter",
    inviteTelegram: "Indicar por Telegram",
    inviteEmail: "Indicar por correo electrónico",
    inviteCopy: "Copiar link",
    backReschedulingMode: "Cambiar unidad",
    nextReschedulingMode: "Buscar horarios",
    invalidSegmentation: "Segmentación inválida",
    pleaseWait: "Espere por favor...",
    seeMoreEstablishments: "ver más unidades",
    selectedEstablishment: "Unidad seleccionada",
    fillFilter: "Ingrese un código postal, dirección, ciudad, vecindario o nombre de la unidad",
    establishmentNotFound: "No hay unidades para mostrar.",
    establishments: "Unidades",
    cities: "Ciudades",
    neighborhoods: "Barrios",
    addresses: "Direcciones",
    establishmentFilterPlaceholder: "Escribe para buscar",
    pleaseSelectAllowedDate: "Seleccione un período del día en el interior.",
    scheduleManagementTitle: "Su agenda",
    scheduleManagementLegend: "Por favor, confirme su asistencia haciendo clic en el botón de abajo. O si lo prefieres usa las otras opciones para reprogramar.",
    schedulerDetailEstablishment: "¡Su cita está confirmada en <b>{{unidade}}</b>",
    schedulerDetailDate: "Para <b>{{data}}</b>",
    confirmSchedule: "Confirmar Asistencia",
    reschedule: "Reprogramar",
    dontGo: "No Iré",
    hi: "Hola {{name}}",
    cancelScheduleQuestion: "¿Estás seguro de que quieres cancelar tu cita con nosotros? No te preocupes, en el futuro puedes volver aquí y reprogramar cuando quieras.",
    yes:  "Si",
    no: "No",
    cancelScheduleSucess: "La cancelación fue exitosa.",
    confirmScheduleSucess: "Cita confirmada con éxito.",
    loadMoreDays: 'Carga más',
    filterByPeriod: 'Filtrar por periodo'
}
