import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import Enumerable from 'linq';
import { environment } from 'src/environments/environment';

interface searchAddressVM {
	type: string,
	text: string,
	Document: any;
};

@Injectable({
	providedIn: 'root'
})
export class SearchAddressService {

	constructor(
		public http: HttpClient) { }

	/**
	 * Busca no azure coginitive search por endereços e unidades
	 * @param term 
	 * @returns 
	 */
	async search(term: string): Promise<searchAddressVM[]> {

		term = term.trim().toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");

		var results = [];

		var top = 20;

		results = results.concat(await this.searchEstablishments(term));

		top = top - results.length;

		results = results.concat(await this.searchAddress(term, top, true, "city", "City", "City, AddressLat, AddressLng"));

		top = top - results.length;

		results = results.concat(await this.searchAddress(term, top, true, "neighborhood", "Neighborhood", "Neighborhood, AddressLat, AddressLng"));

		top = top - results.length;

		var addresses = await this.searchAddress(term, top, false, "address", "PostalCode, Address", "Address, AddressLat, AddressLng");

		/**
		 * Paliativo para não exibir endereços duplicados no retorno da busca cognitiva.
		 * REMOVER APÓS CORRIGIDO
		 */
		addresses.forEach(item =>{
			var exists = results.filter(w => w.text == item.text && w.type == 'address')[0];
			if(!exists){
				results.push(item);
			}
		});

		return results;
	}


	async searchEstablishments(term: string): Promise<searchAddressVM[]> {

		var payload = {
			search: term,
			suggesterName: "sg",
			highlightPreTag: "<b>",
			highlightPostTag: "</b>",
			"filter": `SchemaId eq '${environment.schemaId}' and AppChannels/any(l: l/Channel eq '${environment.appChannel}')`,
			select: "Id, Address, AddressLat, AddressLng",
			fuzzy: true,
			top: 10
		};

		var headers = new HttpHeaders({ 'api-key': environment.searchApiKey });

		var result = <any>await this.http.post(`${environment.searchEndpoint}indexes/establishment/docs/suggest?api-version=2020-06-30`, payload, { headers }).toPromise();

		return Enumerable
			.from(result.value)
			.select(r => {
				return {
					type: "establishment",
					text: r["@search.text"],
					Document: r
				};
			}).toArray();

	}

	async searchAddress(term: string, top: number, distinct: boolean, type: string, part: string, select: string): Promise<searchAddressVM[]> {

		var results = [];

		if (top <= 0) return results;

		var needSearch = true;

		var i = 1;

		var filter = '';

		var max = top > 5 ? 5 : top;

		while (needSearch) {
			var payload = {
				search: term,
				suggesterName: "sg",
				highlightPreTag: "<b>",
				highlightPostTag: "</b>",
				searchFields: part,
				select: select,
				filter: filter,
				fuzzy: true,
				top: distinct ? 1 : max
			};

			if (!distinct) {
				delete payload.filter;
			}

			var headers = new HttpHeaders({ 'api-key': environment.searchApiKey });

			var result = <any>await this.http.post(`${environment.searchEndpoint}indexes/postalcode/docs/suggest?api-version=2020-06-30`, payload, { headers }).toPromise();

			if (result.value.length > 0) {
				i++;

				var filteredResults = Enumerable.from(result.value).where(r => r["AddressLat"] != null && r["AddressLng"] != null).toArray();

				if (filteredResults.length > 0) {
					
					var current = filteredResults[0][part];

					if (current) {
						current = current.replace(/'/g, "%27");
					}

					if (filter.length > 0) {
						filter += `and ${part} ne '${current}'`
					}
					else {
						filter += `${part} ne '${current}'`
					}

					results = results.concat(filteredResults);
				}
				
			} else {
				break;
			}

			if (i == max || !distinct) {
				break;
			}
		}

		return Enumerable
			.from(results)
			.select(r => {
				return {
					type: type,
					text: r["@search.text"],
					Document: r
				};
			}).toArray();
	}

	format(input: searchAddressVM[]) {
		return Enumerable
				.from(input)
				.groupBy(r => r.type)
				.select(g => ({
					type: g.key(),
					results: Enumerable.from(g.getSource())
						.select(t => ({
							text: t.text,
							Document: t.Document
						})).toArray()
				})).toArray();
	}
}
