import { Component, OnInit, Input } from '@angular/core';
import { EstablishmentService } from 'src/app/services/establishment.service';

@Component({
  selector: 'app-unit-selected',
  templateUrl: './unit-selected.component.html',
  styleUrls: ['./unit-selected.component.scss']
})
export class UnitSelectedComponent implements OnInit {

  weekdays: string[];

  @Input() selectedUnit: any;

  @Input() userPosition: boolean;

  constructor(
    private establishmentService: EstablishmentService) { }

  async ngOnInit() {    
    this.weekdays = await this.establishmentService.translateWeekDays();
  }

  getWeekDay(daysOfWeek: number[],) {    
    if (!daysOfWeek || !daysOfWeek.length || daysOfWeek.length <= 0) return '';
    if (!this.weekdays) return '';
    
    let fDay = this.weekdays[daysOfWeek[0]];
    let lDay = daysOfWeek.length > 1 ? ' à ' + this.weekdays[daysOfWeek[daysOfWeek.length - 1]] : '';
    return `${fDay}${lDay}`;
  }

  getEstablishmentDescription(description: string) {
    return this.establishmentService.getEstablishmentDescription(description);
  }

}
