<section [innerHTML]="this.themeService.html_higher_indicates" style="background-color: white; border-top-left-radius: 8px; border-top-right-radius: 8px;"></section>
<div>
    <div class="whatsapp-contact">
        <button type="button" class="btn-whatsapp" (click)="openWhatsappContact()">
          <img src="../../../assets/imagens/whatsapp-icon-white.png"/>
          {{'whatsapp' | translate}}
        </button>
    </div>
</div>
<div>
    <div class="share-link-indicates">
        <button type="button" class="btn-sharelink" (click)="share()">
            <img width="16px" height="17px" src="../../../assets/imagens/share-link-img.png"/>
            {{'share' | translate}}
        </button>
    </div>
</div>

<div *ngIf="this.themeService.html_bottom_indicates != 'null'" [innerHTML]="this.themeService.html_bottom_indicates"></div>

<!-- MODELO ANTIGO - MANTIDO ATÉ ANÁLISE ANTES DO DESENVOLVIMENTO FINAL -->
<!-- 
<div *ngIf="this.themeService.fidelity_dashboard_on_top === true && this.type">
    <app-fidelity-dashboard [clientId]="this.knownPersonId" [type]="this.type"></app-fidelity-dashboard>
</div>
<div *ngIf="!isFromFidelity" [innerHTML]="this.themeService.html_invitation"></div>
<div *ngIf="isFromFidelity" [innerHTML]="this.themeService.html_fidelity_presentation"></div>
<div class="invitation-links">
    <h2>{{'inviteThrough' | translate}}</h2>

    <div class="btn-row">
        <div class="btn-share share-whatsapp" (click)="sendToWhatsapp()">
            <div>
            <img src="../../../assets/imagens/icon-whatsapp.png"/>
            <span>{{'whatsapp' | translate}}</span>
            </div>
        </div> 
        <div class="btn-share share-facebook" (click)="sendToFacebook()">
            <div>
            <img src="../../../assets/imagens/icon-facebook.png"/>
            <span>{{'facebook' | translate}}</span>
            </div>
        </div> 
        <div style="clear:both"></div>
    </div>

    <div class="btn-row">
        <div class="btn-share share-link" (click)="copyInviteLink()">
            <div>
            <img src="../../../assets/imagens/icon-link.png"/>
            <span>{{'link' | translate}}</span>
            </div>
        </div>
        <div style="clear:both"></div>
    </div>

</div> -->
<!-- 
<div *ngIf="this.themeService.fidelity_dashboard_on_top !== true">
    <app-fidelity-dashboard [clientId]="this.knownPersonId" [type]="this.type"></app-fidelity-dashboard>
</div> -->
