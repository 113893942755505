import { DatePipe } from '@angular/common';
import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Apollo, gql } from 'apollo-angular';
import { environment } from 'src/environments/environment';

const dataQuery = gql`{
  queryContractTemplateContents(filter:"data/appchannels/iv/channel eq '${environment.appChannel}' and status eq 'Published' and data/type/iv eq 18", orderby: "data/DateIntegrationUTC/iv desc", top: 1){
    id, flatData{
      templateContent
    }
  }
}`;

const dataQueryFidelity = gql`{
  queryContractTemplateContents(filter:"data/appchannels/iv/channel eq '${environment.appChannel}' and status eq 'Published' and data/type/iv eq 19", orderby: "data/DateIntegrationUTC/iv desc", top: 1){
    id, flatData{
      templateContent
    }
  }
}`;

@Injectable({
  providedIn: 'root'
})
export class CmsSharedService {

  public consentTermContent: any;

  public fidelityTermContent: any;

  constructor(private sanitizer: DomSanitizer,
    public datepipe: DatePipe,
    private apollo: Apollo) { }


  async initialize() {

    if (!this.consentTermContent) {
      var result = await this.apollo.use("getSharedContent").watchQuery<any>({
        query: dataQuery
      }).result();

      if (result.data.queryContractTemplateContents) {
        var data = result.data.queryContractTemplateContents[0].flatData;
        this.consentTermContent = this.sanitizer.bypassSecurityTrustHtml(data.templateContent);        
      }
    }

    if (!this.fidelityTermContent) {
      var result = await this.apollo.use("getSharedContent").watchQuery<any>({
        query: dataQueryFidelity
      }).result();

      if (result.data.queryContractTemplateContents) {
        var data = result.data.queryContractTemplateContents[0].flatData;
        this.fidelityTermContent = this.sanitizer.bypassSecurityTrustHtml(data.templateContent);        
      }
    }
  }
}
