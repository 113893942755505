import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

const fields = `
    solicitaDoc,
    acordoComunicacao,
    htmlMensagemInicial,
    shareWhatsapp,
    shareFacebook,
    shareTwitter,
    facebookAppId,
    rotuloSelLocalidade,
    rotuloSelData,
    rotuloSelHora,
    htmlInvitation,
    minSizeTelephone,
    maxSizeTelephone,
    minRestrictionFirstNumber,
    maxRestrictionFirstNumber,
    utilizaDDD,
    inviteSubject,
    htmlFidelityDashboard,
    htmlFidelityPresentation,
    mediaName,
    successMessage,
    fidelityDashboardOnTop,
    appAndroidLink,
    appIosLink,
    mensagemCadastro,
    textNextBtnRegister,
    textNextBtnUnitSelection,
    textNextBtnTimeSelection,
    whatsappRelationshipCenter,
    showWhatsappContactButton,
    whatsappMessage,
    htmlHigherIndicates,
    htmlBottomIndicates,
    useFreeSchedule`;

    const selectSchedulerfields = `
      titulo,
      descricao,
      icone{
        url
      },
      imagemFundo{
        url
      },
      utilizarHtmlCustomizado,
      html`;

    const schedulerDoneFields = `
        title,
        description,
        showWhatsapp,
        showFacebook,
        showLink,
        appDescription,
        showAndroid,
        showIos,
        ignoreEventCalendar,
        topHTML,
        htmlRefferFriends
    `;

const defaultQuery = gql`{
    queryConfiguracoesContents {
       flatData {
        ${fields}
      }
    }
  }`;
@Injectable({
    providedIn: 'root'
})
export class ThemeService {

    public solicita_doc: boolean;

    public acordo_comunicacao: any;

    public html_mensagem_inicial: any;

    public mensagem_cadastro: string;

    public share_whatsapp: any;

    public share_facebook: any;

    public share_twitter: any;

    public facebook_app_id: any;

    public rotulo_sel_localidade: any;

    public rotulo_sel_data: any;

    public rotulo_sel_hora: any;

    public html_invitation: any;

    public min_size_telephone: any;

    public max_size_telephone: any;

    public min_restriction_first_number: any;

    public max_restriction_first_number: any;

    public utiliza_ddd: any;

    public invite_subject: any;

    public html_fidelity_dashboard: any;

    public html_fidelity_presentation: any;

    public media_name: any;

    public success_message = '';

    public fidelity_dashboard_on_top: any;

    public app_android_link: string;

    public app_ios_link: string;

    public hasMarketingVinculo: boolean = false;

    public hasVariant: boolean = false;

    public mktDigitalPath: string;

    public text_Next_Btn_Register: string;

    public text_Next_Btn_Unit_Selection: string;

    public text_Next_Btn_Time_Selection: string;

    public whatsapp_relationship_center: string;

    public show_whatsapp_contact_button: any = false;

    public whatsapp_message: string;

    public html_higher_indicates: any;
    public html_bottom_indicates: any;
    public use_free_schedule: boolean;

    //campos select scheduler
    public has_select_scheduler_data_from_variants: boolean = false;
    public select_scheduler_titulo: string;
    public select_scheduler_descricao: string;
    public select_scheduler_imagem_fundo: any;
    public select_scheduler_icone: any;
    public select_scheduler_utiliza_html_customizado: boolean;
    public select_scheduler_html: string;

    //campos confirmação de cadastro
    public has_scheduler_done_data_from_variants: boolean = false;
    public scheduler_done_title: string;
    public scheduler_done_description: string;
    public scheduler_done_show_whatsapp: boolean;
    public scheduler_done_show_facebook: boolean;
    public scheduler_done_show_link: boolean;
    public scheduler_done_app_description: string;
    public scheduler_done_show_android: boolean;
    public scheduler_done_show_ios: boolean;
    public scheduler_done_ignore_events_calendar: boolean;
    public scheduler_done_top_html: string;
    public scheduler_done_reffer_friends_html: string;

    constructor(private sanitizer: DomSanitizer,
        private apollo: Apollo) {
    }

    async initialize(segmentation: string, hasMarketingVinculo: boolean = false) {
        var data;
        var dataSelectScheduler;
        var dataSchedulerDone;
        this.hasMarketingVinculo = hasMarketingVinculo;       

        if (segmentation != null) {

            if (this.hasMarketingVinculo)
            {
                const dataQuery = gql`{
                    queryVariantesMarketingDigitalVinculoContents (filter: "data/Id/iv eq '${segmentation}'") {
                        flatData {
                            configuracoes {
                                ${fields}
                            },
                            selecaoHorarioAgendamento{
                                ${selectSchedulerfields}
                            },
                            schedulerDone{
                                ${schedulerDoneFields}
                            }
                        }
                    }
                }`;

                var result = await this.apollo.watchQuery<any>({
                    query: dataQuery
                }).result();

                data = result.data.queryVariantesMarketingDigitalVinculoContents[0].flatData.configuracoes;
                dataSelectScheduler = result.data.queryVariantesMarketingDigitalVinculoContents[0]?.flatData.selecaoHorarioAgendamento;
                dataSchedulerDone = result.data.queryVariantesMarketingDigitalVinculoContents[0]?.flatData.schedulerDone;
            }
            else
            {
                this.hasVariant = true;

                const dataQuery = gql`{
                    queryVariantesContents (filter: "data/Id/iv eq '${segmentation}'") {
                        flatData {
                            configuracoes {
                                ${fields}
                            },
                            selecaoHorarioAgendamento{
                                ${selectSchedulerfields}
                            },
                            schedulerDone{
                                ${schedulerDoneFields}
                            }
                        }
                    }
                }`;

                var result = await this.apollo.watchQuery<any>({
                    query: dataQuery
                }).result();

                data = result.data.queryVariantesContents[0].flatData.configuracoes;
                dataSelectScheduler = result.data.queryVariantesContents[0]?.flatData.selecaoHorarioAgendamento;
                dataSchedulerDone = result.data.queryVariantesContents[0]?.flatData.schedulerDone;
            }
        }
        else {
            var result = await this.apollo.watchQuery<any>({
                query: defaultQuery
            }).result();

            data = result.data.queryConfiguracoesContents[0].flatData;
        }

        this.html_mensagem_inicial = this.sanitizer.bypassSecurityTrustHtml(data.htmlMensagemInicial);
        this.html_invitation = this.sanitizer.bypassSecurityTrustHtml(data.htmlInvitation);
        this.html_fidelity_dashboard = data.htmlFidelityDashboard; //this.sanitizer.bypassSecurityTrustHtml(data.htmlFidelityDashboard);
        this.html_fidelity_presentation = this.sanitizer.bypassSecurityTrustHtml(data.htmlFidelityPresentation);

        this.share_whatsapp = data.shareWhatsapp;
        this.share_facebook = data.shareFacebook;
        this.share_twitter = data.shareTwitter;

        this.facebook_app_id = data.facebookAppId;

        this.rotulo_sel_localidade = data.rotuloSelLocalidade;
        this.rotulo_sel_data = data.rotuloSelData;
        this.rotulo_sel_hora = data.rotuloSelHora;

        this.min_size_telephone = data.minSizeTelephone;
        this.max_size_telephone = data.maxSizeTelephone;
        this.min_restriction_first_number = data.minRestrictionFirstNumber;
        this.max_restriction_first_number = data.maxRestrictionFirstNumber;

        this.solicita_doc = data.solicitaDoc;

        this.acordo_comunicacao = data.acordoComunicacao;
        this.utiliza_ddd = data.utilizaDDD;

        this.invite_subject = data.inviteSubject;
        this.media_name = data.mediaName;

        this.success_message = data.successMessage;

        this.fidelity_dashboard_on_top = data.fidelityDashboardOnTop;

        this.app_android_link = data.appAndroidLink;
        this.app_ios_link = data.appIosLink;
        this.mensagem_cadastro = data.mensagemCadastro;
        this.text_Next_Btn_Register = data.textNextBtnRegister;
        this.text_Next_Btn_Unit_Selection = data.textNextBtnUnitSelection;
        this.text_Next_Btn_Time_Selection = data.textNextBtnTimeSelection;
        this.whatsapp_relationship_center = data.whatsappRelationshipCenter;
        this.show_whatsapp_contact_button = data.showWhatsappContactButton;
        this.whatsapp_message = data.whatsappMessage;

        this.html_higher_indicates = this.sanitizer.bypassSecurityTrustHtml(data.htmlHigherIndicates);
        this.html_bottom_indicates = this.sanitizer.bypassSecurityTrustHtml(data.htmlBottomIndicates);
        this.use_free_schedule = data.useFreeSchedule;

        if(dataSelectScheduler) {
            this.has_select_scheduler_data_from_variants = true;
            this.select_scheduler_titulo = dataSelectScheduler.titulo;
            this.select_scheduler_descricao = dataSelectScheduler.descricao;
            this.select_scheduler_imagem_fundo = dataSelectScheduler.imagemFundo;
            this.select_scheduler_icone  = dataSelectScheduler.icone;
            this.select_scheduler_utiliza_html_customizado = dataSelectScheduler.utilizarHtmlCustomizado;
            this.select_scheduler_html = dataSelectScheduler.html;
        }

        if(dataSchedulerDone){
            this.has_scheduler_done_data_from_variants = true;            
            this.scheduler_done_title = dataSchedulerDone.title;
            this.scheduler_done_description = dataSchedulerDone.description;
            this.scheduler_done_show_whatsapp = dataSchedulerDone.showWhatsapp;
            this.scheduler_done_show_facebook = dataSchedulerDone.showFacebook;
            this.scheduler_done_show_link = dataSchedulerDone.showLink;
            this.scheduler_done_app_description = dataSchedulerDone.appDescription;
            this.scheduler_done_show_android = dataSchedulerDone.showAndroid;
            this.scheduler_done_show_ios = dataSchedulerDone.showIos;
            this.scheduler_done_ignore_events_calendar = dataSchedulerDone.ignoreEventCalendar;
            this.scheduler_done_top_html = dataSchedulerDone.topHTML;            
            this.scheduler_done_reffer_friends_html = dataSchedulerDone.htmlRefferFriends;
        }

     }
}