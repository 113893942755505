import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ELeadMembershipType } from 'src/app/model/enum.leadMembershipType';
import { CmsSharedService } from 'src/app/services/cms-shared.service';
import { LeadService } from 'src/app/services/lead.service';
import { DialogContentPrivacyPolicy } from 'src/app/DialogContentPrivacyPolicy';
import { InitialThemeService } from 'src/app/services/initial-theme.service';

@Component({
  selector: 'app-consent-term-checkbox',
  templateUrl: './consent-term-checkbox.component.html',
  styleUrls: ['./consent-term-checkbox.component.scss']
})
export class ConsentTermCheckboxComponent implements OnInit {

  @Input() consentTermChecked: boolean;
  @Output() consentTermCheckedChange = new EventEmitter<boolean>();
  @Input() consentTermContent: string = "";
  @ViewChild('callContentTermModal') callContentTermModal: TemplateRef<any>;

  constructor(
    private modal: MatDialog,
    private cmsShared: CmsSharedService,
    public themeService: InitialThemeService,
    private leadService: LeadService
  ) { }

  async ngOnInit() {
    await this.cmsShared.initialize();

    this.consentTermContent = this.cmsShared.consentTermContent;

    if (!this.consentTermContent) {
      this.setCheck(true);
    }
  }

  setCheck(event: boolean) {
    this.consentTermChecked = event;
    this.consentTermCheckedChange.emit(this.consentTermChecked);
  }

  openModal() {

    const modalRef = this.modal.open(this.callContentTermModal, {
      panelClass: 'my-dialog'
    });

    modalRef.afterClosed().subscribe(result => {

      if (result == false || result == undefined) { 

        this.consentTermChecked = false;

      } else if (result == true) {

        this.consentTermChecked = true;

      }
    })
  }

  openPrivacyPolicyDialog(){
    this.modal.open(DialogContentPrivacyPolicy, {
      data: {
        html: this.themeService.html_politica_privacidade
      },
      height: "96vh",
      width: "95%",
      maxWidth: "95%",
      panelClass: "privacy-modal"
    });
  }

}
