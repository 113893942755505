<header class="sec-header" *ngIf="!this.isFromApp && !isFromFidelity">
    <section class="sec-topo">
        <div class="grid">
            <div class="logo">
                <a [href]="this.initialThemeService.link_site"><img [src]="this.initialThemeService.logo" alt=""></a>
            </div>
            <nav class="sec-menu">
                <section *ngIf="showHtmlTopo" [innerHTML]="this.initialThemeService.html_topo">
                </section>
            </nav>
        </div>
    </section>
</header>

<div *ngIf="isLoading" id="loading" class="block-screen">
    <mat-spinner></mat-spinner>
</div>

<section [style.background-image]="backgroundImg" class="sec-ch-banner" [class.sec-ch-banner-margin]="!this.isFromApp && !isFromFidelity" [ngClass]="{'sec-ch-banner-fidelity': isFromFidelity}">
    <div class="grid">
        <div class="img">
        </div>
        <div class="form" [ngClass]="{'form-align-fidelity': isFromFidelity}">
            <div class="container">
                <form id="msform">
                    
                    <fieldset fs-index="1" class="app-fs active msg-initial">
                        <app-initial-form [activeStep]="activeStep" [model]="model"
                            (onValidating)="onInitialFormValidating();"
                            (onConfirm)="initialFormConfirmed($event);"></app-initial-form>                            
                    </fieldset>
                    <fieldset fs-index="2" class="app-fs">                  
                        <app-unit-view *ngIf="useNewEstablishmentSearch" [model]="model" [activeStep]="activeStep" (onBack)="back($event);" (onConfirm)="selectEstablishmentConfirmed($event);" [mainEvents]="mainEvents"></app-unit-view>

                        <app-select-establishment *ngIf="!useNewEstablishmentSearch"
                            [activeStep]="activeStep" [model]="model"
                            [mainEvents]="mainEvents"
                            (onConfirm)="selectEstablishmentConfirmed($event);"
                            (onBack)="back($event);"></app-select-establishment>

                    </fieldset>
                    <fieldset fs-index="3" class="app-fs">
                        <app-consent-term  [activeStep]="activeStep" [model]="model"
                        (onConfirm)="acceptConsentTerm($event);"
                            (onBack)="back($event);"></app-consent-term>
                    </fieldset>

                    <!--AGENDAMENTO-->
                    <div *ngIf="stepName === 'SelectScheduler' || stepName === 'DoScheduler'" class="select-scheduler-top">
                        <app-select-scheduler-top-content [activeStep]="activeStep"></app-select-scheduler-top-content>
                    </div>
                    <fieldset fs-index="4" class="app-fs select-scheduler-fs">
                        <app-select-scheduler  [activeStep]="activeStep" [model]="model"
                        (onConfirm)="selectSchedulerConfirmed($event);"
                        (ConfirmSchedule)="doSchedulerConfirmed($event);"
                            (onBack)="back($event);"></app-select-scheduler>
                    </fieldset>
                    <fieldset fs-index="5" class="app-fs">
                        <app-do-scheduler [activeStep]="activeStep" [lead]="model" [timesAndSuggestions]="timesAndSuggestions"
                            (onConfirm)="doSchedulerConfirmed($event);"
                            (onBack)="back($event);"></app-do-scheduler>
                    </fieldset>
                    <fieldset fs-index="6" class="app-fs">
                        <app-scheduler-done [activeStep]="activeStep" [lead]="model" [selectedTime]="selectedTime"
                            (onConfirm)="schedulerDoneConfirmed($event)" *ngIf="selectedTime"
                            (onBack)="back($event);" (onSchedulerDone)="setInviteLink($event)"></app-scheduler-done>
                    </fieldset>
                    <fieldset fs-index="7" class="app-fs">
                        <app-refer-friend [activeStep]="activeStep" [model]="model"
                            (onConfirm)="referFriendsConfirmed($event)"
                            (onBack)="back($event);"></app-refer-friend>
                    </fieldset>
                    <fieldset fs-index="8" class="app-fs">
                        <app-final-message [activeStep]="activeStep"></app-final-message>
                    </fieldset>
                    <fieldset fs-index="9" class="app-fs msg-initial">
                        <app-invite-form [activeStep]="activeStep"></app-invite-form>
                    </fieldset>
                    <fieldset fs-index="10" class="app-fs">
                        <app-schedule-management [activeStep]="activeStep" [model]="model"></app-schedule-management>
                    </fieldset>
                    <div class="reffer" *ngIf="stepName === 'SchedulerDone'">
                        <app-reffer-area [inviteLink]="refferShareLink"></app-reffer-area>
                    </div> 
                </form>
            </div>
        </div>
    </div>
</section>

<section *ngIf="!isFromFidelity" id="como-participar" [innerHTML]="this.initialThemeService.html_como_participar">
</section>

<section *ngIf="!isFromFidelity" class="sec-passos" [innerHTML]="this.initialThemeService.html_passos" >
</section>


<section *ngIf="!isFromFidelity" class="sec-regulamento" id="regulamento" [innerHTML]="this.initialThemeService.html_regulamento">
</section>

<section *ngIf="!isFromFidelity" class="sec-espaco-lazer">
    <div class="grid">

        <div class="desc">
            

            <div class="redes">
                <div style="display: flex;" [innerHTML]="this.initialThemeService.html_media_social" >
                </div>
            </div>

            <p style="cursor: pointer;" (click)="openPrivacyPolicyDialog()">{{ 'PrivacyPolicy' | translate }}</p>
            <p style="display: none;" (click)="openCookiePolicyDialog()" id="hdnLinkCookiePolicy"></p>
        </div>
    </div>
</section>

<footer class="sec-rodape" *ngIf="!isFromFidelity">
    <section [innerHTML]="this.initialThemeService.html_footer" >
    </section>
</footer>