import { Injectable, EventEmitter, Inject, PLATFORM_ID } from '@angular/core';
import { BusinessError } from 'src/app/helper/BusinessError';
import { TranslateService } from '@ngx-translate/core';
import swal from 'sweetalert2';
import { isPlatformBrowser } from '@angular/common';
import { SegmentationService } from './segmentation.service';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UiService {

  uiChangedEvent: EventEmitter<any> = new EventEmitter();

  onLoading = new EventEmitter<boolean>();

  currentLanguage = new BehaviorSubject<string>(null);

  isLoading = false;

  loadingElement: any;

  isBrowser: boolean;

  activeSegmentation: string = null;

  public mask = {
    guide: true,
    showMask: true,
    mask: [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/]
  };


  constructor(
    private translateService: TranslateService,
    public segmentationService: SegmentationService,
    @Inject(PLATFORM_ID) platformId: Object) {
      this.isBrowser = isPlatformBrowser(platformId);
  }

  sendUiEvent(eventName) {
    this.uiChangedEvent.emit(eventName);
  }

  getDefaultLang(): string {
    return this.translateService.getDefaultLang();
  }
  setDefaultLang(lang: string) {
    if (!lang) {
      lang = 'pt-BR';
    }
    this.translateService.setDefaultLang(lang);
    
    this.currentLanguage.next(lang);
  }

  hasActiveSegmentation(): boolean {
      return this.getActiveSegmentation() != null;
  }

  getActiveSegmentation(): string {
      return this.activeSegmentation;
  }

  setActiveSegmentation(segmentation: string) {
    if(!this.segmentationService.isSegmentationValid(segmentation)){
        return;
    }

    this.activeSegmentation = segmentation;
  }

  async translate(key: string): Promise<any> {
    return await this.translateService.get(key).toPromise();
  }

  showLoading() {
    this.onLoading.emit(true);
  }

  hideLoading() {
    this.onLoading.emit(false);
  }

  async showError(e): Promise<any> {
    var message: string;

    if (e instanceof BusinessError) {
      message = e.message;
    } else {
      message = await this.translate("anErrorWasOcurred");
    }

    this.hideLoading();

    return await swal.fire({
        title: "Ops..",
        text: message,
        icon: "warning"
      });    
  }

  async showMessage(options) {
    return await swal.fire(options);
  }

  formatCurrency(number): string {
    var lang = this.getDefaultLang();

    var currency = '';
    
    switch (lang) {
      case 'pt-BR':
        currency = "BRL";
        break;
      default:
        currency = "USD";
        break;
    }

    var formatter = new Intl.NumberFormat(lang, {
      style: 'currency',
      currency: currency,
    });

    return formatter.format(number);
  }
}
