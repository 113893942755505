import { Component, OnInit, Input } from '@angular/core';
import { SchedulerDoneService } from 'src/app/services/pages/scheduler-done.service';
import { DomSanitizer } from '@angular/platform-browser';
import { ThemeService } from 'src/app/services/theme.service';

@Component({
  selector: 'app-reffer-area',
  templateUrl: './reffer-area.component.html',
  styleUrls: ['./reffer-area.component.scss']
})
export class RefferAreaComponent implements OnInit {
  refferAreaContent: any;
  @Input() inviteLink: string;
  constructor(private schedulerDoneService: SchedulerDoneService,
    private sanitizer: DomSanitizer,
    private themeService: ThemeService) { }

  async ngOnInit()  {
    let refferHtml;

    if (!this.themeService.has_scheduler_done_data_from_variants) {
      let refferContent = await this.schedulerDoneService.initializeReffer();
      refferHtml = refferContent?.htmlRefferFriends;
    }else{
      refferHtml = this.themeService.scheduler_done_reffer_friends_html;
    }
    
    if(refferHtml){
      this.refferAreaContent = this.sanitizer.bypassSecurityTrustHtml(refferHtml);
    }
  }


}
