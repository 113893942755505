<div class="unit-view">
    <h2 *ngIf="!filterView && !selectedUnit" class="unit-list-title">{{'selectPreferencEstablishment' | translate}}</h2>
    <div *ngIf="!filterView && !selectedUnit" class="see-more" (click)="openFilter()">
        <p style="float:left;">{{ 'seeMoreEstablishments' | translate }}</p>
        <svg version="1.1" id="Capa_1" class="svg-search" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="124.524px" height="124.524px" viewBox="0 0 124.524 124.524" style="enable-background:new 0 0 124.524 124.524;"
            xml:space="preserve">
            <g>
                <path d="M51,102.05c10.5,0,20.2-3.2,28.3-8.6l29.3,29.3c2.301,2.3,6.101,2.3,8.5,0l5.7-5.7c2.3-2.3,2.3-6.1,0-8.5L93.4,79.35
                    c5.399-8.1,8.6-17.8,8.6-28.3c0-28.1-22.9-51-51-51c-28.1,0-51,22.9-51,51C0,79.149,22.8,102.05,51,102.05z M51,20.05
                    c17.1,0,31,13.9,31,31c0,17.1-13.9,31-31,31c-17.1,0-31-13.9-31-31C20,33.95,33.9,20.05,51,20.05z"/>
            </g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g>
        </svg>
        <div class="clear"></div>
    </div>
    <app-unit-filter *ngIf="filterView && !selectedUnit" [userPosition]="userPosition" (closeFilterEvent)="closeFilter()" (selectAddressEvent)="selectAddress($event)"></app-unit-filter>
    <app-unit-selection-list *ngIf="!filterView && !selectedUnit"
        (selectUnitEvent)="selectUnit($event)"
        (userPositionEnabledEvent)="checkUserPosition($event)"
        [filteredLat]="filteredLat"
        [filteredLng]="filteredLng"
        [model]="model"
        [removedUnit]="removedUnit"
        [mainEvents]="mainEvents"
    ></app-unit-selection-list>
    <app-unit-selected *ngIf="selectedUnit" [selectedUnit]="selectedUnit" [userPosition]="userPosition"></app-unit-selected>
    <div *ngIf="!filterView">
        <button mat-button *ngIf="!selectedUnit" name="previous" class="previous action-button w50" (click)="back()" >{{ 'back' | translate }}</button>
        <button mat-button *ngIf="selectedUnit" name="previous" class="previous action-button w50" (click)="removeUnit()" >{{'backReschedulingMode' | translate}}</button>
        <button mat-button id="btnNextSelectEstablishment" name="next" class="next action-button w50" (click)="confirm()" >{{ this.themeService.text_Next_Btn_Unit_Selection }}</button>
    </div>
</div> 