import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UiService } from './ui.service';
import { BusinessError } from '../helper/BusinessError';
import { SchedulerSearchVM } from '../model/schedulerSearchVM';
import { Lead } from '../model/lead';
import { LeadService } from './lead.service';

@Injectable({
  providedIn: 'root'
})
export class SchedulerService {

    constructor(
      private http: HttpClient, 
      private uiService: UiService,
      private leadService: LeadService) {
        
    }

    async getAvaliableTimes(model: SchedulerSearchVM): Promise<any> {

      var data = <any>await this.http.post(`${environment.serviceUrl}/${this.uiService.getDefaultLang()}/GetAvaliableTimes`, model).toPromise();

      if (data.error) {
        throw new BusinessError(data.message);
      }

      return data;
    }

    async doSchedule(lead: Lead, date: Date, freeSchedule: boolean = false) {
      var data = <any>await this.http.post(`${environment.serviceUrl}/${this.uiService.getDefaultLang()}/DoSchedule`, { lead, date, freeSchedule }).toPromise();

      if (data.error) {
        throw new BusinessError(data.message);
      }
      
      /*
      if (data.lead) {        
        lead.name = data.lead.Name;        
        lead.organizationalStructure = data.lead.OrganizationalStructure;
      }
      */
    }

    
    async confirmSchedule(lead: Lead) {
      var data = <any>await this.http.post(`${environment.serviceUrl}/${this.uiService.getDefaultLang()}/ConfirmSchedule`, { lead }).toPromise();

      if (data.error) {
        throw new BusinessError(data.message);
      }
      
    }

    async cancelSchedule(lead: Lead) {
      var data = <any>await this.http.post(`${environment.serviceUrl}/${this.uiService.getDefaultLang()}/CancelSchedule`, { lead }).toPromise();

      if (data.error) {
        throw new BusinessError(data.message);
      }
      
    }
}