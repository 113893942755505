import { Injectable } from '@angular/core';
import { UiService } from './ui.service';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { ClientAlreadyExistsError } from '../helper/ClientAlreadyExistsError';
import { LinkService } from './link.service';
import { EmailAlreadyExistsError } from '../helper/EmailAlreadyExistsError';
import { ActivatedRoute, Router } from '@angular/router';

const defaultModalTitle: string = 'Ops..';

@Injectable({
  providedIn: 'root'
})

export class ExceptionHandlerService {


  constructor(
    private uiService: UiService,
    private translateService: TranslateService,
    private linkService: LinkService,
    private route: ActivatedRoute,
    private router: Router) {
  }

  async handle(e) {
    if (e instanceof ClientAlreadyExistsError) {
      await this.handleClientAlreadyExistsMessage(e);
    } else if (e instanceof EmailAlreadyExistsError) {
      await this.handleEmailAlreadyExistsMessage(e);
    } else {
      this.uiService.showError(e);
    }
  }

  private async handleClientAlreadyExistsMessage(e: ClientAlreadyExistsError) {
    if (this.uiService.isBrowser) {
      if (environment.app_endpoint) {
        await this.handleClientAlreadyExistsMessageA(e);
      }
      else {
        await this.handleClientAlreadyExistsMessageB(e);
      }
    }
    else {
      await this.handleClientAlreadyExistsMessageC(e);
    }
  }

  private async handleClientAlreadyExistsMessageA(e: ClientAlreadyExistsError) {
    await this.handleMessageA(e, 'default');
  }

  private async handleClientAlreadyExistsMessageB(e: ClientAlreadyExistsError) {
    await this.handleMessageB(e, 'default');
  }

  private async handleClientAlreadyExistsMessageC(e: ClientAlreadyExistsError) {
    await this.handleMessageC(e, 'default');
  }

  private async handleEmailAlreadyExistsMessage(e: EmailAlreadyExistsError) {
    if (this.uiService.isBrowser) {
      if (environment.app_endpoint) {
        await this.handleEmailAlreadyExistsMessageA(e);
      }
      else {
        await this.handleEmailAlreadyExistsMessageB(e);
      }
    }
    else {
      await this.handleEmailAlreadyExistsMessageC(e);
    }
  }

  private async handleEmailAlreadyExistsMessageA(e: EmailAlreadyExistsError) {
    await this.handleMessageA(e, 'emailAlreadyExists');
  }
  private async handleEmailAlreadyExistsMessageB(e: EmailAlreadyExistsError) {
    await this.handleMessageB(e, 'emailAlreadyExists');
  }

  private async handleEmailAlreadyExistsMessageC(e: EmailAlreadyExistsError) {
    await this.handleMessageC(e, 'emailAlreadyExists');
  }

  private async handleMessageA(e: any, type: string) {
    var result = await this.uiService.showMessage({
      title: await this.buildModalTitle(type),
      text: e.message,
      icon: "warning",
      showCancelButton: true,
      showDenyButton: true,
      confirmButtonText: await this.translateService.get("knowTheApp").toPromise(),
      cancelButtonText: await this.translateService.get("notNow").toPromise(),
      denyButtonText: await this.translateService.get("referFriends").toPromise(),
    });
    if (result.isConfirmed) {
      document.location.href = environment.app_endpoint;
    }
    else if (result.isDenied) {
      this.route.queryParams.subscribe(async params => {
        if (params['vinculo']) {
          //MARKETING DIGITAL
          var queryString = this.linkService.getQueryStringInUrl();
          var url = environment.shareBaseLink + queryString;
          document.location.href = this.linkService.getLinkVariantMarketingForInvitationPage(e.Person.id, e.Person.type, url);
        }
        else {
          var segmentation = this.linkService.getSegmentationInUrl();
          if (segmentation)
            document.location.href = this.linkService.getLinkVariantForInvitationPage(e.Person.id, e.Person.type, segmentation);
          else
            document.location.href = this.linkService.getLinkForInvitationPage(e.Person.id, e.Person.type);
        }
      });
    }
  }

  private async handleMessageB(e: any, type: string) {
    var result = await this.uiService.showMessage({
      title: await this.buildModalTitle(type),
      text: e.message,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: await this.translateService.get("referFriends").toPromise(),
      cancelButtonText: await this.translateService.get("notNow").toPromise()
    });
    if (result.isConfirmed) {
      this.route.queryParams.subscribe(async params => {
        if (params['vinculo']) {
          //MARKETING DIGITAL
          var queryString = this.linkService.getQueryStringInUrl();
          var url = environment.shareBaseLink + queryString;
          document.location.href = this.linkService.getLinkVariantMarketingForInvitationPage(e.Person.id, e.Person.type, url);
        }
        else {
          var segmentation = this.linkService.getSegmentationInUrl();
          if (segmentation)
            document.location.href = this.linkService.getLinkVariantForInvitationPage(e.Person.id, e.Person.type, segmentation);
          else
            document.location.href = this.linkService.getLinkForInvitationPage(e.Person.id, e.Person.type);
        }
      });
    }
  }

  private async handleMessageC(e: any, type: string) {
    await this.uiService.showMessage({
      title: await this.buildModalTitle(type),
      text: e.message,
      icon: "warning",
      confirmButtonText: await this.translateService.get("notNow").toPromise()
    });
  }

  private async buildModalTitle(type: string) {
    if (type == 'emailAlreadyExists') {
      var message = await this.translateService.get('emailAlreadyExists').toPromise();
      return `<div>${defaultModalTitle}</br><span style="margin: 10px 0;display:block;">${message}</span></div>`;
    }
    return defaultModalTitle;
  }
}