
    <h2 class="fs-title">{{ 'selectPreferencEstablishment' | translate }}</h2>

    <div>
        
        <mat-select #selectUnidade 
            [formControl]="selectUnidadeCtrl" 
            [(value)]="model.organizationalStructure" 
            (valueChange)="selecionarUnidade($event)" 
            [placeholder]="'selectEstablishment' | translate"
            panelClass="long-panel">
            <mat-select-trigger>{{model.organizationalStructure ? model.organizationalStructure.Description : ''}}
            </mat-select-trigger>

            <mat-option>
                <ngx-mat-select-search 
                    [formControl]="selectUnidadeFilterCtrl"
                    [placeholderLabel]="'enterAddress' | translate"
                    [noEntriesFoundLabel]="'noResultsFound' | translate"
                    [indexAndLengthScreenReaderText]="' de '"
                    (keydown)="$event.stopPropagation()">
                    <mat-icon ngxMatSelectSearchClear>highlight_off</mat-icon>
                </ngx-mat-select-search>
            </mat-option>
        
            <mat-option class="unidade-option" *ngFor="let establishment of filteredEstablishments | async" [value]="establishment">
                <span>
                    <!-- NOME UNIDADE -->
                    <span class="unidade-title"><b>{{getEstablishmentDescription(establishment.Description)}}</b></span>
    
                    <!-- ENDERECO UNIDADE SELECIONADA -->
                    <div class="unidade-end" *ngIf="establishment.Address">{{establishment.Address.Description}},
                        {{establishment.Address.Neighborhood}},
                        {{establishment.Address.Number}}
                    </div>
                    <p class="unidade-end" *ngIf="establishment.Address">{{establishment.Address.City}} 
                        {{ establishment.Address.Complement ? establishment.Address.Complement : '' }} -
                        {{establishment.Address.StateAbrev}}
                    </p>
    
                    <!-- HORARIO FUNCIONAMENTO UNIDADE -->
                    <ng-template [ngIf]="establishment.SchedulerCalendarTime">
                        <p class="unidade-end"><b>{{ 'openingHours' | translate }}</b></p>
                        <p class="unidade-end">
                            <span *ngFor="let calendar of establishment.SchedulerCalendarTime">{{ getWeekDay(calendar.DaysOfWeek) }} das {{calendar.StartTime}} às {{calendar.EndTime}}
                                <br>
                            </span>
                        </p>
                    </ng-template>
                </span>
                <mat-divider></mat-divider>
            </mat-option>
        </mat-select>

        <h3 class="fs-mini-info" >{{ 'inputCityForMoreLocationResults' | translate }}</h3>

        <agm-map *ngIf="useMap" [latitude]="lat" [zoom]="zoom" [longitude]="lng">
            <agm-marker *ngIf="userPosition" [latitude]="userLat" [longitude]="userLng"
                [iconUrl]="'./assets/imagens/user.png'"></agm-marker>

            <agm-marker *ngFor="let establishment of establishments" [visible]="establishment != model.organizationalStructure"
                [latitude]="establishment.AddressLat" [longitude]="establishment.AddressLng"
                [iconUrl]="'./assets/imagens/el-marker-icon.png'"
                (markerClick)="selecionarUnidade(establishment)"></agm-marker>

            <agm-marker *ngIf="model.organizationalStructure" [latitude]="model.organizationalStructure.AddressLat"
                [longitude]="model.organizationalStructure.AddressLng" [zIndex]="9999"
                [iconUrl]="'./assets/imagens/el-marker-icon-selected.png'"></agm-marker>
        </agm-map>
        
        <button mat-button name="previous" class="previous action-button w50" (click)="back()" >{{ 'back' | translate }}</button>
        <button mat-button id="btnNextSelectEstablishment" name="next" class="next action-button w50" (click)="confirm()" >{{ this.themeService.text_Next_Btn_Unit_Selection }}</button>
    </div>
