<ng-template #callContentFidelityTermModal>
    <button class="close" mat-button [mat-dialog-close]="true">X</button>
    <mat-dialog-content>
        <div [innerHTML]="fidelityTermContent"></div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="setCheck(true)">{{'acceptConsentTerm' |
            translate}}</button>
    </mat-dialog-actions>
</ng-template>


<mat-checkbox *ngIf="fidelityTermContent && showFidelityTerm" class="text-checkbox" [checked]="fidelityTermChecked"
    (change)="setCheck($event.checked)">
    {{'fidelityTermContent' | translate }}
    <a style="color: #015BFA" (click)="openModalFidelity()"> {{'fidelityTerm' | translate}} </a>
</mat-checkbox>