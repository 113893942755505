import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActiveFrameService } from 'src/app/services/activeFrame.service';

@Component({
  selector: 'app-final-message',
  templateUrl: './final-message.component.html',
  styleUrls: ['./final-message.component.scss']
})
export class FinalMessageComponent implements OnInit, AfterViewInit, OnDestroy {

  stepName: string = 'FinalMessage';

  @Input() activeStep: BehaviorSubject<string>;

  constructor(private activeFrameService: ActiveFrameService) { }

  ngOnInit(): void {
  }

  async ngAfterViewInit() {
    this.activeStep.subscribe(async (active) => {
      if (active == this.stepName) {
        this.activeFrameService.moveTo("8");
      }
    });
  }
  
  ngOnDestroy(): void {
    this.activeStep.unsubscribe();
  }
}
