<div *ngIf="!useHtml" class="scheduler-top-content" [ngStyle]="{'background': 'url(' + background + ') center center'}">
    <div class="text-wrapper">
        <h2 class="title">{{pageContents?.titulo}}</h2>
        <p class="description">{{pageContents?.descricao}}</p>
    </div>
    <div class="img-wrapper" *ngIf="icon">
        <img [src]="icon" class="top-content-icon" />
    </div>
    <div class="clear"></div>
</div>
<!--ESPAÇO RESERVADO PARA O CONTEÚDO INFERIOR (PARTE BRANCA)-->
<div *ngIf="!useHtml" class="scheduler-top-lower-content">

</div>

<div *ngIf="useHtml" [innerHtml]="htmlContent"></div>
