import { Component, Input, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { Lead } from 'src/app/model/lead';
import { Subject } from 'rxjs';
import { ActiveFrameService } from 'src/app/services/activeFrame.service';
import { UiService } from 'src/app/services/ui.service';
import { BusinessError } from 'src/app/helper/BusinessError';
import { SchedulerService } from 'src/app/services/scheduler.service';
import { DatePipe } from '@angular/common';
import { ThemeService } from 'src/app/services/theme.service';

declare function handleCustomDataLayer(step) : any

@Component({
  selector: 'app-do-scheduler',
  templateUrl: './do-scheduler.component.html',
  styleUrls: ['./do-scheduler.component.scss']
})
export class DoSchedulerComponent implements AfterViewInit, OnDestroy {

  stepName: string = 'DoScheduler';

  @Input() lead: Lead;

  @Input() timesAndSuggestions: any;

  times: Date[];

  suggestions: Date[];

  @Input() activeStep: Subject<string>;

  @Output() onConfirm = new EventEmitter<any>();

  @Output() onBack = new EventEmitter<string>();

  selectedTime: Date;

  constructor(
    private activeFrameService: ActiveFrameService,
    private uiService: UiService,
    private schedulerService: SchedulerService,
    public datepipe: DatePipe,
    public themeService: ThemeService) { }

  async ngAfterViewInit() {
    this.activeStep.subscribe(async (active) => {
      if (active == this.stepName) {
        
        setTimeout(_ => {
          this.times = this.timesAndSuggestions.times;
          this.suggestions = this.timesAndSuggestions.suggestions;
        }, 100);
        
        this.activeFrameService.moveTo("5");
      }
    });
  }

  ngOnDestroy(): void {
    this.activeStep.unsubscribe();
  }
  
  onSelectTime(selected) {
    this.selectedTime = selected;
  }

  async validate() {
    if (this.selectedTime == null) {
      throw new BusinessError(await this.uiService.translate("timeIsRequired"));
    }
  }

  back() {
    this.onBack.emit(this.stepName); 
  }

  async confirm() {
    try {
      await this.validate();

      await this.schedulerService.doSchedule(this.lead, this.selectedTime);

      this.onConfirm.emit({
        selectedTime: this.selectedTime,
        lead: this.lead
      });

      handleCustomDataLayer(4);
    } catch(e) {
      this.uiService.showError(e);
    }
  }
}
