export const enUS = {
    back: "Back",
    next: "Forward",
    name: "Name e Surname",
    email: "E-mail",
    phone: "Cell phone number",
    doc: "CPF",
    isBlockToCall: "Block Connections",
    nameIsRequired: "Please enter your name.",
    nameIsInvalid: "Please enter first and surname.",
    invalidEmail: "Please enter a valid email address.",
    phoneIsRequired: "Please enter a valid phone number.",
    docIsRequired: "Por favor, informe seu CPF.",
    docIsInvalid: "O CPF informado é inválido.",
    pleaseSelectEstablishment: "Please select a unit to continue.",
    selectEstablishment: "Please select a unit",
    anErrorWasOcurred: "There was an error processing your request.",
    selectPreferencEstablishment: "Select the Unit of your choice",
    waitMapPermission: "Waiting for permission to access your location and thus show the units closest to you.",
    inputCityForMoreLocationResults: "* You can enter the city, neighborhood, unit name or zip code to refine your search.",
    pleaseSelectDate: "Please enter a valid date.",
    pleaseSelectATurn: "Please select at least one period of the day.",
    invalidReCaptcha: "Please validate ReCaptcha.",
    schedule: "Schedule",
    selectTime: "Select Time",
    timeIsRequired: "Please select a time to continue.",
    schedulerDone: "All right here!",
    schedulerDetail: "Your appointment is confirmed at {{unidade}}, for {{data}}!",
    attention: "Attention!",
    alreadyRegisteredMessage: "It is not possible to proceed as we have located an active registration informed in unit {{unidade}}. Contact the unit for more information.",
    openWhatsapp: "Open WhatsApp",
    fromWhatsapp: "Pelo WhatsApp",
    referFriend: "This way",
    shareToWin: "Refer friends to receive the voucher by email.",
    shareToWinObs: "(If you don't receive it in your inbox, check the Spam box)",
    finalize: "Conclude",
    addMore: "Add More",
    noTimesForThisDay: "No schedule available for this day. 😥",
    referFriends: "Refer Friends",
    referFriendsRequired: "Please fill in all the fields on the nominee list.",
    thanks:"Thank you very much!",
    finalMessage: "We look forward to welcoming you to our unit! 😉",
    referFriendsDuplicated: "We identified repeated phone numbers in the list.",
    PrivacyPolicy: "Privacy Policy",
    CookiePolicy: "Cookie Policy",
    knowTheApp: "Get to know the APP",
    notNow: "Not now",
    weFindSomeSuggests: "However, we have selected some time options for you.",
    participation: "How to Participate",
    regulations: "Regulations",
    monday: "Monday",
    tuesday: "Tuesday",
    wednesday: "Wednesday",
    thursday: "Thursday",
    friday: "Friday",
    saturday: "Saturday",
    sunday: "Sunday",
    morning: "Morning",
    afternoon: "Afternoon", 
    night: "Night",
    openingHours: "OPENING HOURS",
    noResultsFound: "No results found",
    enterAddress: "Enter the desired address to search",
    close: "Close",
    sendInviteEmail: "Send invite",
    copy: "Copy",
    shareInvite: "Share your invitation link",
    copiedLink: "Link copied",
    emailSent: "E-mail sent",
    emailFailure: "We were unable to send your invitation. Use one of the other options available.",
    inviteWhatsApp: "Indicate by WhatsApp",
    inviteFacebook: "Share on Facebook",
    inviteTwitter: "Share on Twitter",
    inviteTelegram: "Indicate by Telegram",
    inviteEmail: "Indicate by e-mail",
    inviteCopy: "Copy link",
    backReschedulingMode: "Back",
    nextReschedulingMode: "Forward",
    invalidSegmentation: "Invalid segmentation",
    pleaseWait: "Please wait...",
    seeMoreEstablishments: "See more establishments",
    selectedEstablishment: "Selected establishment",
    fillFilter: "Enter a zip code, address, city, neighborhood or establishment name",
    establishmentNotFound: "No establishments to display.",
    establishments: "Establishments",
    cities: "Cities",
    neighborhoods: "Neighborhoods",
    addresses: "Addresses",
    establishmentFilterPlaceholder: "Type to search",
    pleaseSelectAllowedDate: "Please, select one period of the day allowed.",
    scheduleManagementTitle: "Your schedule",
    scheduleManagementLegend: "Please confirm your attendance by clicking the button below. Or if you prefer, use the other options to reschedule.",
    schedulerDetailEstablishment: "our appointment is confirmed at <b>{{unidade}}</b>",
    schedulerDetailDate: "For <b>{{data}}</b>",
    confirmSchedule: "Confirm Attendance",
    reschedule: "Reschedule",
    dontGo: "I Will Not Go",
    hi: "Hi {{name}}",
    cancelScheduleQuestion: "Are you sure you want to cancel your appointment with us? Don't worry, in the future you can come back here and reschedule whenever you want.",
    yes:  "Yes",
    no: "No",
    cancelScheduleSucess: "Cancellation was successful.",
    confirmScheduleSucess: "Appointment confirmed successfully.",
    loadMoreDays: 'Load more',
    filterByPeriod: 'Filter by period'
}
