<div class="unit-list-wrapper">    
    <div class="unit-list" infiniteScroll
    [infiniteScrollDistance]="2"
    [infiniteScrollThrottle]="50"
    [scrollWindow]="false"
    (scrolled)="loadMoreEstablishments()">
        <div class="unit-item" *ngFor="let unit of establishments" (click)="saveUnit(unit)">
            <span *ngIf="userPosition" class="unit-distance">{{unit.Distance?.toLocaleString(undefined, { maximumFractionDigits: 1, minimumFractionDigits: 1 })}} km</span>
            <p class="unit-name"><b>{{getEstablishmentDescription(unit.Description)}}</b></p>
            <p class="unit-uf-city"><b>{{unit.Address.StateAbrev + ' - ' + unit.Address.City}}</b></p>
            <p class="unit-address">{{unit.Address.Type | translate | uppercase}} {{unit.Address.Description + ', ' + unit.Address.Number +
                ' - ' + unit.Address.Neighborhood | uppercase }}</p>
                <p class="unit-scheduler" *ngFor="let calendar of unit.SchedulerCalendarTime">{{ getWeekDay(calendar.DaysOfWeek) }} das {{calendar.StartTime}} às {{calendar.EndTime}}
                    <br>
                </p>
        </div>
    </div>
</div>
