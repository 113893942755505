import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { Lead } from 'src/app/model/lead';
import { BusinessError } from 'src/app/helper/BusinessError';
import { UiService } from 'src/app/services/ui.service';
import { Subject } from 'rxjs';
import { ActiveFrameService } from 'src/app/services/activeFrame.service';
import { LeadService } from 'src/app/services/lead.service';
import { ValidationService } from 'src/app/services/validation.service';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import * as CryptoJS from 'crypto-js';
import { ThemeService } from 'src/app/services/theme.service';
import { ExceptionHandlerService } from 'src/app/services/exceptionHandler.service';
import { ActivatedRoute } from '@angular/router';
//import { MASKS, NgBrazilValidators, number } from 'ng-brazil';
import { DDDBrasil } from 'src/app/helper/DDDBrasil';

const CELLPHONE = '(00) 00000-0000';
const LANDLINE = '(00) 0000-00009';


declare function handleCustomDataLayer(step, label, address, encryptedEmail): any

@Component({
  selector: 'app-initial-form',
  templateUrl: './initial-form.component.html',
  styleUrls: ['./initial-form.component.scss']
})
export class InitialFormComponent implements OnInit, AfterViewInit, OnDestroy {

  submitted: boolean = false;

  public phoneErrors: boolean = false;

  // public mask = {};

  // public MASKS = MASKS;

  @Input() model: Lead;

  @Input() activeStep: Subject<string>;

  @Output() onValidating = new EventEmitter();

  @Output() onConfirm = new EventEmitter<Lead>();

  phoneMask: string = LANDLINE;

  cpfMask: string = "000.000.000-00";

  reCaptchaToken: string = null;

  configPhoneMask: string = null;
  configPhoneRegex: string = "[6-9]\\d{8}";

  ready: boolean = false;

  constructor(
    private uiService: UiService,
    private activeFrameService: ActiveFrameService,
    private leadService: LeadService,
    private recaptchaV3Service: ReCaptchaV3Service,
    public themeService: ThemeService,
    private exceptionHandlerService: ExceptionHandlerService,
    private route: ActivatedRoute
  ) {
    //this.mask = this.uiService.mask;
  }

  async ngOnInit() {
    this.uiService.currentLanguage.subscribe(async lang => {
      //considerar lang como flag de inicialização (baseado em home.component)
      if (this.uiService.isBrowser && lang) {


        this.route.queryParams
          .subscribe(async params => {

            let hasSize = this.themeService.min_size_telephone > 0 &&
              this.themeService.max_size_telephone > 0;

            let hasValidation = this.themeService.min_restriction_first_number > 0 &&
              this.themeService.max_restriction_first_number > 0;

            if (hasSize) {

              this.configPhoneMask = (this.themeService.utiliza_ddd == true ? "(00) 00000-0000" : "00000-0000");

              this.configPhoneRegex = (this.themeService.utiliza_ddd == true ? "\\d{2}" : "");
              if (hasValidation) {
                this.configPhoneRegex += `[${this.themeService.min_restriction_first_number}-${this.themeService.max_restriction_first_number}]\\d{${this.themeService.min_size_telephone - 1},${this.themeService.max_size_telephone - 1}}`;
              }
              else {
                this.configPhoneRegex += `\\d{${this.themeService.min_size_telephone},${this.themeService.max_size_telephone}}`;
              }
            }

            this.phoneChanged();
          });
        this.ready = true;
      }
    });
  }

  ngAfterViewInit() {

    this.ready = true;

    this.activeStep.subscribe((active) => {
      if (active == 'InitialForm') {
        this.activeFrameService.moveTo("1");
      }
    });
  }

  ngOnDestroy(): void {
    this.activeStep.unsubscribe();
  }

  validateEmail(email): boolean {
    return ValidationService.isEmailValid(email);
  }

  isSingleNameValid(name: string): boolean {
    return ValidationService.isSingleNameValid(name);
  }

  isNameValid(name: string): boolean {
    return ValidationService.isNameValid(name);
  }

  isPhoneValid(phone: string): boolean {

    // //Removendo validação feita por outros países
    // if (this.model.phoneCountryCode == "55") 

    phone = phone.replace("(", "").replace(")", "").replace("-", "").replace(" ", "");

    var dddExists: boolean = false;
    let PhoneDDD = phone.substring(0, 2);
    if (PhoneDDD.length != 2) {
      return false;
    }

    let FirstNumber = phone.substring(2, 3);
    if (FirstNumber != "9") {
      return false;
    }
    var y: number = +PhoneDDD;
    //Carrega Lista com DDDs válidos no Brasil
    let DDD = DDDBrasil.newDDDList();
    //Valida se existe o DDD
    DDD.forEach(function (value) {
      let code = value;
      if (code == y) {
        dddExists = true;
      }
    });

    if (!dddExists)
      return false;

    const re = new RegExp(`^${this.configPhoneRegex}$`);
    return re.test(phone);
  }

  isDocValid(doc): boolean {
    return ValidationService.isDocValid(doc);
  }

  phoneChanged() {

    if (this.ready) {
      setTimeout(_ => {
        if (this.model == null || this.model.phoneNumber == null || this.model.phoneNumber.length <= 10) {
          this.phoneMask = this.configPhoneMask ?? LANDLINE;
        }
        else {
          this.phoneMask = this.configPhoneMask ?? CELLPHONE;
        }
      }, 100);
    }
  }

  removeEmojis(string) {
    var regex = /(?:[\u2700-\u27bf]|(?:\ud83c[\udde6-\uddff]){2}|[\ud800-\udbff][\udc00-\udfff]|[\u0023-\u0039]\ufe0f?\u20e3|\u3299|\u3297|\u303d|\u3030|\u24c2|\ud83c[\udd70-\udd71]|\ud83c[\udd7e-\udd7f]|\ud83c\udd8e|\ud83c[\udd91-\udd9a]|\ud83c[\udde6-\uddff]|\ud83c[\ude01-\ude02]|\ud83c\ude1a|\ud83c\ude2f|\ud83c[\ude32-\ude3a]|\ud83c[\ude50-\ude51]|\u203c|\u2049|[\u25aa-\u25ab]|\u25b6|\u25c0|[\u25fb-\u25fe]|\u00a9|\u00ae|\u2122|\u2139|\ud83c\udc04|[\u2600-\u26FF]|\u2b05|\u2b06|\u2b07|\u2b1b|\u2b1c|\u2b50|\u2b55|\u231a|\u231b|\u2328|\u23cf|[\u23e9-\u23f3]|[\u23f8-\u23fa]|\ud83c\udccf|\u2934|\u2935|[\u2190-\u21ff])/g;

    return string.replace(regex, '');
  }

  async validate() {

    if (this.model.name == null) {
      throw new BusinessError(await this.uiService.translate("nameIsRequired"));
    }

    this.model.name = this.removeEmojis(this.model.name)?.trim();
    this.model.email = this.model.email?.trim();
    this.model.phoneNumber = this.model.phoneNumber?.trim();

    if (!this.isSingleNameValid(this.model.name)) {
      throw new BusinessError(await this.uiService.translate("nameIsInvalid"));
    }
    if (this.model.email == null || !this.validateEmail(this.model.email)) {
      throw new BusinessError(await this.uiService.translate("invalidEmail"));
    }
    if (this.model.phoneNumber == null || !this.isPhoneValid(this.model.phoneNumber) || this.model.phoneNumber.length <= 6) {
      throw new BusinessError(await this.uiService.translate("phoneIsRequired"));
    }
    if (this.reCaptchaToken == null) {
      throw new BusinessError(await this.uiService.translate("invalidReCaptcha"));
    }
    if (this.themeService.solicita_doc) {
      if (this.model.doc == null) {
        throw new BusinessError(await this.uiService.translate("docIsRequired"));
      }
      if (!this.isDocValid(this.model.doc)) {
        throw new BusinessError(await this.uiService.translate("docIsInvalid"));
      }
    }
    if (!this.model.consentTerm) {
      throw new BusinessError(await this.uiService.translate("pleaseYouHaveToAcceptTheTerm"));
    }
  }

  async confirm() {
    try {

      this.submitted = true;

      this.reCaptchaToken = await this.recaptchaV3Service.execute('confirm').toPromise()

      await this.validate();
      this.onValidating.emit();

      //Removendo mascara do telefone
      this.model.phoneNumber = this.model.phoneNumber.replace("(", "").replace(")", "").replace("-", "").replace(" ", "");

      var m = await this.leadService.validate(this.model, this.reCaptchaToken);

      if (m) {

        // if (m.status == 2 || m.status == 4) {         
        //   throw new BusinessError(await this.translateService.get("alreadyRegisteredMessage", { unidade: m.organizationalStructure["Description"] }).toPromise());
        // }

        this.model.id = m.id;
        this.model.status = m.status;
        this.model.organizationalStructure = m.organizationalStructure;
      }

      this.onConfirm.emit(this.model);

      var encryptedEmail = CryptoJS.SHA256(this.model.email).toString(CryptoJS.enc.Base64);
      handleCustomDataLayer(1, null, null, encryptedEmail);

    } catch (e) {
      await this.exceptionHandlerService.handle(e);
    }
  }

  // hasError(obj) { }

  // getNumber(obj) { }

  // telInputObject(obj) {
  //   obj.setCountry('br');
  //   //this.model.phoneCountryCode = "55";
  // }

  // onCountryChange(obj) {
  //   //this.model.phoneCountryCode = obj.dialCode;
  //   if (obj.dialCode != "55")
  //   {
  //     this.phoneMask = "";
  //   }
  //   else
  //   {
  //     setTimeout(_ => {
  //       if (this.model == null || this.model.phoneNumber == null || this.model.phoneNumber.length <= 10) {
  //         this.phoneMask = this.configPhoneMask ?? LANDLINE;
  //       }
  //       else {
  //         this.phoneMask = this.configPhoneMask ?? CELLPHONE;
  //       }
  //     }, 100);
  //   }
  //   this.model.phoneNumber = null;
  // }

}
