import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { DomSanitizer } from '@angular/platform-browser';
import { DatePipe } from '@angular/common';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';

const fields = `
    corDeFundo,
    htmlPassos,
    htmlComoParticipar,
    customCSS,
    htmlPoliticaPrivacidade,
    htmlPoliticaCookie,
    htmlRegulamento,
    htmlCopyright,
    htmlMediaSocial,
    htmlTopo,
    linkSite,
    metaTitle,
    metaDescription,
    metaSite,
    metaUrl,
    metaImage {
      url
    },
    gTM,
    globalization,
    ignoreScheduler,
    successMessage,
    logo {
        id
    },
    icone {
        id
    },
    imagemDeFundo {
        id
    },
    footerHtml,
    footerScripts,
    hideMenuRegistrationStage,
    hideMenuUnitSelection,
    hideMenuTimeSelection,
    hideMenuRegistrationConfirmation,
    hideMenuIndicate,
    useFreeSchedule`;

const fieldsBanners = `
    page,
    image{
      url
    },
    backgroundColor`;
    
const fieldsTemplateEmail = `
    tipo,
    titulo,
    conteudo`;

const defaultQuery = gql`{
    queryConfiguracoesContents {
       flatData {
        ${fields}
      }
    }
  }`;

const defaultQueryBanners = gql`{
    queryBannersContents {
        flatData{
        page,
        image{
            url
        },
        backgroundColor
        }
    }
}`;

@Injectable({
    providedIn: 'root'
})
export class InitialThemeService {

    public html_politica_cookie: any;

    public html_media_social: any;

    public html_como_participar: any;

    public html_passos: any;

    public custom_css: any;

    public html_regulamento: any;

    public html_politica_privacidade: any;

    public html_copyright: any;

    public html_topo: any;

    public logo: any;

    public icone: any;

    public link_site: any;

    public meta_title: any;

    public meta_description: any;

    public meta_site: any;

    public meta_url: any;

    public meta_image: any;

    public gtm: any;

    public globalization: any;

    public corDeFundo: any;

    public backgroundImage: any;

    public customCSS: any;

    initPromotionDate: Date;

    endPromotionDate: Date;

    public ignore_scheduler: any;

    public success_message = '';

    public banners: any;

    public html_footer: any;

    public scripts_footer: any;

    public hide_menu_registration_stage: any;
    public hide_menu_unit_selection: any;
    public hide_menu_time_selection: any;
    public hide_menu_registration_confirmation: any;
    public hide_menu_indicate : any;
    public use_free_schedule: boolean;

    constructor(private sanitizer: DomSanitizer,
        public datepipe: DatePipe,
        private apollo: Apollo) {

        this.initPromotionDate = new Date();
        this.initPromotionDate.setHours(0, 0, 0, 0);

        this.endPromotionDate = new Date(this.initPromotionDate);
        this.endPromotionDate.setDate(this.initPromotionDate.getDate() + 90);
    }

    async initialize(segmentation: string, hasMarketingVinculo: Boolean = false) {
        var data;
        var dataBanners;

        if(segmentation != null){

            var dataQuery;

            if (!hasMarketingVinculo)
            {
                dataQuery = gql`{
                    queryVariantesContents (filter: "data/Id/iv eq '${segmentation}'") {
                        flatData {
                            configuracoes {
                                ${fields}
                            },
                            banners {
                                ${fieldsBanners}
                            }
                        }
                    }
                }`;

                var result = await this.apollo.watchQuery<any>({
                    query: dataQuery
                }).result();
    
                data = result.data.queryVariantesContents[0].flatData.configuracoes;
                dataBanners = result.data.queryVariantesContents[0].flatData.banners?.map(q => {
                    return {
                      page: q.page,
                      image: q.image && q.image.length > 0 && q.image[0] ? q.image[0].url : null,
                      backgroundColor: q.backgroundColor
                    }
                  });   ;
            }
            else
            {
                dataQuery = gql`{
                    queryVariantesMarketingDigitalVinculoContents (filter: "data/Id/iv eq '${segmentation}'") {
                        flatData {
                            configuracoes {
                                ${fields}
                            },
                            banners {
                                ${fieldsBanners}
                            }
                        }
                    }
                }`;

                var result = await this.apollo.watchQuery<any>({
                    query: dataQuery
                }).result();
    
                data = result.data.queryVariantesMarketingDigitalVinculoContents[0].flatData.configuracoes;
                dataBanners = result.data.queryVariantesMarketingDigitalVinculoContents[0].flatData.banners?.map(q => {
                    return {
                      page: q.page,
                      image: q.image && q.image.length > 0 && q.image[0] ? q.image[0].url : null,
                      backgroundColor: q.backgroundColor
                    }
                  });   ;
            }
        }
        else
        {
            var result = await this.apollo.watchQuery<any>({
                query: defaultQuery
            }).result();

            data = result.data.queryConfiguracoesContents[0].flatData;

            var result2 = await this.apollo.watchQuery<any>({
                query: defaultQueryBanners
            }).result();

            dataBanners = result2.data.queryBannersContents.map(q => {
                return {
                  page: q.flatData.page,
                  image: q.flatData.image ? q.flatData.image[0].url : null,
                  backgroundColor: q.flatData.backgroundColor
                }
              });
        }

        this.corDeFundo = data.corDeFundo ? data.corDeFundo : "#fff";
        this.backgroundImage = `url(${environment.cms_url}${environment.cms_assets_endpoint.replace("{0}", data.imagemDeFundo[0].id)})`;
        this.customCSS = data.customCSS;

        this.logo = `${environment.cms_url}${environment.cms_assets_endpoint.replace("{0}", data.logo[0].id)}`;
        this.icone = `${environment.cms_url}${environment.cms_assets_endpoint.replace("{0}", data.icone[0].id)}`;

        this.html_como_participar = this.sanitizer.bypassSecurityTrustHtml(data.htmlComoParticipar);
        this.html_passos = this.sanitizer.bypassSecurityTrustHtml(data.htmlPassos);
        this.html_politica_privacidade = this.sanitizer.bypassSecurityTrustHtml(data.htmlPoliticaPrivacidade);
        this.html_politica_cookie = this.sanitizer.bypassSecurityTrustHtml(data.htmlPoliticaCookie);
        this.html_copyright = this.sanitizer.bypassSecurityTrustHtml(data.htmlCopyright);
        this.html_media_social = this.sanitizer.bypassSecurityTrustHtml(data.htmlMediaSocial);

        this.hide_menu_registration_stage = data.hideMenuRegistrationStage;
        this.hide_menu_unit_selection = data.hideMenuUnitSelection;
        this.hide_menu_time_selection = data.hideMenuTimeSelection;
        this.hide_menu_registration_confirmation = data.hideMenuRegistrationConfirmation;
        this.hide_menu_indicate = data.hideMenuIndicate;

        this.html_topo = this.sanitizer.bypassSecurityTrustHtml(data.htmlTopo);
        this.html_footer = this.sanitizer.bypassSecurityTrustHtml(data.footerHtml);
        this.scripts_footer = data.footerScripts;
        
        var regulamento = data.htmlRegulamento;

        if (regulamento) {
            regulamento = regulamento.replace('[[PromoIni]]', this.datepipe.transform(this.initPromotionDate, 'dd/MM/yyyy'));
            regulamento = regulamento.replace('[[PromoFim]]', this.datepipe.transform(this.endPromotionDate, 'dd/MM/yyyy'));
            this.html_regulamento = this.sanitizer.bypassSecurityTrustHtml(regulamento);
        }

        this.link_site = data.linkSite;

        this.meta_title = data.metaTitle;
        this.meta_description = data.metaDescription;
        this.meta_url = data.metaUrl;
        this.meta_site = data.metaSite;
        this.meta_image = data.metaImage[0]?.url;
        this.gtm = data.gTM;

        this.globalization = data.globalization;
        this.ignore_scheduler = data.ignoreScheduler;
        this.success_message = data.successMessage;

        this.banners = dataBanners;
        this.use_free_schedule = data.useFreeSchedule;

    }

    applyBanner(activeStep: string){
    
        var currentBanner;
    
        switch(activeStep)  {
          case 'InitialForm':
            currentBanner = 'form_inicial'
            break;
          case 'SelectEstablishment':
            currentBanner = 'selecionar_unidade'
            break;
          case 'SelectScheduler':
            currentBanner = 'selecionar_data_agendamento'
            break;
          case 'DoScheduler':
            currentBanner = 'selecionar_horario_agendamento'
            break;
          case 'SchedulerDone':
            currentBanner = 'agendamento_concluido'
            break;
          case 'InviteForm':
              currentBanner = 'indicar_amigo'
            break;
        }
            
        return this.banners?.filter(w => w.page == currentBanner)[0];
    
      }
    
}
