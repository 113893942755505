import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UiService } from './ui.service';
import { BusinessError } from '../helper/BusinessError';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ShortlinkService {

  constructor(
    private http: HttpClient, 
    private uiService: UiService
  ) { }

  async generateShortenerURL(url: string, baseUrl: string, isQueryParam: boolean) {
    var data = <any>await this.http.post(`${environment.serviceUrl}/${this.uiService.getDefaultLang()}/GenerateShortenerURL`, { url, baseUrl, isQueryParam }).toPromise();
    if (data.error) {
      throw new BusinessError(data.message);
    }
    return data;
  }

  async getShortenerURLByToken(token: string) {

    var data = <any>await this.http.post(environment.serviceUrl + `/${this.uiService.getDefaultLang()}/GetShortenerURLByToken`,  {Token: token} ).toPromise();
    
    if (data.error) {
      throw new BusinessError(data.message);
    }
    return data;
  }

  generateShortenerURLForCopy(url: string, baseUrl: string, isQueryParam: boolean): Observable<any>{
    return this.http.post(`${environment.serviceUrl}/${this.uiService.getDefaultLang()}/GenerateShortenerURL`, { url, baseUrl, isQueryParam }) as Observable<any>;
  }

}
