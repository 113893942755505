import { NgcCookieConsentConfig } from 'ngx-cookieconsent';

export const cookieConfig: NgcCookieConsentConfig =
{
  "enabled": true,
  "cookie": {
    "domain": "tinesoft.github.io"
  },
  "position": "bottom-left",
  "theme": "classic",
  "palette": {
    "popup": {
      "background": "rgba(64,64,64,.9)",
      "text": "#ffffff",
      "link": "#5bb4e5"
    },
    "button": {
      "background": "#7b3d90",
      "text": "#ffffff",
      "border": "transparent"
    }
  },
  "type": "info",
  "content": {
    "message": "Utilizamos cookies para melhorar a sua experiência, otimizar as funcionalidades do site, obter estatísticas de visita e para fins de publicidade. Para maiores informações acesse o nosso",
    "dismiss": "Estou Ciente",
    "deny": "",
    "link": "Aviso de Privacidade",
    "href": "javascript:openCookiePolicy();",
    "target": "_self",
    "policy": "Cookie Policy"
  }
}