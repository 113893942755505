<div [innerHtml]="this.themeService.html_mensagem_inicial"></div>

<div class="container-cad">

<input matInput type="text" class="text-cad" placeholder="{{ 'name' | translate }}" [(ngModel)]="model.name" name="name" />

<input matInput type="email" class="text-cad" placeholder="{{ 'email' | translate }}" [(ngModel)]="model.email" name="email" />

<!--CELULAR-->
<input matInput type="tel" class="text-cad" placeholder="{{ 'phone' | translate }}" [(ngModel)]="model.phoneNumber" name="phoneNumber"
    [mask]="phoneMask" (ngModelChange)="phoneChanged();" />

<!-- <div class="div-phone">
<input matInput type="text" class="text-cad phone-cad" ng2TelInput [ng2TelInputOptions]="{initialCountry: 'br', separateDialCode:true}"
                [ngClass]="(submitted && phoneErrors ? 'error ' : '') + 'textfield'"
                (hasError)="hasError($event)" (ng2TelOutput)="getNumber($event)"
                (intlTelInputObject)="telInputObject($event)"
                placeholder="{{ 'phone' | translate }}"
                autocomplete="off"
                [(ngModel)]="model.phoneNumber"
                [mask]="phoneMask"
                maxlength="20"
                (countryChange)="onCountryChange($event)" #telefone />
</div> -->

<input matInput class="text-cad" placeholder="{{ 'doc' | translate }}" [(ngModel)]="model.doc" name="doc" [mask]="cpfMask"
    *ngIf="this.themeService.solicita_doc" />

<!-- <mat-checkbox [(ngModel)]="model.isBlockToCall" name="isBlockToCall" class="fs-info" *ngIf="this.themeService.permite_alt_bloqueio_contato">{{ 'isBlockToCall' | translate }}</mat-checkbox> -->

<p class="fs-info" *ngIf="this.themeService.acordo_comunicacao">{{ this.themeService.acordo_comunicacao }}</p>

<diV class="terms">
    <app-consent-term-checkbox [(consentTermChecked)]="model.consentTerm"></app-consent-term-checkbox>
    <app-fidelity-term-checkbox [(fidelityTermChecked)]="model.fidelityTerm"></app-fidelity-term-checkbox>
</diV>

<button mat-button id="btnNextInitialForm" name="next" class="next action-button btn-next-cad" (click)="confirm()">{{ this.themeService.text_Next_Btn_Register }}</button>

</div>

