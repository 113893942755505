import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { UiService } from './ui.service';
import { BusinessError } from '../helper/BusinessError';

@Injectable({
  providedIn: 'root'
})
export class FidelityService {

  constructor(
    private http: HttpClient, 
    private uiService: UiService
  ) { }

  async getPoints(clientId: number) : Promise<number> {
    var data = await this.callApi(clientId, false);
    return data.points;
  }
  
  async getPointsWithExtraData(personId: number, type?: string) {
    var data = await this.callApi(personId, true, type);
    return data;
  }
    
  async hasPendingResponse(clientId: number) : Promise<boolean> {
    return await this.getFidelityProgramStatus(clientId, 'GetHasPendingFidelityProgramResponse');
  }

  async isClientInFidelityProgram(personId: number, type?: string) : Promise<boolean> {
    return await this.getFidelityProgramStatus(personId, 'GetIsClientInProgram', type);
  }

  private async getFidelityProgramStatus(personId: number, method: string, type?: string) : Promise<boolean> {

    const request = type && type == 'lead' ? {LeadId: personId} : {ClientId: personId};

    var data = <any>await this.http.post(`${environment.serviceUrl}/${this.uiService.getDefaultLang()}/${method}`, request).toPromise();
    if (data.error) {
      throw new BusinessError(data.message);
    }
    return data.result;
  } 

  private async callApi(personId: number, getExtraData: boolean, type?: string) {

    const request = type && type == 'lead' ? { ReferringLeadId: personId, GetExtraData: getExtraData } : {ClientId: personId, GetExtraData: getExtraData };

    var data = <any>await this.http.post(`${environment.serviceUrl}/${this.uiService.getDefaultLang()}/GetFidelityPoints`, request).toPromise();
    if (data.error) {
      throw new BusinessError(data.message);
    }
    return data;
  }
}
