import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { OrganizationalStructure } from 'src/app/model/organizationalStructure';
import { LeadService } from 'src/app/services/lead.service';
import { environment } from 'src/environments/environment';
import { LocationService } from 'src/app/services/location.service';
import { Lead } from 'src/app/model/lead';
import { EstablishmentService } from 'src/app/services/establishment.service';
import { Subject } from 'rxjs';


@Component({
  selector: 'app-unit-selection-list',
  templateUrl: './unit-selection-list.component.html',
  styleUrls: ['./unit-selection-list.component.scss']
})
export class UnitSelectionListComponent implements OnInit {

  userLat: number;

  userLng: number;

  userPosition: boolean = false;

  mapReady: boolean = false;

  establishments: OrganizationalStructure[];

  @Input() filteredLat: any;

  @Input() filteredLng: any;

  @Input() model: Lead;

  @Input() removedUnit: boolean;

  lat: number = null;

  lng: number = null;

  weekdays: string[];

  establishmentPaginationSkip: number = 0;

  establishmentsReady: boolean = false;

  @Output() selectUnitEvent = new EventEmitter<number>();

  @Output() userPositionEnabledEvent = new EventEmitter<boolean>();

  @Input() mainEvents: Subject<string>;

  constructor(
    private leadService: LeadService,
    private locationService: LocationService,
    private establishmentService: EstablishmentService) { }

  async ngOnInit() { 
    this.weekdays = await this.establishmentService.translateWeekDays();
  }

  async ngAfterViewInit(){
    this.mainEvents.subscribe(async (event) => {    
      if (event == "SelectEstablishment" ||
      event == "backFromSelectScheduler"){
        await this.loadUserPosition();
        return;
      }
    }); 
    if(this.removedUnit || (this.filteredLat && this.filteredLng)){
      await this.loadUserPosition();
    }
  }

  saveUnit(unit: any){
    this.selectUnitEvent.emit(unit);
  }

  async loadEstablishments() {

    this.establishments = await this.getEstablishments();
    
    this.establishments.forEach(item =>{
      item.Distance = this.locationService.getDistance(item.AddressLat, item.AddressLng, this.filteredLat ?? this.lat, this.filteredLng ?? this.lng);
    });

    this.weekdays = await this.establishmentService.translateWeekDays();

    this.establishmentsReady = true;

  }

  async loadUserPosition() {  
    try {      
      if (environment.usesGeolocation) {

        var pos = await this.locationService.getPosition();

        this.userPosition = true;
        this.userLat = pos.lat;
        this.userLng = pos.lng;
        this.lat = pos.lat;
        this.lng = pos.lng;
        this.loadEstablishments();
        
      } else {
        this.userPosition = false;
        
      }
    }
    catch (e) {
      this.userPosition = false;
      if(this.filteredLat && this.filteredLng){ 
        this.loadEstablishments();
      }
    }
    finally {
      this.mapReady = true;
      this.emitUserPositionEnabledEvent();
    }
  }

  getWeekDay(daysOfWeek: number[],) {    
    if (!daysOfWeek || !daysOfWeek.length || daysOfWeek.length <= 0) return '';
    if (!this.weekdays) return '';

    let fDay = this.weekdays[daysOfWeek[0]];
    let lDay = daysOfWeek.length > 1 ? ' à ' + this.weekdays[daysOfWeek[daysOfWeek.length - 1]] : '';
    return `${fDay}${lDay}`;
  }

  getEstablishmentDescription(description: string) {
    return this.establishmentService.getEstablishmentDescription(description);
  }

  async loadMoreEstablishments() {
    this.establishmentPaginationSkip += 20;
    let moreEstabs = await this.getEstablishments();

    if(moreEstabs && moreEstabs.length > 0){
      moreEstabs.forEach(item =>{
        item.Distance = this.locationService.getDistance(item.AddressLat, item.AddressLng, this.filteredLat ?? this.lat, this.filteredLng ?? this.lng);
        this.establishments.push(item);
      });
    }        
  }

  async getEstablishments(){
    if(this.filteredLat && this.filteredLng){
      return this.leadService.getEstablishments(this.filteredLat, this.filteredLng, this.model?.attendantId, this.model?.marketingDigitalBondId, this.establishmentPaginationSkip);
    }
    else{
      return await this.leadService.getEstablishments(this.lat, this.lng, this.model?.attendantId, this.model?.marketingDigitalBondId, this.establishmentPaginationSkip);
    }
  }

  emitUserPositionEnabledEvent(){
    this.userPositionEnabledEvent.emit(this.userPosition);
  }
}
