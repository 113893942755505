import { Injectable, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class IconService { 

  constructor(@Inject(DOCUMENT) private doc) {}

  registerIcon(href) {
     let link: HTMLLinkElement = this.doc.createElement('link');
     link.setAttribute('rel', 'shortcut icon');
     this.doc.head.appendChild(link);
     link.setAttribute('href', href);
   }
}