import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Renderer2 } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS } from '@angular/material/core';
import { Meta, Title } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AppDateAdapter, APP_DATE_FORMATS } from './adapters/appDateAdapter';
import { EncrypterService } from './services/encrypter.service';
import { IconService } from './services/icon.service';
import { InitialThemeService } from './services/initial-theme.service';
import { SeoService } from './services/seo.service';
import { UiService } from './services/ui.service';
import { MarketingDigitalService } from 'src/app/services/marketing-digital.service';
import { LinkService } from './services/link.service';
import { ThemeService } from './services/theme.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    { provide: DateAdapter, useClass: AppDateAdapter },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS }
  ]
})
export class AppComponent implements OnInit {

  constructor(
    private title: Title,
    private meta: Meta,
    private uiService: UiService,
    private route: ActivatedRoute,
    private initialThemeService: InitialThemeService,
    private seoService: SeoService,
    private iconService: IconService,
    @Inject(DOCUMENT) private document: any,
    private renderer: Renderer2,
    private router: Router,
    private marketingDigitalService: MarketingDigitalService,
    private linkService: LinkService,
    private themeService: ThemeService) {
  }

  async ngOnInit() {

    //Para que possamos passar a iniciar o processo corretamente vamos buscar
    //os parametros e validar se vem de um vinculo do marketing digital
    //https://stackoverflow.com/questions/39861547/angular2-query-params-subscription-fires-twice/45527189#45527189
    this.router.events.subscribe(evt => {
      if (this.router.navigated) {
        this.route.queryParams
          .subscribe(async params => {
            if (params['vinculo']) {
              let pMarketingDigitalBond = params['vinculo'];
              let valMarketingDigitalBond = EncrypterService.decrypt(pMarketingDigitalBond);
              let id = Number.parseInt(valMarketingDigitalBond);
              let mdData = await this.marketingDigitalService.getBondRecord(id);

              if (mdData) {
                await this.uiService.segmentationService.initialize(true);
                var segmentationMK = mdData.path;
                this.themeService.mktDigitalPath = mdData.redirectTo;

                this.uiService.setActiveSegmentation(segmentationMK);
                await this.initialThemeService.initialize(this.uiService.getActiveSegmentation(), true);
                await this.themeService.initialize(this.uiService.getActiveSegmentation(), true);
              }
            } else {
              await this.uiService.segmentationService.initialize();
              var segmentation = this.linkService.getSegmentationInUrl();

              this.uiService.setActiveSegmentation(segmentation);
              await this.initialThemeService.initialize(this.uiService.getActiveSegmentation(), false);
              await this.themeService.initialize(this.uiService.getActiveSegmentation());
            }

            this.initTheme();
          });
      }
    });
  }


  initTheme() {
    this.uiService.setDefaultLang(this.initialThemeService.globalization);

    if (this.initialThemeService.customCSS) {
      const fileref = this.renderer.createElement('style');
      fileref.innerHTML = this.initialThemeService.customCSS;
      this.renderer.appendChild(this.document.head, fileref);
    }

    this.renderer.setStyle(this.document.documentElement, '--background-image', this.initialThemeService.backgroundImage);
    this.renderer.setStyle(this.document.documentElement, '--box-size', '40%');
    this.renderer.setStyle(this.document.documentElement, '--default-text-align', 'center');

    this.title.setTitle(this.initialThemeService.meta_title);
    //meta tag principais
    this.meta.updateTag({ name: 'title', content: this.initialThemeService.meta_title });
    this.meta.updateTag({ name: 'description', content: this.initialThemeService.meta_description });

    //open graph/facebook
    this.meta.updateTag({ property: 'og:description', content: this.initialThemeService.meta_description });
    this.meta.updateTag({ property: 'og:title', content: this.initialThemeService.meta_title });
    this.meta.updateTag({ property: 'og:url', content: this.initialThemeService.meta_url });
    this.meta.updateTag({ property: 'og:image', content: this.initialThemeService.meta_image });
    this.meta.updateTag({ property: 'og:site_name', content: this.initialThemeService.meta_site });

    //twitter
    this.meta.updateTag({ name: 'twitter:url', content: this.initialThemeService.meta_url });
    this.meta.updateTag({ name: 'twitter:site', content: this.initialThemeService.meta_site });
    this.meta.updateTag({ name: 'twitter:title', content: this.initialThemeService.meta_title });
    this.meta.updateTag({ name: 'twitter:description', content: this.initialThemeService.meta_description });
    this.meta.updateTag({ name: 'twitter:image', content: this.initialThemeService.meta_image });

    this.seoService.createGTM(this.initialThemeService.gtm);
    this.seoService.createLinkForCanonicalURL();
    this.iconService.registerIcon(this.initialThemeService.icone);
  }

}
