<section class="sec-select-day">
    <div class="filter-container" *ngIf="isBrowser">
        <div class="filter-wrapper">
            <p class="filter-title">{{'filterByPeriod' | translate}}:</p>
            <div class="filter-tooltip">
                <p>{{formatLabel(minValue)}} - {{formatLabel(maxValue)}}</p>
            </div>
            <div class="clear"></div>
            <div class="slider-wrapper">
                <img class="icon-sun" src="../../../../assets/imagens/icon-range-1.png"/>
                <img class="icon-moon" src="../../../../assets/imagens/icon-range-2.png"/>
                <ngx-slider 
                    [(value)]="minValue" 
                    [(highValue)]="maxValue" 
                    [options]="options" 
                    (userChangeEnd)="applyFilter()">
                </ngx-slider>
            </div>
        </div>
    </div>
    <div class="days-list">
        <mat-accordion>
            <mat-expansion-panel *ngFor="let item of schedulerDaysToShow" [disabled]="!item.avaliable"
                (opened)="panelOpenState = true" (closed)="panelOpenState = false">
                <mat-expansion-panel-header>
                    <mat-panel-title>
                        {{item.dayName | titlecase}}
                        <span class="today-legend" *ngIf="item.isToday">&nbsp;- Hoje</span>
                    </mat-panel-title>
                    <mat-panel-description>
                        {{item.exibitionDate}}
                    </mat-panel-description>
                </mat-expansion-panel-header>
                <div *ngIf="item.times && item.times.length > 0" class="times-wrapper">
                    <div 
                        class="time-item"
                        *ngFor="let time of item.times"
                        [ngClass]="{'active': time.active}"
                        [hidden]="time.hidden"
                        (click)="selectTime(time, item.date)">
                        <p>{{time.time}}</p>
                    </div>
                    <div class="clear"></div>
                </div>
                <div *ngIf="item.sugestions && item.sugestions.lenght > 0 && (!item.times || item.times.length <= 0)" class="times-wrapper">
                    <div 
                        class="time-item"
                        *ngFor="let time of item.sugestions"
                        [ngClass]="{'active': time.active}"
                        [hidden]="time.hidden"
                        (click)="selectTime(time, item.date)">
                        <p>{{time.time}}</p>
                    </div>
                    <div class="clear"></div>
                </div>
            </mat-expansion-panel>
        </mat-accordion>
       
        <button *ngIf="showLoadMore" class="button btn-load-more" (click)="loadMoreDays()">{{'loadMoreDays' | translate}}</button>
        
    </div>
    
</section>