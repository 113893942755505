import * as CryptoJS from 'crypto-js';

export class ValidationService {

    static isEmailValid(email): boolean {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    static isSingleNameValid(name: string): boolean {
        const re = /^('|[a-zà-úA-ZÀ-ÚäëïöüÿÄËÏÖÜŸ]){3,}/;
        if (!re.test(name)) {
            return false;
        }
        return true;
    }

    static isNameValid(name: string): boolean {
        const re = /^('|[a-zà-úA-ZÀ-ÚäëïöüÿÄËÏÖÜŸ]){3,} ('|[a-zà-úA-ZÀ-ÚäëïöüÿÄËÏÖÜŸ]){2,}/;
        if (!re.test(name)) {
            return false;
        }

        let isValid = true;
        const re1 = /^('|[a-zà-úA-ZÀ-ÚäëïöüÿÄËÏÖÜŸ]){3,}$/;
        const re2 = /^('|[a-zà-úA-ZÀ-ÚäëïöüÿÄËÏÖÜŸ]){2,}$/;
        let array = name.split(" ");
        array.forEach(function (x, y) {
            if ((y == 0 && !re1.test(x)) || (y != 0 && !re2.test(x))) {
                isValid = false;
            }
        });
        return isValid;
    }

    static isPhoneValid(phone: string): boolean {
        const re = /^\d{2}[6-9]\d{8}$/;
        return re.test(phone);
    }

    static isOnlyNumbers(value: string): boolean {
        const reg = new RegExp('^[0-9]+$');

        if (reg.test(value)) {
            return true;
        }

        return false;
    }

    static isDocValid(doc): boolean {
        let cpf = String(doc).replace(".", "").replace("-", "").trim();
        if (cpf == null) {
            return false;
        }
        if (cpf.length != 11) {
            return false;
        }
        if ((cpf == '00000000000') || (cpf == '11111111111') || (cpf == '22222222222') || (cpf == '33333333333') || (cpf == '44444444444') || (cpf == '55555555555') || (cpf == '66666666666') || (cpf == '77777777777') || (cpf == '88888888888') || (cpf == '99999999999')) {
            return false;
        }
        let numero: number = 0;
        let caracter: string = '';
        let numeros: string = '0123456789';
        let j: number = 10;
        let somatorio: number = 0;
        let resto: number = 0;
        let digito1: number = 0;
        let digito2: number = 0;
        let cpfAux: string = '';
        cpfAux = cpf.substring(0, 9);
        for (let i: number = 0; i < 9; i++) {
            caracter = cpfAux.charAt(i);
            if (numeros.search(caracter) == -1) {
                return false;
            }
            numero = Number(caracter);
            somatorio = somatorio + (numero * j);
            j--;
        }
        resto = somatorio % 11;
        digito1 = 11 - resto;
        if (digito1 > 9) {
            digito1 = 0;
        }
        j = 11;
        somatorio = 0;
        cpfAux = cpfAux + digito1;
        for (let i: number = 0; i < 10; i++) {
            caracter = cpfAux.charAt(i);
            numero = Number(caracter);
            somatorio = somatorio + (numero * j);
            j--;
        }
        resto = somatorio % 11;
        digito2 = 11 - resto;
        if (digito2 > 9) {
            digito2 = 0;
        }
        cpfAux = cpfAux + digito2;
        return cpf == cpfAux;
    }
}