import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import gql from 'graphql-tag';


const selectSchedulerQuery = gql`{
  querySelecaoHorarioAgendamentoContents {
    flatData{
      titulo,
      descricao,
      icone{
        url
      },
      imagemFundo{
        url
      },
      utilizarHtmlCustomizado,
      html
    }
  }
}`;

@Injectable({
  providedIn: 'root'
})
export class SelectSchedulerService {

  result: any;
  constructor(private apollo: Apollo) { }

  async initialize(){

    if (!this.result) {
      var result = await this.apollo.watchQuery<any>({
        query: selectSchedulerQuery
      }).result();

      this.result = result.data.querySelecaoHorarioAgendamentoContents[0].flatData;   
    }

    return this.result;

  }


}
