// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  mock: false,
  environmentName: 'HML',
  production: false,
  usesGeolocation: true,
  useMap: false,
  googleApiKey: 'AIzaSyCBjhuiNR3et1U63GBEkM8Lit-xU-WNnTs',
  serviceUrl: "https://func-hml-leadpage-el.azurewebsites.net/api/espacolaser",
  recaptcha_siteKey: '6LeKna8ZAAAAAMFn5FHXkD1YzImDF9TZsCjqlsee',
  shareLink: 'https://app-hml-ui-leadpage-el.azurewebsites.net/?indicadoPor={0}&tipo={1}',
  shareLinkVariant: 'https://app-hml-ui-leadpage-el.azurewebsites.net/{0}/?indicadoPor={1}&tipo={2}',
  shareBaseLink: 'https://app-hml-ui-leadpage-el.azurewebsites.net/',
  shareComplementLink: '&indicadoPor={1}&tipo={2}',
  AZURE_FUNCTION_KEY: 'yeJCx8KT6gsYS071sQkuYepTt/9LbJ4t3AKcDSu0jkOg80iayJz2rg==',
  cms_url: 'https://cms-hml.evup.com.br',
  cms_content_endpoint: '/api/content/landing-page/graphql',
  cms_assets_endpoint: '/api/assets/landing-page/{0}',
  cms_identity_endpoint: '/identity-server/connect/token',
  cms_identity_client_id: 'landing-page:landing-page-site',
  cms_identity_client_secret: 'gnkqfnbk7hiixiltl5czpgfw7ocxbjbavdbzhmvo3fkx',
  redisHost: 'evupdev.redis.cache.windows.net',
  redisPass: '97xikjnQQeteZftSe98V4M8KBk0kj7Hy98UiP2yCuvs=',
  redisPort: '6380',
  redisTls: true,
  // cms_endpoint: 'https://app-dev-cockpitcms-el.azurewebsites.net',
  // cms_authorization: '4aacf3b0399af8bed9d285bd7c4702',
  allowManualReferFriends: false,
  app_endpoint: 'https://www.espacolaser.com.br/app',
  baseUrl: 'https://indique.espacolaser.com.br',
  cms_identity_client_id_shared: 'shared:default',
  cms_identity_client_secret_shared: 'mvk3ll2imzjvmoeqxwxexxkwiimprrkvz0x7saluqsmx',
  cms_shared_contract_content_endpoint: '/api/content/shared/graphql',
  appChannel: "LP_BRA",
  searchEndpoint: 'https://evupsearch.search.windows.net/',
  searchApiKey: 'BCE50E8BB7C8082D138D2040607E01A8',
  schemaId: 'hml-elos-el-1',
  useNewEstablishmentSearch: true,
  schedulerInterval: 60,
  refferShareLink: 'https://app-hml-ui-leadpage-el.azurewebsites.net/?invite=true&indicadoPor={0}&tipo={1}',
  schedulerDaysQuantityToShow: 7,
  useNewScheduler: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
