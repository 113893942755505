import { Component, EventEmitter, Input, OnInit, Output, TemplateRef, ViewChild } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ELeadMembershipType } from 'src/app/model/enum.leadMembershipType';
import { CmsSharedService } from 'src/app/services/cms-shared.service';
import { LeadService } from 'src/app/services/lead.service';

@Component({
  selector: 'app-fidelity-term-checkbox',
  templateUrl: './fidelity-term-checkbox.component.html',
  styleUrls: ['./fidelity-term-checkbox.component.scss']
})
export class FidelityTermCheckboxComponent implements OnInit {
  @Input() fidelityTermChecked: boolean;
  @Output() fidelityTermCheckedChange = new EventEmitter<boolean>();

  @Input() consentTermContent: string = "";

  @Input() fidelityTermContent: string = "";

  @ViewChild('callContentTermModal') callContentTermModal: TemplateRef<any>;

  @ViewChild('callContentFidelityTermModal') callContentFidelityTermModal: TemplateRef<any>;
  showFidelityTerm: boolean = false;

  constructor(
    private modal: MatDialog,
    private cmsShared: CmsSharedService,
    private leadService: LeadService
  ) { }

  async ngOnInit() {

    await this.cmsShared.initialize();

    this.consentTermContent = this.cmsShared.consentTermContent;

    this.fidelityTermContent = this.cmsShared.fidelityTermContent;

    if (!this.consentTermContent) {
      this.setCheck(true);
    }

    if (this.fidelityTermContent) {

      var isFidelityActive = await this.leadService.getFidelityProgramIsActive()
      var leadMembershipType = await this.leadService.getLeadFidelityMembershipType();
      var isLeadFidelityActive = await this.leadService.getLeadFidelityProgramIsActive();


      if (isFidelityActive && isLeadFidelityActive) {
        this.showFidelityTerm = (leadMembershipType == ELeadMembershipType.LeadWithConsent);
      } else {
        this.showFidelityTerm = false;
      }
    }
  }

  setCheck(event: boolean) {
    this.fidelityTermChecked = event;
    this.fidelityTermCheckedChange.emit(this.fidelityTermChecked);
  }

  openModalFidelity() {

    const modalRef = this.modal.open(this.callContentFidelityTermModal, {
      panelClass: 'my-dialog'
    });

    modalRef.afterClosed().subscribe(result => {

      if (result == false || result == undefined) {

        this.fidelityTermChecked = false;

      } else if (result == true) {

        this.fidelityTermChecked = true;

      }
    })
  }
}
