import { Component, OnInit, Input, Output, EventEmitter, AfterViewInit, OnDestroy } from '@angular/core';
import { Lead } from 'src/app/model/lead';
import { Subject } from 'rxjs';
import { UiService } from 'src/app/services/ui.service';
import { ActiveFrameService } from 'src/app/services/activeFrame.service';
import { LeadService } from 'src/app/services/lead.service';
import { Guid } from 'src/app/helper/guid';
import { BusinessError } from 'src/app/helper/BusinessError';

const CELLPHONE = '(00) 00000-0000';
const LANDLINE= '(00) 0000-00009';

@Component({
  selector: 'app-refer-friend',
  templateUrl: './refer-friend.component.html',
  styleUrls: ['./refer-friend.component.scss']
})
export class ReferFriendComponent implements OnInit, AfterViewInit, OnDestroy {

  stepName: string = 'ReferFriends';

  @Input() model: Lead;

  @Input() activeStep: Subject<string>;

  @Output() onBack = new EventEmitter<string>();

  @Output() onConfirm = new EventEmitter<Lead>();
  
  referList = [];

  phoneMask = [];

  constructor(private uiService: UiService,
    private activeFrameService: ActiveFrameService,
    private leadService: LeadService) { }

  ngOnInit(): void {
    this.referList = [
      {
        transientId: Guid.newGuid(),
        name: null, 
        phoneNumber: null
      },
      {
        transientId: Guid.newGuid(),
        name: null, 
        phoneNumber: null
      },
      {
        transientId: Guid.newGuid(),
        name: null, 
        phoneNumber: null
      }
    ];
  }

  ngAfterViewInit() {
    this.activeStep.subscribe((active) => {
      if (active == 'ReferFriends') {
        this.activeFrameService.moveTo("7");
      }
    });
  }

  ngOnDestroy(): void {
    this.activeStep.unsubscribe();
  }
  
  phoneChanged($event) {

    setTimeout(_ => {
      if ($event.phoneNumber.length <= 10) {
        this.phoneMask[this.referList.indexOf($event)] = LANDLINE;
      }
      else {
        this.phoneMask[this.referList.indexOf($event)] = CELLPHONE;
      }
    }, 100);
    
  }

  addRow() {
    this.referList.push({
      transientId: Guid.newGuid(),
      name: null, 
      phoneNumber: null
    });
  }

  remove(element) {

    var index = this.referList.indexOf(element);

    this.referList.splice(index, 1);

    this.phoneMask.splice(index, 1);
  }

  back() {
    this.onBack.emit(this.stepName);
  }

  async validate() {
    if (this.referList.filter(f => f.name == null || f.phoneNumber == null || f.phoneNumber.length < 10).length > 0) {
      throw new BusinessError(await this.uiService.translate("referFriendsRequired"));
    }

    if (this.referList.filter(f => this.referList.filter(s =>s != f && s.phoneNumber == f.phoneNumber).length> 0).length > 0) {
      throw new BusinessError(await this.uiService.translate("referFriendsDuplicated"));
    }

  }

  async confirm() {

    try {
      await this.validate();

      await this.leadService.referFriends(this.model, this.referList);

      this.onConfirm.emit();

    } catch(e) {
      this.uiService.showError(e);
    }
    
  }
}
