import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RedirectService, staticRoutes } from 'src/app/services/redirect.service';

@Component({
    selector: 'app-hub',
    templateUrl: './hub.component.html',
    styleUrls: ['./hub.component.scss']
})
export class HubComponent implements OnInit {

    constructor(
        private redirectService: RedirectService,
        private route: ActivatedRoute,
        private router: Router) {
    }

    async fillRouter() {
        const config = Object.assign([], staticRoutes);

        var routes = await this.redirectService.getAll();

        routes.forEach(function (route) { config.push(route); });

        config.push({ path: '**', component: HubComponent });

        this.router.resetConfig(config);
    }
    
    async ngOnInit(): Promise<void> {

        await this.fillRouter();

        this.route.url.subscribe(async params => {

            if (this.router.config.some(c => c.path == params[0].path)) {

                this.router.navigate([params[0].path], { queryParamsHandling: "merge" });

                return;
            }

            /*
            if (this.isServer) {
               this.response.status(404);
             }
             */

            this.router.navigateByUrl('404');
        });

    }

}
