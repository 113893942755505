import { AfterViewInit, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Lead } from 'src/app/model/lead';
import { ActiveFrameService } from 'src/app/services/activeFrame.service';
import { ExceptionHandlerService } from 'src/app/services/exceptionHandler.service';
import { SchedulerService } from 'src/app/services/scheduler.service';
import { TextService } from 'src/app/services/text.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'app-schedule-management',
  templateUrl: './schedule-management.component.html',
  styleUrls: ['./schedule-management.component.scss']
})
export class ScheduleManagementComponent implements OnInit, AfterViewInit, OnDestroy {

  stepName: string = 'ScheduleManagement';

  @Input() model: Lead;

  @Input() activeStep: BehaviorSubject<string>;

  name: string;
  finalMessage: string;

  constructor(
    private activeFrameService: ActiveFrameService,
    private textService: TextService,
    private schedulerService: SchedulerService,
    private uiService: UiService,
    private exceptionHandlerService: ExceptionHandlerService) { }

  ngOnInit(): void {
  }

  async ngAfterViewInit() {
    this.activeStep.subscribe(async (active) => {
      if (active == this.stepName) {

        if (this.model.name) {
          var firstName = this.model.name.split(' ')[0];

          this.name = this.textService.toPascalCase(firstName);
        }

        this.activeFrameService.moveTo("10");
      }
    });
  }
  
  ngOnDestroy(): void {
    this.activeStep.unsubscribe();
  }

  async confirm() {
  
    try {
      await this.schedulerService.confirmSchedule(this.model);

      this.finalMessage = await this.uiService.translate("confirmScheduleSucess")

    } catch (e) {
      await this.exceptionHandlerService.handle(e);
    }
    
  }
  reschedule() {
    this.activeStep.next('SelectScheduler');
  }
  async dontGo() {
    
    var result = await this.uiService.showMessage({
      icon: "question",
      title: await this.uiService.translate("dontGo"),
      text: await this.uiService.translate("cancelScheduleQuestion"),
      confirmButtonText: await this.uiService.translate("yes"),
      showCancelButton: true,
      cancelButtonText: await this.uiService.translate("no")
    });

    if (result.isConfirmed) {
      try {
        await this.schedulerService.cancelSchedule(this.model);

        this.finalMessage = await this.uiService.translate("cancelScheduleSucess")

      } catch (e) {
        await this.exceptionHandlerService.handle(e);
      }
    }
  }
}
