<ng-template #callContentTermModal>
    <button class="close" mat-button [mat-dialog-close]="true">X</button>
    <mat-dialog-content>
        <div [innerHTML]="consentTermContent"></div>
    </mat-dialog-content>
    <mat-dialog-actions align="end">
        <button mat-button [mat-dialog-close]="true" cdkFocusInitial (click)="setCheck(true)">{{'acceptConsentTerm' |
            translate}}</button>
    </mat-dialog-actions>
</ng-template>

<mat-checkbox *ngIf="consentTermContent" class="text-checkbox" [checked]="consentTermChecked" (change)="setCheck($event.checked)">
    {{'consentTermContent' | translate }}  <a style="color: #015BFA"
        (click)="openModal()">{{'consentTerm' | translate}}</a> {{'alsoThe' | translate}} <a (click)="openPrivacyPolicyDialog()" style="color: #015BFA">{{'PrivacyPolicy' | translate}}</a>
</mat-checkbox>