<h2 class="fs-title">{{ 'scheduleManagementTitle' | translate }}</h2>

<ng-template [ngIf]="!finalMessage">
    <h3 *ngIf="model && model.Schedule" style="font-size: 1.3rem;" class="fs-info">
        <ng-template [ngIf]="name">
            {{ 'hi' | translate : { name: name } }},
            <br/><br/>
        </ng-template> 
        <div [innerHTML]="('schedulerDetailEstablishment' | translate : { unidade: model.Schedule.Establishment })" ></div>
        <div [innerHTML]="('schedulerDetailDate' | translate : { data: (model.Schedule.InitDate | date:'dd/MM/yyyy HH:mm') })" ></div>
        <div [innerHTML]="('scheduleManagementLegend' | translate )" ></div>
    </h3>

    <button mat-button class="action-button" (click)="confirm()">{{ 'confirmSchedule' | translate }}</button>

    <button mat-button class="action-button" (click)="reschedule()">{{ 'reschedule' | translate }}</button>

    <button mat-button class="action-button" (click)="dontGo()">{{ 'dontGo' | translate }}</button>
</ng-template>

<ng-template [ngIf]="finalMessage">
    <mat-icon style="display: inline; font-size: 60px; color: cornflowerblue;">done_outline</mat-icon>
    <h3 style="margin-top: 34px; font-size: 1.3rem;" class="fs-info">
        {{ finalMessage }}
    </h3>
</ng-template>




