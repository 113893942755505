import { Injectable } from "@angular/core";
import { Route } from "@angular/router";
import { NotFoundComponent } from "../components/not-found/not-found.component";
import { HomeComponent } from "../pages/home/home.component";
import { SegmentationService } from "./segmentation.service";

export const staticRoutes = [
    { path: '', component: HomeComponent },
    { path: '404', component: NotFoundComponent }
];

@Injectable({
    providedIn: 'root'
  })
export class RedirectService {

    constructor(private segmentationService: SegmentationService) {        
    }

    async getAll() : Promise<Route[]> {
        try 
        {
            var data = new Array();

            await this.segmentationService.initialize();

            this.segmentationService.variants.forEach(variant => {
                data.push({ path: variant, component: HomeComponent });
            });

            return data;
        }
        catch(e) 
        {
            console.log(e);

            return [];
        }
    }
}