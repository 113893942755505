import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { BusinessError } from '../helper/BusinessError';
import { organizationalStructureConfig, OrganizationalStructureConfigResult, organizationalStructureConfigRoot } from '../model/organizationalStructureConfig';
import { UiService } from './ui.service';

var weekdays: Array<string>;

@Injectable({
  providedIn: 'root'
})
export class EstablishmentService {

  constructor(
    private translateService: TranslateService, private uiService: UiService, private http: HttpClient) { }

  getEstablishmentDescription(description: string) {

    if (!description) return description;

    var i = description.lastIndexOf('-');

    if (i > 0) {
      return description.substr(i + 1).trim();
    }

    return description;
  }

  async translateWeekDays() {
    weekdays = new Array(
      await this.translateService.get("sunday").toPromise(),
      await this.translateService.get("monday").toPromise(),
      await this.translateService.get("tuesday").toPromise(),
      await this.translateService.get("wednesday").toPromise(),
      await this.translateService.get("thursday").toPromise(),
      await this.translateService.get("friday").toPromise(),
      await this.translateService.get("saturday").toPromise()
    );
    return weekdays;
  }

  private handleErrors(data: any) {
    throw new BusinessError(data.message);

  }

  async getEstablishmentConfigRoot(configRootRequest: organizationalStructureConfigRoot): Promise<OrganizationalStructureConfigResult> {
    
    var url = environment.serviceUrl + `/${this.uiService.getDefaultLang()}/GetConfig`;
    var data = <OrganizationalStructureConfigResult>await this.http.post(url, configRootRequest).toPromise();

    if (data.error)
      this.handleErrors(data);

    return data;
  }

  async getEstablishmentConfig(configRequest: organizationalStructureConfig): Promise<OrganizationalStructureConfigResult> {

    var url = environment.serviceUrl + `/${this.uiService.getDefaultLang()}/GetConfig`;
    var data = <OrganizationalStructureConfigResult>await this.http.post(url, configRequest).toPromise();

    if (data.error)
      this.handleErrors(data);

    return data;
  }
}
