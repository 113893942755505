import { Component, OnInit, Input, AfterViewInit, OnDestroy, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ActiveFrameService } from 'src/app/services/activeFrame.service';
import { ThemeService } from 'src/app/services/theme.service';
import { ActivatedRoute } from '@angular/router';
import { UiService } from 'src/app/services/ui.service';
import { LeadService } from 'src/app/services/lead.service';
import { EncrypterService } from 'src/app/services/encrypter.service';
import { ShortlinkService } from 'src/app/services/shortlink.service';
import { InviteService } from 'src/app/services/invite.service';

@Component({
  selector: 'app-invite-form',
  templateUrl: './invite-form.component.html',
  styleUrls: ['./invite-form.component.scss']
})
export class InviteFormComponent implements OnInit, AfterViewInit, OnDestroy {

  stepName: string = 'InviteForm';

  public iFrame: boolean = false;

  public knownPersonId: any;

  public type: string;

  public consultor: any = null;

  public baseUrl: string = null;

  public shortLink: string = null;

  public copyMsg: string = null;

  public isFromFidelity: boolean = false;

  public inviteLink: string;

  @Input() activeStep: BehaviorSubject<string>;

  @Input() inviteEmail: string;

  UTMList: any[] = [];



  @ViewChild('myInput') myInput;

  constructor(private activeFrameService: ActiveFrameService,
    public uiService: UiService,
    public leadService: LeadService,
    private route: ActivatedRoute,
    public themeService: ThemeService,
    private shortlinkService: ShortlinkService,
    private inviteService: InviteService) {
  }

  async ngOnInit() {
    this.uiService.currentLanguage.subscribe(async lang => {
      //considerar lang como flag de inicialização (baseado em home.component)
      if (this.uiService.isBrowser && lang) {
        this.route.queryParams.subscribe(async params => {

          this.UTMList = this.inviteService.buildUTMList(params['utm_term'], params['utm_source'], params['utm_medium'], params['utm_campaign'], params['utm_content']);

          if (params['indicadoPor']) {
            let pIndicadoPor = params['indicadoPor'];
            let pTipo = params['tipo'];

            var iLink = this.inviteService.buildInviteLink(
              pIndicadoPor,
              pTipo,
              this.themeService.hasMarketingVinculo,
              this.themeService.hasVariant,
              false,
              this.UTMList,
              this.themeService.mktDigitalPath);

            this.knownPersonId = EncrypterService.decrypt(pIndicadoPor);
            this.type = pTipo;
            if (params['consultor']) {
              let pConsultor = params['consultor'];
              iLink = iLink.concat("&consultor=", encodeURIComponent(pConsultor));
              this.consultor = EncrypterService.decrypt(pConsultor);
            }

            this.inviteLink = iLink;

          }
          else {
            this.inviteLink = this.inviteService.buildInviteLink(
              null,
              null,
              this.themeService.hasMarketingVinculo,
              this.themeService.hasVariant,
              false,
              this.UTMList,
              this.themeService.mktDigitalPath);
          }

          if (params['iframe']) {
            this.iFrame = true;
          }

          if (params['iframe'] == 'indique') {
            this.isFromFidelity = true;
          }
        });
      }
    });
  }

  async ngAfterViewInit() {

    this.activeStep.subscribe(async (active) => {
      if (active == this.stepName) {
        this.activeFrameService.moveTo("9");
      }
    });
  }

  ngOnDestroy(): void {
    this.activeStep.unsubscribe();
  }

  async sendInviteEmail() {
    var subject = encodeURIComponent(this.themeService.invite_subject);
    var body = encodeURIComponent(await this.getMsg());
    await this.sendOpenCommand(`mailto:?subject=${subject}&body=${body}`);
  }

  async sendToWhatsapp() {
    var msg = encodeURIComponent(await this.getMsg(false));
    this.sendOpenCommand(`https://wa.me/?text=${msg}`);
  }

  async sendToTelegram() {
    await this.generateShortenerURL();
    var url = encodeURIComponent(this.shortLink);
    var msg = encodeURIComponent(this.themeService.share_facebook);
    this.sendOpenCommand(`https://t.me/share/url?url=${url}&text=${msg}`);
  }

  async sendToTwitter() {
    await this.generateShortenerURL();
    var msg = this.themeService.share_twitter.replace("{0}", this.shortLink);
    msg = encodeURIComponent(msg);
    this.sendOpenCommand(`https://twitter.com/intent/tweet?source=tweetbutton&text=${msg}`);
  }

  async sendToFacebook() {
    await this.generateShortenerURL();
    var url = encodeURIComponent(this.shortLink);
    var msg = encodeURIComponent(this.themeService.share_facebook);
    this.sendOpenCommand(`https://www.facebook.com/sharer.php?u=${url}&quote=${msg}`);
  }

  async getMsg(clear: boolean = true): Promise<string> {
    await this.generateShortenerURL();
    var msg = this.themeService.share_whatsapp.replace("{0}", this.shortLink);

    if (clear) {
      // msg = msg.replaceAll("*", "");
      while (msg.indexOf("*") >= 0) {
        msg = msg.replace("*", "");
      }
    }

    return msg;
  }

  sendOpenCommand(url: string) {
    if (this.iFrame) {
      parent.postMessage({
        type: "aberturaLink",
        data: url
      }, "*");
    }
    else {
      if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        window.location.assign(url);
      } else {
        window.open(url);
      }
    }
  }

  async generateShortenerURL() {

    const url = new URL(this.inviteLink);
    const inviteLinkParams = url.pathname + url.search;
    this.baseUrl = url.origin;

    const data = await this.shortlinkService.generateShortenerURL(inviteLinkParams, this.baseUrl, true);
    if (data && data.token) {
      this.shortLink = `${this.baseUrl}?t=${data.token}`;
    } else {
      this.shortLink = `${this.baseUrl}`;
    }
  }

  copyInviteLink() {
    const inviteLinkComponents = this.inviteLink.split('?');
    this.baseUrl = inviteLinkComponents[0];
    const inviteLinkParams = inviteLinkComponents[1];

    this.shortlinkService.generateShortenerURLForCopy(inviteLinkParams, this.baseUrl, true).subscribe(response => {
      if (response && response.token) {
        setTimeout(() => {
          this.sendCopyCommand(`${this.baseUrl}?t=${response.token}`);
        }, 500);
      } else {
        setTimeout(() => { this.sendCopyCommand(`${this.baseUrl}`) }, 500);
      }
    });
  }

  sendCopyCommand(msg) {
    if (this.iFrame && !this.isFromFidelity) {
      parent.postMessage({
        type: "copiaLink",
        data: msg
      }, "*");
    } else {
      navigator.clipboard.writeText(msg).then(async () => {
        this.uiService.showMessage({
          title: "",
          text: await this.uiService.translate("copiedLink"),
        });
      }).catch((e) => {
        this.uiService.showError(e);
      });
    }
  }

  async openWhatsappContact() {
    if (!this.uiService.isBrowser) {
      return;
    }
    await this.generateShortenerURL();
    setTimeout(() => {
      var msg = this.themeService.share_whatsapp.replace("{0}", this.shortLink);
      msg = encodeURIComponent(msg);
      if (msg) {
        var url = `https://wa.me/?text=${msg}`;
        window.open(url, '_blank').focus();
      }
    })
  }

  async share() {

    await this.generateShortenerURL();
    var objShare = {
      title: '',
      text: '',
      url: this.shortLink
    };

    if (navigator.canShare(objShare)) {
      navigator.share(objShare)
        .then(() => console.log('Compartilhado com sucesso!'))
        .catch((error) => console.log('Error ao compartilhar', error));
    }
    else {
      //Alinhar sobre a necessidade de
      //montar Modal para compartilhamento em caso de falha.      
    }
  }
}
